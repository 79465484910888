import classNames from "classnames"
import { get } from "lodash-es"
import styles from "./SummaryTable/SummaryTable.module.css"

type TransactionTableProps = {
  columns?: any[]
  data?: any[]
  header?: any
  className?: any
}

const TransactionTable = ({
  columns,
  header,
  className,
  data,
}: TransactionTableProps) => {

  const renderFooterRow = (footerType, data) => {
    return (
      <tr>
        {columns?.map?.((column, index) => {
          const isLastColumn = index === columns.length - 1;
          return (
            <td className={classNames("border-t border-gray-300 bg-white", { "text-right": isLastColumn })} key={`${column.id}-${footerType}`}>
              {column?.[footerType]?.(data)}
            </td>
          );
        })}
      </tr>
    );
  };

  const hasFooter = footerType => columns?.some(col => !!col[footerType]);

  return (
    <table className={classNames(styles["base"], className)}>
      <thead>
        {header}
        <tr>
          {columns
            ?.filter(column => column.name)
            ?.map((column, index) => {
              const isLastColumn = index === columns.length - 1;
              return (
                <th
                  key={column.id}
                  colSpan={column.colSpan}
                  className={classNames(column.className, { "text-right": isLastColumn })}
                >
                  {column.name}
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody>
        {data?.map?.((row, rowIndex) => {
          return (
            <tr key={row.id}>
              {columns?.map?.((column, colIndex) => {
                const isLastColumn = colIndex === columns.length - 1;
                return (
                  <td key={column.id} className={classNames({ "text-right": isLastColumn })}>
                    {column?.cell?.(get(row, column?.accessor), row, rowIndex)}
                  </td>
                );
              })}
            </tr>
          )
        })}
      </tbody>
      {hasFooter('footer') || hasFooter('footer2') || hasFooter('footer3') || hasFooter('footer4') ? (
        <tfoot>
          {hasFooter('footer') && renderFooterRow('footer', data)}
          {hasFooter('footer2') && renderFooterRow('footer2', data)}
          {hasFooter('footer3') && renderFooterRow('footer3', data)}
          {hasFooter('footer4') && renderFooterRow('footer4', data)}
        </tfoot>
      ) : null}
    </table>
  )
}

export default TransactionTable
