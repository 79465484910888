import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuid4 } from 'uuid';
import { Button, Spinner } from "@vesatogo/grass-core";
import { Plus } from "@phosphor-icons/react";

export type FileUploadButtonProps = {
  endpoint: string;
  onChange?: (newArray: any[]) => void;
  onError: (message: string) => void;
  onSuccess?: (message: any) => void;
  maxFiles?: number;
  multiple?: boolean;
  access?: 'public-read' | 'authenticated-read' | 'private';
  shouldEncrypt?: boolean;
  className?: string;
  disabled?: boolean;
  method?: 'post' | 'put';
  uploadCardClassName?: string;
  'data-testid'?: string;
  environment?: string;
  appName?: string;
  media?: any[];
};

function generateFilename(file: File) {
  const extension = file.type.split('/')[1];
  return `${uuid4()}.${extension}`;
}

const FileUploadButton = ({
  endpoint,
  onChange,
  onError,
  onSuccess,
  maxFiles = 8,
  multiple = true,
  access = 'public-read',
  shouldEncrypt = false,
  className,
  disabled = false,
  method = 'post',
  environment = '',
  appName = 'dashboard',
  media = [],
  ...props
}: FileUploadButtonProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const uploadFiles = async (files: File[]) => {
    try {
      setLoading(true);
      if (!files?.length) return;

      const path = [appName, environment].filter((str) => str).join('/') + '/';
      const filesPromises = files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);
        formData.append('access', access);
        formData.append('should_encrypt', shouldEncrypt.toString());
        formData.append('name', generateFilename(file));

        return method === 'post'
          ? axios.post(endpoint, formData, {
              headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
          : axios.put(endpoint, formData, {
              headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`
              }
            });
      });

      const uploadedFiles = await Promise.all(filesPromises);
      const newFiles = uploadedFiles.map(({ data }) => {
        const type = /(.jpeg|.jpg|.png|.webp)$/i.test(data.media.url)
          ? 'image'
          : 'file';

        return {
          url: data.media.url,
          is_encrypted: shouldEncrypt,
          media_kind: type,
          access
        };
      });

      onChange?.(newFiles);
      onSuccess?.(uploadedFiles);
    } catch (err: any) {
      onError(`Something went wrong! ${err.message || err}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const currentFileCount = media.length;

    const imageFiles = files.filter(file => file.type.startsWith('image/'));

    if (imageFiles.length + currentFileCount > maxFiles) {
      onError(`Cannot upload more than ${maxFiles} files`);
      return;
    }

    uploadFiles(imageFiles);
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple={multiple}
        disabled={disabled}
        aria-label="Upload Files"
        accept="image/*"
        {...props}
      />
      <Button
        className={className}
        intent="primary"
        variant="outline"
        onClick={() => fileInputRef.current!.click()}
        leftIcon={<Plus size={16} />}
        disabled={disabled}
      >
        {loading ? <Spinner size={16} /> : 'Add Attachment'}
      </Button>
    </>
  );
};

export default FileUploadButton;
