import { useTranslation } from "react-i18next"
import { Card, Group, Text } from "@vesatogo/grass-core"
import dayjs from "dayjs"
import { PavtiTradeDetailQuery } from "~/generated/graphql"
import { DetailCard } from "~/pages/pavti/components"

export function TradeValidity({
  data,
}: {
  data: PavtiTradeDetailQuery["trade_by_pk"]
}) {
  const { t } = useTranslation()
  return !!data?.start_date || !!data?.end_date || !!data?.expiry ? (
    <Card className="overflow-hidden mt-4 !rounded-lg">
      <DetailCard
        header={
          <Text className="font-normal !text-gray-800">
            {t("trade.validity.title")}
          </Text>
        }
      >
        <Group className="flex justify-between items-start p-2 border-t border-gray-300">
          <Text size="sm" className="!text-gray-800">
            {t("trade.start")}
          </Text>
          <Text size="sm" className="flex items-center gap-1 !text-gray-800">
            {data?.start_date
              ? dayjs(data?.start_date).format("DD MMM YY")
              : "-"}
          </Text>
        </Group>
        <Group className="flex justify-between items-start p-2 border-t border-gray-300">
          <Text size="sm" className="!text-gray-800">
            {t("trade.end")}
          </Text>
          <Text size="sm" className="flex items-center gap-1 !text-gray-800">
            {data?.end_date ? dayjs(data?.end_date).format("DD MMM YY") : "-"}
          </Text>
        </Group>
        <Group className="flex justify-between items-start p-2 border-t border-gray-300">
          <Text size="sm" className="!text-gray-800">
            {t("trade.validity.period")}
          </Text>
          <Text size="sm" className="flex items-center gap-1 !text-gray-800">
            {data?.expiry ? dayjs(data?.expiry).format("DD MMM YY") : "-"}
          </Text>
        </Group>
      </DetailCard>
    </Card>
  ) : null
}
