import { Card, Text, Group } from "@vesatogo/grass-core"

type IMetricsCard = {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  count?: number
}

export function MetricsCard({ title, subtitle, count }: IMetricsCard) {
  return (
    <Card className="flex flex-1 items-center flex-wrap justify-between rounded-sm p-4 gap-4">
      <Group>
        <Text className="text-lg text-gray-800">{title}</Text>
        <Text as="p" className="text-sm text-gray-500">
          {subtitle}
        </Text>
      </Group>
      <Text as="h2" size="lg" className="text-4xl text-green-400">
        {count !== null && count !== undefined
          ? Number(Number(count).toFixed(2)).toLocaleString()
          : "-"}
      </Text>
    </Card>
  )
}

type IMetricsSection = {
  title?: string
  children?: React.ReactNode
}

export function MetricsSection({ title, children }: IMetricsSection) {
  return (
    <div className="p-4 pb-12">
      <Text as="h3" className="text-lg pb-4 text-gray-700">
        {title}
      </Text>
      <Group className="flex gap-7">{children}</Group>
    </div>
  )
}
