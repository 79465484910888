import { GetInputProps, Reset } from "@mantine/form/lib/types"
import {
  FormInput,
  FormQuerySelector,
  FormSelector,
  Modal,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { useSearchParams } from "react-router-dom"
import { OperationContext } from "urql"
import { CustomToast } from "~/components/CustomToast"
import {
  useAddUpdateSharedEntityMutation,
  useAllDepartmentsQuery,
  useAllPaymentMethodsQuery,
  useDepartmentPaymentListQuery,
  useInsertPlantSettlementMutation,
  useUsersQuery,
} from "~/generated/graphql"

export const PlantTransaction = ({
  state,
  resetForm,
  getInputProps,
  refetch,
}: {
  state?: any
  resetForm: Reset
  getInputProps: GetInputProps<Record<string, unknown>>
  refetch?: (opts?: Partial<OperationContext> | undefined) => void
}) => {
  const [params, setParams] = useSearchParams()
  const isOpen = Boolean(params.get("addPlantTransaction"))

  // const form = useForm()
  // const {reset} = form
  const [, insertPlantTransaction] = useInsertPlantSettlementMutation()
  const [, addUpdateSharedEntity] = useAddUpdateSharedEntityMutation()
  const onClose = () => {
    params.delete("addPlantTransaction")
    setParams(params)
    resetForm?.()
  }

  const [{ data: bankData }] = useDepartmentPaymentListQuery({
    variables: {
      where: {
        id: {
          _eq: state?.plant?.primaryKey,
        },
      },
    },
  })

  const onSubmit = async () => {
    if (
      !state?.date ||
      !state?.plant ||
      !state?.customer ||
      !state?.amount ||
      !state?.transaction_id ||
      !state?.bank_name
    ) {
      return CustomToast({
        intent: "danger",
        title: "Please enter all mandatory details!",
      })
    }
    const { data: userData, error: userError } = await addUpdateSharedEntity({
      reference_id: state?.customer?.codename,
      group_name: "kite.base_user",
      name: state?.customer?.name,
    })
    if (userError)
      return CustomToast({
        intent: "danger",
        title:
          "Something went wrong while fetching Customer details! " +
          userError?.message,
      })
    const userId = userData?.insert_setu_trade_shared_entity_one?.id

    const { data: deptData, error: deptError } = await addUpdateSharedEntity({
      reference_id: state?.plant?.id,
      group_name: "kite.department",
      name: state?.plant?.name,
    })
    if (deptError)
      return CustomToast({
        intent: "danger",
        title:
          "Something went wrong while fetching Department details! " +
          deptError?.message,
      })
    const deptId = deptData?.insert_setu_trade_shared_entity_one?.id

    const { data: bankData, error: bankError } = await addUpdateSharedEntity({
      reference_id: state?.bank_name?.payment_detail?.id?.toString(),
      group_name: "kite.payment_detail",
      name: state?.bank_name?.payment_detail?.account_holder_name,
    })
    if (bankError)
      return CustomToast({
        intent: "danger",
        title:
          "Something went wrong while fetching Bank details! " +
          bankError?.message,
      })
    const bankId = bankData?.insert_setu_trade_shared_entity_one?.id

    const { error } = await insertPlantTransaction({
      object: {
        date: state?.date,
        department_id: deptId,
        user_id: userId,
        transactions: {
          data: [
            {
              amount: state?.amount,
              date: state?.date,
              mode_id: state?.payment_mode?.id,
              transaction_id: state?.transaction_id?.toString(),
              bank_id: bankId,
              account_no: state?.bank_name?.payment_detail?.account_number,
            },
          ],
        },
      },
    })

    refetch?.({ requestPolicy: "network-only" })
    resetForm?.()
    onClose()
    if (error) {
      return CustomToast({
        intent: "danger",
        title: "Something went wrong! " + error.message,
      })
    }
    return CustomToast({
      title: "Successfully added Plant Transaction!",
    })
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Plant Transaction Details"
      bodyClassName="overflow-visible max-w-5xl"
      primaryActionButtonProps={{
        text: "Yes, Confirm",
        onClick: onSubmit,
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        onClick: onClose,
      }}
    >
      <div className="grid grid-cols-3 gap-3 over">
        <FormDateInput label="Date" required {...getInputProps("date")} />
        <FormQuerySelector
          dataHook={useAllDepartmentsQuery}
          label="Plant Name"
          required
          {...getInputProps("plant")}
        />
        <FormQuerySelector
          dataHook={useUsersQuery}
          label="Customer Name"
          required
          serverSideQuery
          variables={{ groups: ["Farmer", "Trader"] }}
          {...getInputProps("customer")}
        />
        <FormInput
          label="Received Amount"
          required
          placeholder="₹"
          {...getInputProps("amount")}
        />
        <FormSelector
          label="Bank Name"
          {...getInputProps(`bank_name`)}
          required
          getOptionLabel={op => {
            return op?.payment_detail?.bank_name
          }}
          options={bankData ? bankData?.department?.[0]?.payment_details : []}
        />
        <FormQuerySelector
          dataHook={useAllPaymentMethodsQuery}
          label="Payment Mode"
          required
          {...getInputProps("payment_mode")}
        />
        <FormInput
          label="Transaction Id"
          required
          {...getInputProps("transaction_id")}
        />
      </div>
    </Modal>
  )
}
