import { Link } from "react-router-dom"
import { useExpenseConfigVersionsQuery, useExpenseConfigsQuery, useTncConfigVersionsQuery, useTncConfigsQuery } from "~/generated/graphql"


type ITemplateTypeMap = {
  [P in "expenses" | "seller" | "buyer"]: {
    name: string
    listQuery: any
    listKey: string
    detailsQuery: any
    detailsKey: string
  }
}

export const templateTypeMap: ITemplateTypeMap = {
  expenses: {
    name: "Expenses",
    listQuery: useExpenseConfigVersionsQuery,
    listKey: "expense_config_versions",
    detailsQuery: useExpenseConfigsQuery,
    detailsKey: "expense_configs",
  },
  seller: {
    name: "Seller Terms",
    listQuery: useTncConfigVersionsQuery,
    listKey: "trade_tnc_versions",
    detailsQuery: useTncConfigsQuery,
    detailsKey: "trade_tnc_template_detail",
  },

  buyer: {
    name: "Buyer Terms",
    listQuery: useTncConfigVersionsQuery,
    listKey: "trade_tnc_versions",
    detailsQuery: useTncConfigsQuery,
    detailsKey: "trade_tnc_template_detail",
  },
}

export const TEMPLATE_COLUMNS = [
  {
    Header: "Version No.",
    accessor: "name",
    Cell: ({ cell }) => {
      return (
        <Link
          className="link-text text-left"
          to={`?id=${cell?.row?.original?.version_id}`}
        >
          {cell.value}
        </Link>
      )
    },
  },
  {
    Header: "Trade Type",
    accessor: "kind",
  },
  {
    Header: "Trade Mode",
    accessor: "mode",
  },
]
