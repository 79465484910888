import { useForm } from "@mantine/form"
import { Card, MenuDivider, Modal, SpinnerOverlay } from "@vesatogo/grass-core"
import classNames from "classnames"
import dayjs from "dayjs"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { OperationContext } from "urql"
import { CustomToast } from "~/components/CustomToast"
import { TransactionHeader } from "~/components/Transaction/Header"
import { TransactionDetails } from "~/components/Transaction/TransactionDetail"
import {
  HistoryColumns,
  TransactionHistory,
} from "~/components/Transaction/TransactionHistory"
import {
  Transaction_Constraint,
  Transaction_Update_Column,
  useAddUpdateSharedEntityMutation,
  useInsertTransactionMutation,
} from "~/generated/graphql"

const INITIAL_STATE = {
  payment_type: "full",
  amount: undefined,
  attachments: [],
}

export const TransactionModal = ({
  refetch,
  transactions,
  tradeId,
  receiptId,
  invoiceId,
  fetching = false,
  totalAmount = 0,
  createdAt,
  kind,
  objectId,
  sellerName,
  advanceAmount,
  idLabel,
  parameterName,
  headerTotalAmount = 0,
  historyCols = "",
  historyColSpan = "",
  hideBank = false,
  bankSelectorOptions,
  columns = [
    HistoryColumns.DATE?.accessor,
    HistoryColumns.PATTI_NO?.accessor,
    HistoryColumns.PATTI_TYPE?.accessor,
    HistoryColumns.ADVANCE_TYPE?.accessor,
    HistoryColumns.PAYMENT_MODE?.accessor,
    HistoryColumns.ADVANCE_AMOUNT?.accessor,
    HistoryColumns.TRANSACTION_ID?.accessor,
    HistoryColumns.ACCOUNT_NO.accessor,
    HistoryColumns.RELEASED_AMOUNT?.accessor,
  ],
}: {
  refetch?: (opts?: Partial<OperationContext> | undefined) => void
  transactions?: any[]
  fetching?: boolean
  columns?: any[]
  tradeId?: any
  receiptId?: any
  invoiceId?: any
  totalAmount?: number
  sellerName?: String
  createdAt?: Date
  objectId?: number
  kind?: String
  advanceAmount?: any
  idLabel?: String
  parameterName?: String
  headerTotalAmount?: any
  historyCols?: string
  historyColSpan?: string
  hideBank?: boolean
  bankSelectorOptions?: any[]
}) => {
  //   const { invoiceId, showInvoice } = ;
  const [searchParams, setSearchParams] = useSearchParams()
  const advanceId = searchParams.get("advanceId")
  const id = searchParams.get("id")
  const isOpen = Boolean(searchParams.get("transaction")) || false
  const [, insertTransaction] = useInsertTransactionMutation()
  const [, addUpdateSharedEntity] = useAddUpdateSharedEntityMutation()
  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  // const transactions = data?.advance_payment_by_pk?.transactions
  const paid = transactions?.reduce(
    (acc, trans) => acc + trans?.amount,
    0
  ) as number
  const remaining =
    Number((totalAmount - paid).toFixed(2)) < 0
      ? 0
      : Number((totalAmount - paid).toFixed(2))
  const isDisabled = false

  const { values: state, setValues: setState } = form
  function getFileUploadProps(
    key: string,
    maxFiles = 4,
    shouldEncrypt = false
  ) {
    const inputProps = form.getInputProps(key)
    return {
      media: inputProps.value,
      onChange: inputProps.onChange,
      maxFiles,
      shouldEncrypt,
      onError: toast.error,
    }
  }

  useEffect(() => {
    setState(val => ({ ...val, amount: remaining, remaining: remaining }))
  }, [])

  function getInputProps(key: string) {
    const inputProps = form.getInputProps(key)
    return {
      ...inputProps,
    }
  }
  // getInputProps("")?.onChange()

  const onClose = () => {
    searchParams.delete("transaction")
    searchParams.delete("advanceId")
    searchParams.delete("invoiceId")
    searchParams.delete("id")
    setSearchParams(searchParams)
    form.reset()
  }

  useEffect(() => {
    setState({
      amount: remaining,
      payment_type: "full",
    })
  }, [transactions, remaining])

  const onSubmit = async () => {
    const transaction = state
    if (transaction?.amount > remaining) {
      return CustomToast({
        title: "Please enter value less than " + remaining,
        intent: "danger",
      })
    }
    if (!hideBank && !transaction?.bank_name) {
      return CustomToast({
        intent: "danger",
        title: "Please select a Bank",
      })
    }

    let bankId = undefined

    if (!hideBank) {
      const { data: bankData, error: bankErr } = await addUpdateSharedEntity({
        reference_id: transaction?.bank_name?.id,
        group_name: "kite.payment_detail",
        name: transaction?.bank_name?.account_holder_name,
      })
      if (bankErr)
        return CustomToast({
          intent: "danger",
          title:
            "Something went wrong while fetching Bank details! " +
            bankErr?.message,
        })
      bankId = bankData?.insert_setu_trade_shared_entity_one?.id
    }

    const { data: insertData, error } = await insertTransaction({
      object: {
        advance_payment_id: advanceId,
        amount: transaction?.amount,
        bank_id: !hideBank ? bankId : undefined,
        transaction_id: transaction?.transaction_id,
        trade_id: tradeId,
        mode_id: transaction?.mode?.id,
        date:
          dayjs(transaction?.transaction_date).format("MM-DD-YYYY") ||
          new Date(),
        account_no: transaction?.bank_name?.account_number,
        receipt_id: receiptId,
        invoice_id: invoiceId,
        attachments: {
          data: transaction?.attachments?.map(att => {
            return { media_id: att.id }
          }),
        },
      },
      onConflict: {
        constraint: Transaction_Constraint.TransactionPkey,
        update_columns: [
          Transaction_Update_Column.BankId,
          Transaction_Update_Column.Amount,
          Transaction_Update_Column.Date,
          Transaction_Update_Column.ModeId,
          Transaction_Update_Column.TransactionId,
          Transaction_Update_Column.AccountNo,
        ],
      },
    })

    if (error) {
      return CustomToast({
        title: "Something went wrong! " + error.message,
        intent: "danger",
      })
    }
    refetch?.({
      requestPolicy: "network-only",
    })
    onClose()

    return CustomToast({ title: "Successfully saved transaction!" })
  }

  return (
    <Modal
      bodyClassName="max-w-7xl overflow-visible"
      isOpen={isOpen}
      onClose={onClose}
      title={"Transaction Details"}
      footerClassName={classNames(remaining === 0 ? "!hidden" : "")}
      primaryActionButtonProps={{
        text: "Yes, Confirm",
        size: "sm",
        onClick: onSubmit,
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        variant: "outline",
        size: "sm",
        onClick: onClose,
      }}
    >
      <Card className="!rounded-none p-2 bg-gray-100 text-gray-800">
        <SpinnerOverlay show={fetching} />
        <TransactionHeader
          totalAmount={headerTotalAmount || totalAmount}
          seller={sellerName}
          created_at={createdAt}
          id={objectId}
          kind={kind}
          remainingAmount={remaining}
          advanceAmount={advanceAmount}
          idLabel={idLabel}
          parameter_name={parameterName}
        />
        {transactions?.length === 0 ? (
          <></>
        ) : (
          <>
            <MenuDivider />
            <TransactionHistory
              transactions={transactions}
              columns={columns}
              cols={historyCols}
              colSpan={historyColSpan}
            />
          </>
        )}
      </Card>

      {remaining !== 0 && (
        <TransactionDetails
          getFileUploadProps={getFileUploadProps}
          getInputProps={getInputProps}
          accessPath={`transaction`}
          maxFiles={4}
          application={state}
          setFieldValue={form.setFieldValue}
          uploaderClassName="row-span-4 "
          hideBank={hideBank}
          bankSelectorOptions={bankSelectorOptions}
        ></TransactionDetails>
      )}
    </Modal>
  )
}
