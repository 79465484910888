import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  Circle,
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Receipt,
} from "@phosphor-icons/react"
import {
  Avatar,
  CodenameTag,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  SegmentedControl,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import dayjs from "dayjs"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RemarkModal } from "~/components/Remark/RemarkModal"
import TradeKindTag from "~/components/TradeKindTag"
import { HistoryColumns } from "~/components/Transaction/TransactionHistory"
import { TransactionModal } from "~/components/Transaction/TransactionModal"
import { PayableReceivableDate } from "~/constants/Payable"
import { siteConfig } from "~/constants/config"
import { AppRoutes } from "~/constants/routes"
import { TRADE_KINDS } from "~/constants/static.items"
import {
  Order_By,
  useAuthenticatedUserQuery,
  useNetPayableReceiptDetailQuery,
  useNetPayableReceiptListQuery,
  useNetPayableTradeDetailQuery,
  useNetPayableTradeListQuery,
  useUpdateReceiptNetPaymentMutation,
  useUpdateTradeNetPaymentMutation,
} from "~/generated/graphql"
import PayableFilter, {
  PayableFilterType,
} from "~/pages/d/payable/PayableFilter"
import {
  PayableSelector,
  usePayableSelector,
} from "~/pages/d/payable/PayableSelector"
import { inrFormatter } from "~/utils/inrFormatter"

const historyColumns = [
  HistoryColumns.DATE?.accessor,
  HistoryColumns.PAYMENT_OPT?.accessor,
  HistoryColumns.PAYMENT_MODE?.accessor,
  HistoryColumns.TRANSACTION_ID?.accessor,
  HistoryColumns.RELEASED_AMOUNT?.accessor,
  HistoryColumns.ACCOUNT_NO?.accessor,
]
const NetPayable = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<PayableFilterType>({})
  const [params, setParams] = useSearchParams()
  const page = Number(params.get("page")) || 1
  const id = params.get("id")
  const search = params.get("search")
  const [department] = useLocalStorage({ key: "department" })
  const { selected } = usePayableSelector()
  const isTrade = selected === "trade"
  const [debouncedSearch] = useDebouncedValue(
    search?.trim(),
    siteConfig.debounce
  )
  const [{ data: authData }] = useAuthenticatedUserQuery()
  const me = authData?.me

  const [{ data: tradeList, fetching: tradeFetching }, refetchTradeList] =
    useNetPayableTradeListQuery({
      variables: {
        limit: siteConfig.pageSize,
        offset: (page - 1) * siteConfig.pageSize,
        orderBy: {
          created_at: Order_By.DescNullsLast,
        },
        where: {
          created_at: {
            _lte: filter?.date_to
              ? dayjs(filter?.date_to).endOf("d")
              : undefined,
            _gte: filter?.date_from
              ? dayjs(filter?.date_from).startOf("d")
              : undefined,
            _gt: dayjs(PayableReceivableDate).startOf("d"),
          },
          ...(filter?.placed_by
            ? {
                created_by_id: {
                  _eq: filter?.placed_by?.id?.toString(),
                },
              }
            : undefined),
          ...(isTrade
            ? {
                kind: {
                  _in: ["final", "inward"],
                  _eq: filter?.patti_type,
                },
                department: {
                  reference_id: {
                    _eq:
                      (department && String(department)) ||
                      me?.agent?.current_department?.codename,
                  },
                },
                ...(debouncedSearch
                  ? {
                      id:
                        debouncedSearch && Number(debouncedSearch)
                          ? {
                              _eq: Number(debouncedSearch),
                            }
                          : undefined,
                    }
                  : undefined),
                _or: [
                  {
                    seller:
                      debouncedSearch && !Number(debouncedSearch)
                        ? {
                            name: {
                              _ilike: `%${debouncedSearch}%`,
                            },
                          }
                        : undefined,
                  },
                ]?.filter(fil => fil),
              }
            : undefined),
          _or: [
            {
              parent_trade_id: {
                _is_null: true,
              },
            },
            {
              parent_trade: {
                kind: {
                  _nin: ["inward"],
                },
              },
            },
          ],
        },
      },
      pause: !isTrade,
    })

  const [{ data: receiptList, fetching: receiptFetching }, refetchReceiptList] =
    useNetPayableReceiptListQuery({
      variables: {
        limit: siteConfig.pageSize,
        offset: (page - 1) * siteConfig.pageSize,
        orderBy: {
          created_at: Order_By.DescNullsLast,
        },
        where: {
          ...(filter?.placed_by
            ? {
                seller: {
                  id: {
                    _eq: filter?.placed_by?.id?.toString(),
                  },
                },
              }
            : undefined),
          ...(!isTrade
            ? {
                kind: {
                  _in: ["final"],
                  _eq: filter?.patti_type,
                },
                department: {
                  reference_id: {
                    _eq:
                      (department && String(department)) ||
                      me?.agent?.current_department?.codename,
                  },
                },
                ...(debouncedSearch
                  ? {
                      id:
                        debouncedSearch && Number(debouncedSearch)
                          ? {
                              _eq: Number(debouncedSearch),
                            }
                          : undefined,
                    }
                  : undefined),
                _or: [
                  {
                    seller:
                      debouncedSearch && !Number(debouncedSearch)
                        ? {
                            name: {
                              _ilike: `%${debouncedSearch}%`,
                            },
                          }
                        : undefined,
                  },
                ]?.filter(fil => fil),
              }
            : undefined),
        },
      },
      pause: isTrade,
    })

  const [{ data: tradeDetailData, fetching: tradeDetailFetching }] =
    useNetPayableTradeDetailQuery({
      variables: {
        id: id,
      },
      pause: !id,
      requestPolicy: "network-only",
    })
  const [{ data: receiptDetailData, fetching: receiptDetailFetching }] =
    useNetPayableReceiptDetailQuery({
      variables: {
        id: id,
      },
      pause: !id,
      requestPolicy: "network-only",
    })
  const totalRows = isTrade
    ? tradeList?.trade_aggregate?.aggregate?.count || 0
    : receiptList?.receipt_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    {
      Header: "Created On",
      accessor: "created_at",
      Cell({ value }) {
        // if (!value) return "--"
        return (
          <Cell
            title={dayjs(value).format("D MMM 'YY")}
            subtitle={dayjs(value).format("hh:mm a")}
          />
        )
      },
      formatter: () => {
        return "Total"
      },
    },
    {
      Header: <div className="pl-3">{isTrade ? "Trade Id" : "Receipt Id"}</div>,
      id: "id",
      Cell({ row: { original } }) {
        const value = isTrade ? original?.id : original?.id
        if (!value) return "--"
        return (
          <div className="pl-4">
            <div
              className="link-text text-left w-fit cursor-pointer"
              onClick={() => {
                params.set("transaction", "true")
                params.set("id", original?.id)
                setParams(params)
              }}
            >
              #{value}
            </div>
          </div>
        )
      },
    },
    {
      Header: "Type",
      id: "kind",
      Cell({ row: { original } }) {
        const value = isTrade ? original?.kind : original?.kind
        const kind = TRADE_KINDS.find(k => k.id === value)
        if (!kind?.name) return "--"

        return <TradeKindTag kind={kind?.id as any} />
      },
    },

    {
      Header: "Seller",
      accessor: "seller.user[0]",
      id: "seller",
      Cell({ row: { original } }) {
        const value = isTrade
          ? original?.seller?.user?.[0]
          : original?.seller?.user?.[0]
        if (!value) return "--"
        return (
          <Cell
            title={value.full_name}
            subtitle={<CodenameTag referenceId={value.external_reference_id} />}
          />
        )
      },
    },
    {
      Header: "Total Amount",
      accessor: "seller_payable",
      id: "seller_payable",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 !text-green" title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },

    {
      Header: "Advance Amount",
      accessor: "advance_payments_aggregate.aggregate.sum.amount",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 !text-green" title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },

    {
      Header: "Paid Amount",
      accessor: "transactions_aggregate.aggregate.sum.amount",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 !text-green" title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },
    {
      Header: "Payable Amount",
      accessor: "payable_amount",
      Cell({ value, row: { original } }) {
        const totalAmount = original?.seller_payable
        const paid = original?.transactions_aggregate?.aggregate?.sum?.amount
        const remaining = Number((totalAmount - paid).toFixed(2))
        if (!remaining || remaining < 0) return "--"
        return (
          <Cell titleClassName="font-700 !text-red" title={inr(remaining)} />
        )
      },
    },

    {
      Header: "Placed By",
      accessor: "created_by[0]",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.full_name}
              src={value?.photo?.url}
            />
          </Tooltip>
        )
      },
    },
    {
      Header: "Transaction Status",
      accessor: "transactions",
      Cell({ row: { original }, value }) {
        const totalAmount = original?.seller_payable
        const paid = original?.transactions_aggregate?.aggregate?.sum?.amount
        const remaining = totalAmount - paid
        const isSettled = remaining === 0 || remaining < 0
        return (
          <Cell
            titleClassName={classNames(
              "flex gap-1 items-center font-600 ",
              isSettled ? "!text-green" : paid ? "!text-blue" : "!text-yellow"
            )}
            title={
              <>
                <Circle weight="fill" />
                {isSettled ? "Settled" : paid ? "Partial" : "Pending"}
              </>
            }
          />
        )
      },
    },
    {
      Header: "Last Transaction",
      accessor: "transactions.0.date",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell title={<>{dayjs(value).format("DD MMM 'YYYY")}</>} />
      },
    },
    {
      Header: "Remark",
      accessor: "remark",
      Cell({ value, row: { original } }) {
        const parentReceiptId = original?.transactions?.[0]
          ?.parent_transaction_id
          ? original?.transactions?.[0]?.trade?.receipt_id
          : undefined
        return (
          <>{parentReceiptId ? `Settled Receipt #${parentReceiptId}` : value}</>
        )
      },
    },
    {
      Header: "",
      accessor: "updated_at",
      Cell({ row: { original } }) {
        const [params, setParams] = useSearchParams()

        return (
          <Popover
            minimal
            arrow={false}
            className="p-0"
            trigger="mouseenter"
            interactive
            placement="bottom"
            content={
              <Menu className="max-h-72">
                <MenuItem
                  onClick={() => {
                    params.set("remark", original.id)
                    setParams(params)
                  }}
                >
                  Remark
                </MenuItem>
              </Menu>
            }
          >
            <button className="hover:bg-gray-300 rounded">
              <DotsThreeVertical size={20} />
            </button>
          </Popover>
        )
      },
    },
  ]
  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"net"}
          onChange={value => {
            if (value === "advance") {
              navigate(AppRoutes.advancePayable)
            }
          }}
          data={[
            {
              label: "Advance",
              value: "advance",
              icon: <List className="mr-1" />,
            },
            {
              label: "Net Payable",
              value: "net",
              icon: <Receipt className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            leftElement={<MagnifyingGlass />}
            inputProps={{
              placeholder: "Search by Trade id, Seller name ",
              value: search || "",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
          />
          <PayableFilter
            filter={filter}
            setFilter={setFilter}
            hideAdvanceStatus
            hideAdvanceType
            hideTransactionStatus
            hideBookingPatti
            hideSaudaPavti
            hideStoragePatti
            hideTradeKind={!isTrade}
          />
        </div>
        <div className="flex ">
          <PayableSelector />
        </div>
      </header>
      <RemarkModal
        refetch={isTrade ? refetchTradeList : refetchReceiptList}
        title="Remark"
        useAddRemarkMutation={
          isTrade
            ? useUpdateTradeNetPaymentMutation
            : useUpdateReceiptNetPaymentMutation
        }
      />
      <TransactionModal
        refetch={isTrade ? refetchTradeList : refetchReceiptList}
        transactions={
          isTrade
            ? tradeDetailData?.trade_by_pk?.transactions
            : receiptDetailData?.receipt_by_pk?.transactions
        }
        columns={historyColumns}
        totalAmount={
          isTrade
            ? tradeDetailData?.trade_by_pk?.seller_payable || 0
            : receiptDetailData?.receipt_by_pk?.seller_payable || 0
        }
        fetching={isTrade ? tradeDetailFetching : receiptDetailFetching}
        createdAt={
          isTrade
            ? tradeDetailData?.trade_by_pk?.created_at
            : receiptDetailData?.receipt_by_pk?.created_at
        }
        headerTotalAmount={
          isTrade
            ? tradeDetailData?.trade_by_pk?.seller_payable
            : receiptDetailData?.receipt_by_pk?.seller_payable
        }
        idLabel={isTrade ? "Trade Id" : "Receipt Id"}
        objectId={
          isTrade
            ? tradeDetailData?.trade_by_pk?.id
            : receiptDetailData?.receipt_by_pk?.id
        }
        historyCols={`!grid-cols-6`}
        historyColSpan={`!col-span-4`}
        tradeId={isTrade ? tradeDetailData?.trade_by_pk?.id : undefined}
        receiptId={isTrade ? undefined : receiptDetailData?.receipt_by_pk?.id}
        bankSelectorOptions={
          isTrade
            ? tradeDetailData?.trade_by_pk?.seller?.user?.[0]?.payment_details
                ?.length !== 0
              ? tradeDetailData?.trade_by_pk?.seller?.user?.[0]
                  ?.payment_details || []
              : tradeDetailData?.trade_by_pk?.seller?.user?.[0]
                  ?.my_businesses?.[0]?.payment_details || []
            : receiptDetailData?.receipt_by_pk?.seller?.user?.[0]
                ?.payment_details?.length !== 0
            ? receiptDetailData?.receipt_by_pk?.seller?.user?.[0]
                ?.payment_details || []
            : receiptDetailData?.receipt_by_pk?.seller?.user?.[0]
                ?.my_businesses?.[0]?.payment_details || []
        }
      />
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        paginationProps={{
          total: Math.ceil(totalRows / siteConfig.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={isTrade ? tradeFetching : receiptFetching}
        columns={COLUMNS}
        data={isTrade ? tradeList?.trade || [] : receiptList?.receipt || []}
        meta={<>{totalRows} results</>}
        aggregateColumnClassName={
          "bg-blue-100 hover:bg-blue-100 p-10 sticky bottom-0 z-[8] font-600"
        }
      />
    </>
  )
}

export default NetPayable
