export const AppRoutes = {
  home: "/",
  tradeReceipts: "/d/trade-requests/receipts",
  tradeInvoices: "/d/trade-requests/invoices",
  tradeReceiptDetail(id: string | number) {
    return `/d/trade-requests/receipts/${id}`
  },
  tradeRequests: "/d/trade-requests",
  hishobPatti: "/d/mandi-listing/hishob-patti",
  mixPatti: "/d/mandi-listing",
  advancePayable: "/d/payable",
  netPayable: "/d/payable/net",
  plantSettlement: "/d/bank-reconciliation",
  invoiceSettlement: "/d/bank-reconciliation/invoice-settlement",
} as const

export const APIRoutes = {
  downloadTradeReceipt(id: string | number) {
    return `rest/trade/receipt_pdf/${id}/`
  },
  downloadGatePass(id: string | number) {
    return `rest/trade/gatepass/${id}/`
  },
  downloadPavti(hash: string) {
    return `rest/trade/receipt/${hash}`
  },
  downloadInvoice(id: string | number) {
    return `rest/trade/invoice_pdf/${id}/`
  },
}
