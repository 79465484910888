import { Selector, SelectorProps } from "@vesatogo/grass-core"
import { debounce } from "lodash-es"
import { useState } from "react"

export type QuerySelectorProps = Omit<SelectorProps, "getOptionLabel"> & {
  listPath?: string
  /**
   * @description The hook generated after running graphql codegen
   */
  dataHook?: any
  variables?: any
  serverSideQuery?: boolean
  useWildCardsInQuery?: boolean
  allowSelectAll?: boolean
  getOptionLabel?: (item: any) => any
}

export const QuerySelector = ({
  listPath,
  dataHook: useDataHook,
  serverSideQuery,
  variables,
  useWildCardsInQuery = true,
  allowSelectAll = false,
  onChange,
  getOptionLabel = item => item.name,
  ...props
}: QuerySelectorProps) => {
  const [query, setQuery] = useState("")
  const [{ data, loading }] = useDataHook({
    variables: serverSideQuery
      ? { ...variables, query: useWildCardsInQuery ? `%${query}%` : query }
      : variables,
  })
  const path = listPath || (data && Object.keys(data)?.[0])

  const list = data?.[path]
  if (
    props.isMulti &&
    allowSelectAll &&
    list?.length > 0 &&
    list[0]?.id !== "select_all"
  ) {
    list?.unshift({
      id: "select_all",
      name: "Select All",
      value: "Select All",
    })
  }

  const inputChangeDebounced = debounce(query => {
    if (query.length > 2) {
      setQuery(query)
    }
  }, 500)

  const handleOnChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setQuery("")
    }
    let value = selectedOption

    if (
      allowSelectAll &&
      props.isMulti &&
      selectedOption.some(e => e.id === "select_all")
    ) {
      value = list.filter(item => item.id !== "select_all")
    }

    onChange && onChange(value, triggeredAction)
  }

  return (
    <Selector
      isLoading={loading}
      isSearchable={true}
      onInputChange={inputChangeDebounced}
      filterOption={serverSideQuery ? () => true : undefined}
      options={list}
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}
