import { useLocalStorage } from "@mantine/hooks"
import { FormGroup, Modal, QuerySelector, Toast } from "@vesatogo/grass-core"
import { uniqBy } from "lodash-es"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { useLocation, useSearchParams } from "react-router-dom"
import { useTradeStatusesQuery } from "~/generated/graphql"

type UpdateTradeStatusProps = {
  storeListHook?: any
  bulkUpdateHook?: any
  entity?: "trade" | "invoice"
}
const UpdateTradeStatus = ({
  storeListHook: useStoreList,
  bulkUpdateHook: useBulkUpdateMutation,
  entity = "trade",
}: UpdateTradeStatusProps) => {
  const [params, setParams] = useSearchParams()
  const { state } = useLocation()
  const selectedList = useStoreList(store => store.selected)
  const selectedListKeys = Object.keys(selectedList)
  const totalItems = selectedListKeys?.length
  const resetSelected = useStoreList(store => store.reset)
  const [newStatus, setNewStatus] = useState<any>(null)
  const update = params.get("update")
  const entityId = update && parseInt(update)
  const isOpen = update === "all" || entityId ? true : false
  const [{ fetching }, updateStatuses] = useBulkUpdateMutation()
  const [department] = useLocalStorage({ key: "department" })

  const selectedListArr = Object.values(selectedList)
  const totalStatuses = uniqBy(selectedListArr, "current_status_id")?.length

  const hasMultipleStatuses = totalStatuses > 1
  const currentStatus =
    state?.current_status_id ||
    selectedList[selectedListKeys?.[0]]?.current_status_id
  const isProcessing =
    state?.status_processing ??
    selectedListArr.some((obj: any) => obj.status_processing)

  return (
    <Modal
      overflow
      title={
        entityId ? `Update ${entity} #${entityId}` : `Update all ${entity}`
      }
      primaryActionButtonProps={{
        text: "Update",
        disabled: isProcessing
          ? true
          : entityId
          ? false
          : hasMultipleStatuses
          ? true
          : false,
        loading: fetching,
        async onClick() {
          try {
            if (!newStatus?.id)
              throw new Error("Please select atleast one of the statuses")

            if (!department)
              throw new Error("Please select a department before proceeding")

            if (update === "all" && !selectedListKeys.length)
              throw new Error(
                `Please select atleast one ${entity} before proceeding`
              )
            if (isProcessing)
              throw new Error(
                "Some trades are already processing in the backend, please wait sometime before making a change"
              )
            const { data, error } = await updateStatuses({
              where:
                update === "all"
                  ? {
                      id: {
                        _in: selectedListKeys,
                      },
                    }
                  : { id: { _eq: entityId } },
              set: {
                // status: status?.id,
                current_status_id: newStatus?.id,
                status_processing: true,
                // current_status_id: null,
              },
            })

            const result = data?.[Object.keys(data)?.[0]]
            if (result) {
              params.delete("update")
              params.set("updatedAt", Date.now().toString())
              setParams(params)
              resetSelected()
              setNewStatus(null)
              return toast.custom(
                <Toast title={`Updated ${result?.affected_rows} ${entity}`} />
              )
            }
            if (error) throw new Error(error.message)
          } catch (error) {
            resetSelected()
            return toast.custom(
              <Toast
                title={
                  (error as any)?.message ||
                  "Unable to update status, please try again"
                }
                intent="danger"
              />
            )
          }
        },
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        loading: fetching,
        onClick() {
          params.delete("update")
          setParams(params)
        },
      }}
      isOpen={isOpen}
      onClose={() => {
        params.delete("update")
        setParams(params)
      }}
    >
      {update === "all" && (
        <Toast
          title={
            (hasMultipleStatuses ? (
              <span>
                <b>Multiple {entity} statuses</b> found in {totalItems || 0}{" "}
                {entity}s, please ensure all the {entity}s have the same status
                before updating at once
              </span>
            ) : (
              <span>
                This will update all the <b>{totalItems || 0}</b> {entity}s
              </span>
            )) as any
          }
          className="max-w-full mb-4"
          intent={hasMultipleStatuses ? "danger" : "warning"}
          variant="minimal"
        />
      )}

      <div className="grid grid-cols-1 gap-4">
        <FormGroup label="Status">
          <QuerySelector
            getOptionLabel={option => option.id}
            dataHook={useTradeStatusesQuery}
            variables={{
              where: {
                parents: {
                  parent: {
                    id: {
                      ...(currentStatus
                        ? { _eq: currentStatus }
                        : { _is_null: true }),
                    },
                  },
                },
                _or: [
                  {
                    category: {
                      _eq: "fixed",
                    },
                  },
                  {
                    category: {
                      _eq: "dynamic",
                    },
                    departments: {
                      department: {
                        reference_id: {
                          _eq: department?.toString(),
                        },
                      },
                    },
                  },
                ],
              },
            }}
            value={newStatus}
            onChange={setNewStatus}
          />
        </FormGroup>
      </div>
    </Modal>
  )
}

export default UpdateTradeStatus
