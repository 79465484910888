import { omit } from "lodash"
import { create } from "zustand"
import { SelectorType } from "~/store/types"

export const useReceiptSelectorList = create<SelectorType>((set, get) => ({
  selected: {},
  setSelected(value) {
    if (get().selected[value.id]) {
      set({ selected: omit(get().selected, value.id) })
    } else {
      set({ selected: { ...get().selected, [value.id]: value } })
    }
  },
  isSelectAll(o1, o2) {
    return (
      Object.values(o1).filter((k: any) => {
        return o2?.some(a => a.id === k.id)
      }).length === Object.values(o2).length
    )
  },
  deselectAll() {
    set({ selected: {} })
  },
  selectAll(values) {
    const selected = {}
    values.forEach(value => {
      selected[value.id] = value
    })
    set({ selected: { ...get().selected, ...selected } })
  },
  reset() {
    set({ selected: {} })
  },
}))
