import { Group, Text, Modal, Selector, Button } from "@vesatogo/grass-core"
import { useSearchParams } from "react-router-dom"
import { TableView } from "@vesatogo/grass-table"

import { Plus } from "@phosphor-icons/react"
import { cloneDeep, get, set } from "lodash-es"
import { useState } from "react"
import { ExpensesTable } from "~/components/ExpensesTable"
import { siteConfig } from "~/constants/config"
import { TRADE_KINDS } from "~/constants/static.items"
import {
  useCreateExpensesTemplatesMutation,
  useCreateTnCsConfigMutation,
} from "~/generated/graphql"
import {
  templateTypeMap,
  TEMPLATE_COLUMNS,
} from "~/pages/d/trade-templates/data"
import { normalizeExpenses } from "~/utils/expenseAmount"
import { useDepartment } from "~/utils/useDepartment"
import Editor from "~/components/Editor/Editor"

type IProps = {
  type: "expenses" | "seller" | "buyer"
}

export function TradeTemplates({ type }: IProps) {
  const [params, setParams] = useSearchParams()
  const page = Number(params.get("page")) || 1
  const [expenses, setExpenses] = useState<any[]>([{}])
  const [tnc, setTnc] = useState("")
  const { id: departmentId, name: departmentName } = useDepartment()

  const [tradeMode, setTradeMode] = useState<{ id: string; name: string }>({
    id: "facilitator",
    name: "Facilitator",
  })
  const [tradeKind, setTradeKind] = useState(TRADE_KINDS[0])

  const [{ data: versionsData }, refetch] = templateTypeMap[type].listQuery({
    variables: {
      department: `${departmentId}`,
      kind: tradeKind?.id,
      mode: tradeMode.id,
      role: type,
    },
    pause: !departmentId,
    requestPolicy: "network-only",
  })
  const versions = versionsData?.[templateTypeMap[type].listKey]

  const [{ data: detailsData }] = templateTypeMap[type].detailsQuery({
    variables: {
      version: params.get("id"),
      department: departmentId,
      kind: tradeKind?.id,
      mode: tradeMode.id,
      role: type,
    },
    pause: !params.get("id"),
    requestPolicy: "network-only",
  })
  const details = detailsData?.[templateTypeMap[type]?.detailsKey]
  const detailsNormalized =
    type === "expenses" && normalizeExpenses(details || [])

  function handleProps(key: string, state?: any) {
    return {
      value: get(state || expenses, key, ""),
      onChange: async (e: any) => {
        const stateCopy = cloneDeep(state || expenses)
        set(stateCopy, key, e?.target ? e.target.value : e)
        setExpenses(stateCopy)
      },
    }
  }

  const [, createExpenses] = useCreateExpensesTemplatesMutation()

  function handleExpenseCreate() {
    createExpenses({
      department: Number(departmentId),
      kind: tradeKind?.id,
      mode: tradeMode.id,
      expenses: expenses?.reduce((prev, expense, idx) => {
        const commonProperties = {
          expense_parameter_id: expense.expense_parameter?.id,
          kind: expense.kind?.id,
        }
        if (expense.buyer?.value) {
          prev.push({
            ...commonProperties,
            paid_by: "buyer",
            value: Number(expense.buyer.value),
          })
        }
        if (expense.seller?.value) {
          prev.push({
            ...commonProperties,
            paid_by: "seller",
            value: Number(expense.seller.value),
          })
        }
        return prev
      }, []),
    }).then(() => {
      refetch()
      setExpenses([{}])
      setParams({})
    })
  }

  const [, createTnc] = useCreateTnCsConfigMutation()

  function handleTncCreate() {
    createTnc({
      department: Number(departmentId),
      kind: tradeKind?.id,
      mode: tradeMode.id,
      party: type,
      terms: tnc,
    }).then(() => {
      refetch()
      setTnc("")
      setParams({})
    })
  }

  return (
    <div>
      <Group className="flex items-center flex-wrap gap-4 justify-between p-2 px-[10%] border-b-1 border-b-gray-300">
        <Text className="text-gray-800">
          {templateTypeMap[type].name} Templates
        </Text>
        <Group className="flex items-center flex-wrap gap-2">
          <Selector
            className="z-[15]"
            options={TRADE_KINDS}
            isClearable={false}
            value={tradeKind}
            onChange={(value: any) => setTradeKind(value)}
          />
          <Selector
            className="z-[15]"
            options={[
              { id: "direct", name: "Direct" },
              { id: "facilitator", name: "Facilitator" },
            ]}
            isClearable={false}
            value={tradeMode}
            onChange={(value: any) => setTradeMode(value)}
          />
          <Button
            className="ml-6"
            leftIcon={<Plus />}
            onClick={() => setParams({ create: "true" })}
          >
            Create
          </Button>
        </Group>
      </Group>
      <div className="px-[10%]">
        <TableView
          relative={false}
          className="!max-h-[calc(100vh-64px-57px)]"
          paginationProps={{
            total: Math.ceil(Number(versions?.length) / siteConfig.pageSize),
            page: page,
            onChange(page) {
              params.set("page", page.toString())
              params.set("search", "")
              setParams(params)
            },
          }}
          isLoading={false}
          columns={TEMPLATE_COLUMNS}
          data={versions || []}
          meta={<>{versions?.length} templates</>}
        />
      </div>
      <Modal
        title={`${templateTypeMap[type].name} Details`}
        isOpen={Boolean(params.get("id"))}
        onClose={() => setParams({})}
        bodyClassName="max-w-3xl"
      >
        <Group className="flex justify-between mb-4 pb-2 border-b-1 border-b-gray-300">
          <div>
            <div className="mb-2">
              Version no.: <b>{params.get("id")}</b>
            </div>
            <div>
              Department: <b>{departmentName}</b>
            </div>
          </div>
          <div>
            <div className="mb-2">
              Kind: <b>{tradeKind?.name}</b>
            </div>
            <div>
              Mode: <b>{tradeMode?.name}</b>
            </div>
          </div>
        </Group>
        {type === "expenses" ? (
          <ExpensesTable
            expenses={detailsNormalized}
            handleProps={key => handleProps(key, detailsNormalized)}
            isDisabled={true}
          />
        ) : (
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: details?.[type] }}
          ></div>
        )}
      </Modal>
      <Modal
        title={`Create ${templateTypeMap[type].name} Template`}
        bodyClassName="overflow-visible max-w-3xl"
        isOpen={Boolean(params.get("create"))}
        onClose={() => setParams({})}
      >
        <div className="flex flex-col gap-2">
          {type === "expenses" ? (
            <>
              <Button
                onClick={() => {
                  setExpenses(prev => (prev?.length ? [{}, ...prev] : [{}]))
                }}
                leftIcon={<Plus />}
                size="sm"
                className="justify-self-end ml-auto"
              >
                Expense
              </Button>
              <ExpensesTable expenses={expenses} handleProps={handleProps} />
            </>
          ) : (
            <Editor
              value={tnc}
              onEditorChange={value => setTnc(value)}
            />
          )}
          <Button
            onClick={
              type === "expenses" ? handleExpenseCreate : handleTncCreate
            }
            size="sm"
            className="justify-self-end ml-auto"
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  )
}
