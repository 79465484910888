import { Modal, NoDataFound, Tag } from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import TransactionTable from "~/components/TransactionTable"
import { useUserPackingQuery } from "~/generated/graphql"
import { useMemo } from "react"

type PackingMaterialItem = {
  id: number
  payment_status: string
  transaction_number?: string
  total_amount: number
  total_quantity: number
  total_amount_pending: number
  total_amount_paid: number
  data: {
    id: number
    name: string
    quantity: number
    rate: number
    materialCode: string
    amount: number
  }[]
}

type PackingMaterialSaveModalPropType = {
  pData: any
  isOpen: boolean
  onClose: () => void
}

export const PackingMaterialSaveModal = ({
  pData,
  isOpen,
  onClose,
}: PackingMaterialSaveModalPropType) => {
  const transformData = (pData: any): PackingMaterialItem[] => {
    if (!pData || !pData.packaging_order) return []
    return pData.packaging_order.map((order: any, index: number) => ({
      id: index + 1,
      payment_status: capitalizeStatus(order.payment_status),
      transaction_number: order.transaction_number,
      total_amount: order.total_amount,
      total_quantity: order.total_quantity,
      total_amount_pending: order.total_amount_pending,
      total_amount_paid: order.total_amount_paid,
      data: order.packaging_order_items.map((item: any, subIndex: number) => ({
        id: subIndex + 1,
        name: item.packaging.name,
        quantity: item.quantity,
        rate: item.rate,
        materialCode: item.packaging.material_code,
        amount: item.amount,
      })),
    }))
  }

  const capitalizeStatus = (status: string): string => {
    const statuses: Record<string, string> = {
      partial: "Partial",
      paid: "Paid",
      pending: "Pending",
    }
    return statuses[status.toLowerCase()] || status
  }

  const data: PackingMaterialItem[] = useMemo(
    () => transformData(pData),
    [pData]
  )

  const columns = (
    payment_status: string,
    total_amount: number,
    total_amount_pending: number,
    total_quantity: number,
    total_amount_paid: number
  ) => [
    {
      id: 1,
      name: <span className="text-gray-600">Material Name</span>,
      accessor: "name",
      cell: (value, row) => (
        <>
          <span className="text-gray-800">
            {value}{" "}
            <Tag pill intent="info" variant="minimal">
              {row?.materialCode}
            </Tag>
          </span>
        </>
      ),
      footer: () => (
        <div className="text-gray-800 font-600">
          <h6>Total</h6>
        </div>
      ),
      footer2:
        payment_status === "Partial"
          ? () => (
              <div className="text-gray-800 font-600">
                <h6 className="text-green">Total Paid</h6>
              </div>
            )
          : null,
      footer3:
        payment_status === "Partial"
          ? () => (
              <div className="text-gray-800 font-600">
                <h6 className="text-red">Total Due</h6>
              </div>
            )
          : null,
    },
    {
      id: 2,
      name: <span className="text-gray-600">Quantity</span>,
      accessor: "quantity",
      cell: value => <span className="text-gray-800"> {value}</span>,
      footer: () => (
        <div className="text-gray-800 font-600">
          <h6>{total_quantity}</h6>
        </div>
      ),
    },
    {
      id: 3,
      name: <span className="text-gray-600">Rate</span>,
      accessor: "rate",
      cell: value => <span className="text-gray-800"> {value}</span>,
    },
    {
      id: 4,
      name: <span className="text-gray-600">Amount</span>,
      accessor: "amount",
      cell: value => <span className="text-gray-800"> {value}</span>,
      footer: () => (
        <div className="text-gray-800 font-600">
          <h6>{inr(total_amount)}</h6>
        </div>
      ),
      footer2:
        payment_status === "Partial"
          ? () => (
              <div className="text-gray-800 font-600">
                <h6 className="text-green">{inr(total_amount_paid)}</h6>
              </div>
            )
          : null,
      footer3:
        payment_status === "Partial"
          ? () => (
              <div className="text-gray-800 font-600">
                <h6 className="text-red">{inr(total_amount_pending)}</h6>
              </div>
            )
          : null,
    },
  ]

  const getStatusClass = (status: string): string => {
    const statusClasses: Record<string, string> = {
      Partial: "text-red-500",
      Pending: "text-blue-500",
      Paid: "text-green-500",
    }
    return statusClasses[status] || ""
  }

  const totalPackingAmount = useMemo(() => {
    return data.reduce((total, item) => total + item.total_amount, 0)
  }, [data])

  return (
    <Modal
      title="Packing Material"
      isOpen={isOpen}
      onClose={onClose}
      bodyClassName="w-1/2"
      primaryActionButtonProps={{
        onClick: () => onClose(),
        text: "Close",
      }}
    >
      {data.length > 0 ? (
        <div>
          {data.map((item, index) => (
            <div key={index} className="w-full rounded-lg border-2 mb-3">
              <div className="flex justify-between font-500 gap-2 py-2 px-4 text-gray-700">
                <div className="flex justify-between gap-4">
                  <span>Transaction</span>
                  {item.transaction_number && (
                    <>
                      |
                      <span>
                        SAP Invoice ID{" "}
                        <Tag pill intent="info" variant="minimal">
                          {item.transaction_number}
                        </Tag>
                      </span>
                    </>
                  )}
                </div>
                <div className="flex gap-3 mr-4">
                  <span>20 Jul 2024</span> |
                  <span
                    className={`here ${getStatusClass(item.payment_status)}`}
                  >
                    ● {item.payment_status}
                  </span>
                </div>
              </div>
              <TransactionTable
                data={item.data}
                columns={columns(
                  item.payment_status,
                  item.total_amount,
                  item.total_amount_pending,
                  item.total_quantity,
                  item.total_amount_paid
                )}
                className={"w-full"}
              />
            </div>
          ))}

          <div className="bg-green-100 flex justify-between p-2 px-4 rounded">
            <h6>Final Amount</h6>
            <h6>{inr(totalPackingAmount)}</h6>
          </div>
        </div>
      ) : (
        <NoDataFound title={"No Packing Material Transaction Found"} />
      )}
    </Modal>
  )
}
