import {
  Checkbox,
  FormGroup,
  QuerySelector,
  RadioGroup,
} from "@vesatogo/grass-core"
import { FormCard } from "~/components/FormCard"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import {
  useAllCommoditiesQuery,
  useAllPlotCropsQuery,
  useAllVarietiesQuery,
} from "~/generated/graphql"

type WhatIsBeingTradedProps = {
  form: any
  inputProps: any
  isDisabled: boolean
}
const WhatIsBeingTraded = ({
  form,
  inputProps,
  isDisabled,
}: WhatIsBeingTradedProps) => {
  return (
    <FormCard title="What is being traded">
      <div className="flex gap-4">
        <FormGroup required label="Commodity" className={"w-full"}>
          <QuerySelector
            serverSideQuery
            dataHook={useAllCommoditiesQuery}
            {...inputProps("commodity", {
              group: SharedEntityGroup.KiteCommodity,
            })}
            isDisabled={isDisabled}
          />
        </FormGroup>{" "}
        <FormGroup required label="Variety" className={"w-full"}>
          <QuerySelector
            dataHook={useAllVarietiesQuery}
            variables={{ commodity_id: form.values.commodity?.id }}
            {...inputProps("commodity_variety", {
              group: SharedEntityGroup.KiteVariety,
            })}
            isDisabled={isDisabled}
          />
        </FormGroup>{" "}
        <FormGroup label="Plot Selection" className={"w-full"}>
          <QuerySelector
            dataHook={useAllPlotCropsQuery}
            variables={{
              farmer: form.values.seller?.id,
              crop: form.values.commodity?.crop_id,
            }}
            {...inputProps("plot_crop", {
              group: SharedEntityGroup.KitePlotCrop,
            })}
            getOptionLabel={({ tag, name, external_reference_id, id }) => {
              if (!id) return undefined
              return `${tag} ${name} | ${external_reference_id}`
            }}
            isDisabled={isDisabled}
          />
        </FormGroup>
      </div>
      <div className="mt-2">
        <RadioGroup
          label="Packaging type"
          required
          {...inputProps("packaging")}
        >
          <Checkbox
            type={"radio"}
            value="raw_material"
            label="Raw Material"
            disabled={isDisabled}
          />
          <Checkbox
            type={"radio"}
            value="finished_goods"
            label="Finished Goods"
            disabled={isDisabled}
          />
        </RadioGroup>
      </div>
    </FormCard>
  )
}

export default WhatIsBeingTraded
