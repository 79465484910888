import LoginPage from "~/pages"
import SaudaPavti from "~/pages/pavti/[id]"
import DashboardLayout from "../components/DashboardLayout"

import { dashboardRoutes } from "./dashboard.routes"

const routes: any[] = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "d",
    element: <DashboardLayout />,
    children: dashboardRoutes,
  },
  {
    path: "pavti/:id",
    element: <SaudaPavti />,
  },
]

export default routes
