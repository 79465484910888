import { useForm } from "@mantine/form"
import { Avatar, AvatarGroup, MenuDivider } from "@vesatogo/grass-core"
import classNames from "classnames"
import dayjs from "dayjs"
import { useState } from "react"
import AttachmentModal from "~/components/AttachmentModal"
import TradeKindTag from "~/components/TradeKindTag"

export const HistoryColumns = {
  DATE: { label: "Date", accessor: "date" },
  PATTI_NO: { label: "Patti no.", accessor: "patti_no" },
  PATTI_TYPE: { label: "Patti Type", accessor: "kind" },
  ADVANCE_TYPE: { label: "Advance Type", accessor: "advance_type" },
  PAYMENT_OPT: { label: "Payment Option", accessor: "payment_option" },
  PAYMENT_MODE: { label: "Payment Mode", accessor: "payment_mode" },
  ADVANCE_AMOUNT: { label: "Advance Amount", accessor: "advance_amount" },
  TRANSACTION_ID: {
    label: "Transaction Id",
    accessor: "transaction_id",
  },
  ACCOUNT_NO: { label: "Account Number", accessor: "account_no" },
  RELEASED_AMOUNT: { label: "Released Amount", accessor: "released_amount" },
}

export type TransactionHistoryType = {
  transactions?: any
  columns?: any
  cols?: string
  colSpan?: string
}
export const TransactionHistory = ({
  transactions,
  columns = Object.values(HistoryColumns)?.map(col => col?.accessor),
  cols: className = "",
  colSpan = "",
}: TransactionHistoryType) => {
  const form = useForm()
  const [attachmentModal, setAttachmentModal] = useState(false)
  const total = transactions?.reduce((acc, transaction: any) => {
    return acc + transaction?.amount
  }, 0)
  const len = columns?.length
  return (
    <div className="p-2">
      <div
        className={classNames(`grid grid-cols-${len} text-gray-600`, className)}
      >
        {Object.values(HistoryColumns)
          ?.filter(col => columns?.includes(col?.accessor))
          ?.map(col => {
            return <span key={col?.accessor}>{col?.label}</span>
          })}
      </div>
      <MenuDivider />

      {transactions?.map(transaction => {
        const type = transaction?.trade?.id
          ? transaction?.trade
          : transaction?.receipt?.id
          ? transaction?.receipt
          : undefined
        return (
          <div
            key={transaction?.id}
            className={classNames(
              `grid grid-cols-${len} text-gray-800 text-[14px]`,
              className
            )}
          >
            {Object.values(HistoryColumns)
              ?.filter(col => columns?.includes(col?.accessor))
              ?.map(col => {
                const value =
                  col?.accessor === HistoryColumns.PATTI_TYPE?.accessor ? (
                    <TradeKindTag kind={type?.kind as any} />
                  ) : col.accessor === HistoryColumns.PATTI_NO?.accessor ? (
                    `#${type?.id}`
                  ) : col.accessor === HistoryColumns.DATE?.accessor ? (
                    dayjs(transaction?.date).format("DD MMM 'YY")
                  ) : col?.accessor ===
                    HistoryColumns.ADVANCE_AMOUNT.accessor ? (
                    transaction?.advance_payment?.amount
                  ) : col.accessor ===
                    HistoryColumns.RELEASED_AMOUNT?.accessor ? (
                    <>
                      {Number(transaction?.amount).toLocaleString()}
                      <AvatarGroup>
                        {transaction?.attachments?.map(att => {
                          return (
                            <Avatar
                              onClick={() => {
                                setAttachmentModal(transaction?.id)
                              }}
                              key={att?.id}
                              src={att.media?.url}
                              className="cursor-pointer"
                            ></Avatar>
                          )
                        })}
                      </AvatarGroup>
                    </>
                  ) : col.accessor === HistoryColumns.ACCOUNT_NO?.accessor ? (
                    <div>
                      <div>{transaction?.account_no || "--"}</div>
                      <div className="text-sm text-gray-600">
                        {transaction?.bank?.payment_detail?.bank_name || "--"}
                      </div>
                    </div>
                  ) : col.accessor === HistoryColumns.PAYMENT_MODE?.accessor ? (
                    transaction?.mode?.name
                  ) : col.accessor === HistoryColumns.ADVANCE_TYPE?.accessor ? (
                    transaction?.advance_payment?.parameter?.name
                  ) : col.accessor === HistoryColumns.PAYMENT_OPT?.accessor ? (
                    transaction?.advance_payment?.id ? (
                      "Advance"
                    ) : (
                      "Net"
                    )
                  ) : (
                    transaction?.[col?.accessor]
                  )

                return (
                  <span
                    key={`value${col?.accessor}`}
                    className="grid grid-cols-2"
                  >
                    {value}
                  </span>
                )
              })}
            <MenuDivider className={classNames("", colSpan)} />
          </div>
        )
      })}
      <div className={classNames(`grid grid-cols-${len}`, className)}>
        <div className="font-600">Total</div>
        <div className={classNames("", colSpan)}></div>
        <div className="font-600">{Number(total).toLocaleString()}</div>
      </div>
      <AttachmentModal
        isOpen={Boolean(attachmentModal)}
        attachments={
          transactions?.find(transaction => transaction?.id === attachmentModal)
            ?.attachments || []
        }
        previewOnly
        onClose={() => {
          setAttachmentModal(false)
        }}
      />
    </div>
  )
}
