import { useEffect, useState } from "react"
import { useLocalStorage } from "@mantine/hooks"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import {
  useAuthenticatedUserQuery,
  useAddUpdateSharedEntityMutation,
} from "~/generated/graphql"

export function useDepartment() {
  const [sharedDeptId, setSharedDeptId] = useState<any>()
  const [{ data }] = useAuthenticatedUserQuery()
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()

  const [localDepartmentId] = useLocalStorage({ key: "department" })
  let localDepartment = data?.me?.agent?.departments?.find(
    item => item?.department?.codename === localDepartmentId
  )?.department
  const department = localDepartment || data?.me?.agent?.current_department

  useEffect(() => {
    async function insertDept(department: any) {
      if (department?.id) {
        const { data } = await upsertSharedEntity({
          group_name: SharedEntityGroup.KiteDepartment,
          reference_id: localDepartment?.codename || department?.codename,
          name: localDepartment?.name || department?.name,
        })
        const deptId = data?.insert_setu_trade_shared_entity_one?.id
        if (deptId) {
          setSharedDeptId(deptId)
        }
      }
    }
    insertDept(department)
  }, [department])

  return { id: sharedDeptId, name: department?.name }
}
