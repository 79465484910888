import { Tag } from "@vesatogo/grass-core"
import { TradeKinds, TradeKindsReadable } from "~/constants/Kinds"

export type TradeKindTagProps = {
  kind: keyof typeof TradeKinds
}
const TradeKindTag = ({ kind }: TradeKindTagProps) => {
  return (
    <Tag
      intent={
        kind === TradeKinds.HishobPatti
          ? "primary"
          : kind === TradeKinds.SaudaPavti
          ? "warning"
          : "cyan"
      }
    >
      {TradeKindsReadable[kind]}
    </Tag>
  )
}

export default TradeKindTag
