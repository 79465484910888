import classNames from "classnames";
import { get } from "lodash-es";
import styles from "./SummaryTable.module.css";

type SummaryTableProps = {
  columns?: any[];
  data?: any[];
  header?: any;
  className?: any;
};

const SummaryTable = ({
  columns,
  header,
  className,
  data,
}: SummaryTableProps) => {
  return (
    <table className={classNames(styles["base"], className)}>
      <thead>
        {header}
        <tr>
          {columns
            ?.filter((column) => column.name)
            ?.map((column) => (
              <th key={column.id} colSpan={column.colSpan} className={column.className}>
                {column.name}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data?.map?.((row, index) => {
          return (
            <tr key={row.id}>
              {columns?.map?.((column) => {
                return (
                  <td key={column.id}>
                    {column?.cell?.(
                      get(row, column?.accessor),
                      row,
                      index 
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      {columns?.some((col) => !!col.footer) && (
        <tfoot>
          <tr>
            {columns?.map?.((column) => {
              return <td key={column.id}>{column?.footer?.(data)}</td>;
            })}
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default SummaryTable;
