import { FormQuerySelector } from "@vesatogo/grass-core"
import { FormCard } from "~/components/FormCard"
import UserDetailsCard from "~/components/UserDetailsCard"
import { Permission } from "~/constants/Permissions"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { useAuthenticatedUserQuery, useUsersQuery } from "~/generated/graphql"

type WhoIsTradingProps = {
  showBuyer?: boolean
  inputProps?: any
  form?: any
  isDisabled?: boolean
  setPackingOpen?: () => void
  disableBuyer?: boolean
}

function getOptionLabel({ is_verified, name }) {
  return `${name} | ${
    is_verified === false
      ? "Rejected"
      : is_verified === true
      ? "Verified"
      : "Pending Verification"
  }`
}
export const WhoIsTrading = ({
  form,
  inputProps,
  showBuyer = true,
  isDisabled,
  setPackingOpen,
  disableBuyer,
}: WhoIsTradingProps) => {

  const [{ data }] = useAuthenticatedUserQuery()
  const me = data?.me

  
  const buyerGroups = ["Trader"]
  if (me?.permissions?.includes(Permission.SelectFarmerInBuyer)) {
    buyerGroups.push("Farmer")
  }

  return (
    <FormCard title="Who is trading" action={setPackingOpen ? <button onClick={setPackingOpen} className="text-blue-500 underline">Packing Material</button> : null}>
      <div className="flex gap-4">
        <div className={"w-1/2"}>
          <FormQuerySelector
            required
            isDisabled={isDisabled}
            label="Farmer"
            className={"mb-2"}
            serverSideQuery
            dataHook={useUsersQuery}
            variables={{ groups: ["Farmer", "Trader"] }}
            getOptionLabel={getOptionLabel}
            {...inputProps("seller", {
              group: SharedEntityGroup.KiteUser,
            })}
          />
          <UserDetailsCard user={form.values.seller} />
        </div>
        {showBuyer && (
          <div className={"w-1/2"}>
            <FormQuerySelector
              required={true}
              isDisabled={disableBuyer ?? isDisabled}
              label="Trader"
              className={"mb-2"}
              serverSideQuery
              dataHook={useUsersQuery}
              variables={{ groups: buyerGroups }}
              getOptionLabel={getOptionLabel}
              {...inputProps("buyer", {
                group: SharedEntityGroup.KiteUser,
              })}
            />

            <UserDetailsCard user={form.values.buyer} />
          </div>
        )}
      </div>
    </FormCard>
  )
}
