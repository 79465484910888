import { Tag, InputGroup, Selector } from "@vesatogo/grass-core"
import { EXPENSE_KINDS } from "../constants/static.items"
import { useExpenseParametersQuery } from "../generated/graphql"
import { QuerySelector } from "./QuerySelector"

export function ExpensesTable({
  expenses,
  handleProps,
  isDisabled,
}: {
  expenses: any
  handleProps: any
  isDisabled?: boolean
}) {
  return (
    <table className="w-full expenses-table flex flex-col">
      <thead className="bg-green-100 text-sm rounded overflow-hidden">
        <tr className="flex">
          <td className="p-1 flex-[3]">Expense Type</td>
          <td className="p-1 flex-1">Farmer Charge</td>
          <td className="p-1 flex-1">Trader Charge</td>
        </tr>
      </thead>
      <tbody>
        {expenses?.map((expense, idx) => {
          const rightElement = expense?.kind?.short && (
            <Tag variant="minimal" className="mr-2">
              {expense?.kind?.short}
            </Tag>
          )
          return (
            <tr key={idx} className="flex items-center">
              <td className="flex gap-3 flex-[3]">
                <QuerySelector
                  className="w-full"
                  isOptionDisabled={(opt: any) =>
                    expenses?.find(
                      exp =>
                        Number(exp.expense_parameter?.id) === Number(opt.id)
                    )
                  }
                  isDisabled={isDisabled}
                  dataHook={useExpenseParametersQuery}
                  {...handleProps(`${idx}.expense_parameter`)}
                />
                <Selector
                  className="w-full"
                  options={EXPENSE_KINDS}
                  isDisabled={isDisabled}
                  {...handleProps(`${idx}.kind`)}
                />
              </td>
              <td className="flex-1">
                <InputGroup
                  inputProps={{
                    ...handleProps(`${idx}.seller.value`),
                    type: "number",
                    className: "text-right",
                  }}
                  rightElement={rightElement}
                  className="text-right"
                  disabled={isDisabled}
                />
              </td>
              <td className="flex-1">
                <InputGroup
                  inputProps={{
                    ...handleProps(`${idx}.buyer.value`),
                    type: "number",
                    className: "text-right",
                  }}
                  rightElement={rightElement}
                  disabled={isDisabled}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
