export const EXPENSE_FRAG = /* GraphQL */ `
  fragment ExpenseFrag on expense {
    id
    paid_by
    expense_parameter {
      id
      codename
      name
    }
    kind
    value
    amount
  }
`

export const DELETE_EXPENSE_ITEM = /* GraphQL */ `
  mutation DeleteExpenseItem($ids: [bigint!]) {
    delete_expense(where: { id: { _in: $ids } }) {
      returning {
        id
        trade {
          id
          expenses {
            ...ExpenseFrag
          }
        }
      }
    }
  }
  ${EXPENSE_FRAG}
`
