import { DownloadSimple, Trash } from "@phosphor-icons/react"
import { Carousel } from "@vesatogo/grass-carousel"
import { Image, Modal, NoDataFound, Spinner } from "@vesatogo/grass-core"
import { getExtension } from "@vesatogo/utils"
import { saveAs } from "file-saver"
import { useState } from "react"
import toast from "react-hot-toast"
import FileUploadButton from "~/components/FIleUploadButton"

type SendConsentType = {
  isOpen: boolean
  onClose: () => void
  form?: any
  disabled?: boolean
  addAttachment?: (attachment: any) => void
  attachments: any[]
  previewOnly?: boolean
}

const AttachmentModal = ({
  isOpen,
  onClose,
  form,
  disabled,
  addAttachment,
  attachments,
  previewOnly = false,
}: SendConsentType) => {
  const [loading, setLoading] = useState(false)

  const imageSlides = attachments.map((attachment, index) => {
    return {
      slide: (
        <div
          key={index}
          className="relative w-96 h-80 mx-auto rounded border p-2"
        >
          <Image
            src={attachment.media.url}
            alt={attachment.media.url}
            className="w-full h-full object-cover rounded"
          />
          {!attachment.id ? (
            <button
              onClick={() => {
                const updatedAttachments = attachments.filter(
                  (item, i) => i !== index
                )
                form.setFieldValue("attachments", updatedAttachments)
                addAttachment?.(updatedAttachments)
              }}
              className="absolute top-2 right-2 bg-white text-red p-1 m-2 rounded"
            >
              <Trash size={20} />
            </button>
          ) : (
            <div className="absolute top-2 right-2 bg-white text-green p-1 m-2 rounded cursor-pointer">
              <DownloadSimple
                size={20}
                onClick={() => {
                  saveAs(
                    attachment.media.url,
                    `${Date.now()}.${getExtension(attachment.media.url)}`
                  )
                }}
              />
            </div>
          )}
        </div>
      ),
    }
  })

  const handleSuccess = message => {
    const media = message.map(item => ({
      media: item.data.media,
    }))
    const updatedAttachments = [...attachments, ...media]
    form.setFieldValue("attachments", updatedAttachments)
    addAttachment?.(updatedAttachments)
    onClose()
  }

  return (
    <>
      <Modal
        bodyClassName="w-1/2"
        title="Attachment"
        isOpen={isOpen}
        onClose={onClose}
      >
        {attachments.length === 0 ? (
          <NoDataFound
            title={"You haven’t added an Attachment"}
            children={
              loading ? (
                <Spinner />
              ) : (
                !previewOnly && (
                  <FileUploadButton
                    multiple
                    disabled={disabled}
                    method="put"
                    appName="setutrade"
                    environment={import.meta.env.VITE_APP_ENV}
                    endpoint={`https://${
                      import.meta.env.VITE_REST_URL
                    }/rest/trade/uploader/`}
                    media={attachments}
                    onSuccess={handleSuccess}
                    onError={message => {
                      toast.error(message)
                    }}
                  />
                )
              )
            }
          />
        ) : (
          <div>
            <div className="flex justify-end">
              {loading ? (
                <Spinner />
              ) : (
                !previewOnly && (
                  <FileUploadButton
                    multiple
                    disabled={disabled}
                    method="put"
                    appName="setutrade"
                    environment={import.meta.env.VITE_APP_ENV}
                    endpoint={`https://${
                      import.meta.env.VITE_REST_URL
                    }/rest/trade/uploader/`}
                    media={attachments}
                    onSuccess={handleSuccess}
                    onError={message => {
                      toast.error(message)
                    }}
                  />
                )
              )}
            </div>
            <Carousel
              className="mt-5"
              showArrows={true}
              transition={false}
              showDots={false}
              slides={imageSlides}
              options={{
                speed: 4,
                loop: true,
              }}
            />
          </div>
        )}
      </Modal>
    </>
  )
}

export default AttachmentModal
