import { useTranslation } from "react-i18next"
import { Avatar, Card, Group, Image, Text } from "@vesatogo/grass-core"
import { PavtiTradeDetailQuery } from "~/generated/graphql"
import { DetailCard } from "~/pages/pavti/components"
import phoneIcon from "~/pages/pavti/assets/phone.svg"
export function Facilitator({
  data,
}: {
  data: PavtiTradeDetailQuery["trade_by_pk"]
}) {
  const { t } = useTranslation()
  return (
    <Card className="overflow-hidden !rounded-lg !rounded-t-none my-4">
      <DetailCard
        header={
          data?.facilitator?.name ? (
            <Text className="text-xl font-medium !text-gray-800">
              {data?.facilitator?.name}
            </Text>
          ) : null
        }
      >
        <Group className="flex items-center p-2">
          <Avatar size="lg" />
          <Group className="self-start ml-2">
            <Text size="sm" className="font-medium !text-gray-800">
              {data?.created_by?.[0]?.full_name}
            </Text>
            <Text size="sm">{t("facilitator.title")}</Text>
          </Group>
          <a
            href={`tel:${data?.created_by?.[0]?.number}`}
            className="p-2 shadow-sm shadow-gray-300 rounded-full ml-auto"
          >
            <Image src={phoneIcon as unknown as string} />
          </a>
        </Group>
      </DetailCard>
    </Card>
  )
}
