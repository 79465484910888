import { useEffect, useState } from "react"
import { ICountdownProps } from "~/pages/pavti/lib/types"
import { timeFormat } from "~/pages/pavti/lib/utils"

export function Countdown({
  text,
  start,
  warning,
  setWarning,
  handleEnd,
}: ICountdownProps) {
  const [countDown, setCountdown] = useState(start)
  const time = timeFormat(countDown)

  useEffect(() => {
    let timer: number = 0
    if (setWarning && warning && countDown <= warning) setWarning()
    if (countDown > 0) {
      timer = window.setInterval(() => setCountdown(prev => prev - 1), 1000)
    } else handleEnd(true)
    return () => clearInterval(timer)
  })

  return (
    <span>
      <span>{text}</span>
      {time.hours ? `${time.hours} h ` : ""}
      {time.minutes ? `${time.minutes} min ` : ""}
      {time.seconds ? `${time.seconds} sec` : ""}
    </span>
  )
}
