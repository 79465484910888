import { FormSelector } from "@vesatogo/grass-core"
import { useEffect } from "react"
import { create } from "zustand"

export type PayableSelectorType = {
  selected: any
  setSelected: (value: any) => void
}

export const usePayableSelector = create<PayableSelectorType>((set, get) => ({
  selected: undefined,
  setSelected(value) {
    set({ selected: value })
  },
}))

const selector = [
  { id: "trade", name: "Trade" },
  { id: "receipt", name: "Receipt" },
]

export const PayableSelector = () => {
  const { selected, setSelected } = usePayableSelector()
  useEffect(() => {
    if (selected === undefined) {
      setSelected(selector?.[0]?.id)
    }
  }, [])
  return (
    <FormSelector
      options={selector}
      isClearable={false}
      value={
        selector.find(sel => sel.id === selected) || (selector?.[0] as any)
      }
      onChange={e => {
        setSelected(e?.id)
      }}
    />
  )
}
