import {
  RadioGroup,
  Checkbox,
  Note,
  FormGroup,
  Selector,
} from "@vesatogo/grass-core"
import { useParams, useSearchParams } from "react-router-dom"
import { FormCard } from "~/components/FormCard"
import { TradeKinds } from "~/constants/Kinds"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { TRADE_KINDS } from "~/constants/static.items"

type HowIsItTradedProps = {
  form: any
  inputProps: any
  isDisabled: boolean
  showBookingKinds?: boolean
  showInwardKinds?: boolean
  disableBookingPatti?: boolean
}
const HowIsItTraded = ({
  form,
  inputProps,
  isDisabled,
  showBookingKinds = false,
  showInwardKinds = false,
  disableBookingPatti = false,
}: HowIsItTradedProps) => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  return (
    <FormCard title="How is it traded">
      <div className="flex gap-4">
        <div className={"w-[120%]"}>
          <RadioGroup
            className={"mb-3"}
            label="Type"
            required
            {...inputProps("kind")}
          >
            {TRADE_KINDS.filter(kind => {
              if (showBookingKinds && showInwardKinds) return true
              if (showBookingKinds) return kind.id !== "inward"
              if (showInwardKinds) return kind.id !== "booking"
              return kind.id !== "booking" && kind.id !== "inward"
            }).map((kind, i) => (
              <Checkbox
                key={i}
                type={"radio"}
                value={kind.id}
                label={kind.name}
                disabled={
                  id !== "new" ? !searchParams.get('newfpr') : false ||
                  (kind.id === TradeKinds.Booking && disableBookingPatti)
                }
              />
            ))}
          </RadioGroup>
          {form.values.kind === TradeKinds.HishobPatti ? (
            <Note className="text-sm">
              No information can be changed later. The invoice will be created
              directly now.
            </Note>
          ) : (
            <Note intent="warning" className="text-yellow-600 text-sm">
              This is temporary and is subject to change later.
            </Note>
          )}
        </div>
        <RadioGroup
          className={"w-full"}
          label="Mode"
          required
          {...inputProps("mode")}
        >
          <Checkbox
            type={"radio"}
            value="direct"
            label="Direct"
            disabled={isDisabled}
          />
          <Checkbox
            type={"radio"}
            value="facilitator"
            label="Via Facilitator"
            disabled={isDisabled || !form.values.seller?.companies?.length}
          />
        </RadioGroup>
        {form.values.mode === "facilitator" ? (
          <FormGroup label="Facilitator Company" className={"w-full"}>
            <Selector
              options={form.values.seller?.companies?.map(c => c?.company)}
              {...inputProps("facilitator", {
                group: SharedEntityGroup.KiteCompany,
              })}
              isDisabled={isDisabled}
            />
          </FormGroup>
        ) : (
          <div className="w-full" />
        )}
      </div>
    </FormCard>
  )
}

export default HowIsItTraded
