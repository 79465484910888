import {
  Card,
  Checkbox,
  ClassName,
  Divider,
  FormInput,
  FormQuerySelector,
  FormSelector,
  RadioGroup,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { FileUpload } from "@vesatogo/grass-dropzone"
import classNames from "classnames"
import { OperationContext } from "urql"
import { useAllPaymentMethodsQuery } from "~/generated/graphql"

export type TransactionProps = {
  getFileUploadProps?: any
  getInputProps?: any
  accessPath?: any
  application?: any
  refetch?: (opts?: Partial<OperationContext>) => void
  maxFiles?: number
  setFieldValue?: any
  children?: React.ReactNode
  uploaderClassName?: ClassName
  deletePermission?: string
  hideBank?: boolean
  bankSelectorOptions?: any[]
}

export const TransactionDetails: React.FC<TransactionProps> = ({
  getFileUploadProps,
  getInputProps,
  application,
  refetch,
  maxFiles = 1,
  setFieldValue,
  children,
  uploaderClassName,
  deletePermission,
  hideBank = false,
  bankSelectorOptions = [{ id: 1, name: "Please enter bank name" }],
}) => {
  const isUPI = application?.transaction?.payment_method?.name === "UPI"
  const type = isUPI ? "UPI ID" : "Account Number"
  return (
    <>
      <section className="p-4">
        <div className="grid grid-cols-4 gap-4">
          <div className={classNames("row-span-4", uploaderClassName)}>
            <FileUpload
              {...getFileUploadProps(`attachments`, maxFiles)}
              endpoint={`https://${
                import.meta.env.VITE_REST_URL
              }/rest/trade/uploader/`}
              appName="setutrade"
              onSuccess={(file: any) => {
                setFieldValue("attachments", [
                  ...application?.attachments,
                  file[0]?.data?.media,
                ])
              }}
            />
          </div>
          {children}
          <RadioGroup
            label="Payment Type"
            required
            // disabled={remainingAmount === 0}
            {...getInputProps("payment_type")}
          >
            <Checkbox type="radio" value={"full"}>
              Full Payment
            </Checkbox>
            <Checkbox type="radio" value={"partial"}>
              Partial Payment
            </Checkbox>
          </RadioGroup>
          <FormInput
            {...getInputProps("amount")}
            label="Amount"
            disabled={application?.payment_type === "full"}
            type="number"
            required
          ></FormInput>
          <FormQuerySelector
            label="Payment Mode"
            dataHook={useAllPaymentMethodsQuery}
            required
            {...getInputProps(`mode`)}
          />
          {!isUPI && !hideBank && (
            <>
              <FormSelector
                label="Bank Name"
                {...getInputProps(`bank_name`)}
                getOptionLabel={op => op?.bank_name}
                options={bankSelectorOptions}
              />
            </>
          )}
          <FormInput
            required
            label="Transaction Id"
            {...getInputProps(`transaction_id`)}
          />
          <FormDateInput
            // required
            label="Transaction Date"
            {...getInputProps(`transaction_date`)}
          />
          {application?.bank_name?.id && (
            <Card className="!rounded-none p-2 bg-gray-100 text-gray-800 col-span-3">
              <div className="grid grid-cols-3 gap-2">
                <div className="flex justify-between">
                  <div>
                    <div className="text-sm text-gray-700">
                      Account Holder Name
                    </div>
                    <div className="font-600 text-gray-800">
                      {application?.bank_name?.account_holder_name}
                    </div>
                  </div>
                  <Divider></Divider>
                </div>

                <div className="flex justify-between">
                  <div>
                    <div className="text-sm text-gray-700">Account Number</div>
                    <div className="font-600 text-gray-800">
                      {application?.bank_name?.account_number}
                    </div>
                  </div>
                  <Divider></Divider>
                </div>
                <div>
                  <div className="text-sm text-gray-700">IFSC Code</div>
                  <div className="font-600 text-gray-800">
                    {application?.bank_name?.ifsc_code}
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>
      </section>
    </>
  )
}
