import {
  DotsThreeVertical,
  MagnifyingGlass,
  Plus,
  User,
} from "@phosphor-icons/react"
import {
  Avatar,
  Button,
  CodenameTag,
  InputGroup,
  Menu,
  MenuItem,
  OverflowMenu,
  Popover,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { siteConfig } from "~/constants/config"
import { Order_By, usePackingMaterialsQuery } from "~/generated/graphql"
import PackingMaterialFilter from "~/components/PackingMaterial/PackingMaterialFilter"
import NewMaterialModal from "~/components/PackingMaterial/PackingModal/NewMaterialModal"
import ViewAddSapModal, {
  ModalType,
} from "~/components/PackingMaterial/PackingModal/ViewAddSapModal"

export type FilterType = {
  material?: any
  payment_status?: any
}
const PackingMaterial = () => {
  const [params, setParams] = useSearchParams()
  const [view, setView] = useState(false)
  const [viewMaterial, setViewMaterial] = useState(false)
  const [modalType, setModalType] = useState<ModalType>(ModalType.VIEW_SAP)

  const search = params.get("search")
  const page = Number(params.get("page")) || 1
  const [filter, setFilter] = useState<FilterType>({})

  const [{ data, fetching }, refetch] = usePackingMaterialsQuery({
    variables: {
      where: {
        ...(search && {
          transaction_number: { _ilike: `%${search}%` },
        }),
        packaging_order_items: {
          packaging_id: {
            _eq: filter.material?.id,
          },
        },
        payment_status: { _eq: filter.payment_status?.id },
      },
      limit: siteConfig.pageSize,
      offset: (page - 1) * siteConfig.pageSize,
      order_by: { created_at: Order_By.Desc },
    },
  })

  const totalOrder = data?.packaging_order_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    {
      Header: <div className="ml-2">Date</div>,
      accessor: "date",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <Cell
            titleClassName="ml-2"
            title={dayjs(value).format("D MMM' YY")}
          />
        )
      },
    },
    {
      Header: "Farmer Name",
      accessor: "user.user[0]",
      Cell({ value, row: { original } }) {
        const [params, setParams] = useSearchParams()
        if (!value) return "--"

        return (
          <Cell
            title={
              <button
                onClick={() => {
                  params.set("farmerId", original?.id)
                  setParams(params)
                  setViewMaterial(true)
                }}
              >
                {value.name}
              </button>
            }
            subtitle={
              <CodenameTag
                leftIcon={<User weight="fill" />}
                referenceId={value.external_reference_id2}
              />
            }
          />
        )
      },
    },
    {
      Header: "Plot",
      accessor: "plot_crop.plot_crop[0]",
      Cell({ value }) {
        return (
          <Cell
            title={value?.name}
            subtitle={
              <CodenameTag referenceId={value?.external_reference_id} />
            }
          />
        )
      },
    },

    {
      Header: "Material",
      accessor: "packaging_order_items",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <div>
            <OverflowMenu
              items={value.map(item => ({
                name: item?.packaging?.name,
                value: item?.packaging?.id,
              }))}
            />
          </div>
        )
      },
    },
    {
      Header: "Total Amount",
      accessor: "total_amount",
      Cell({ value }) {
        return <Cell title={inr(value)} />
      },
    },
    {
      Header: "Payment Status",
      accessor: "payment_status",
      Cell({
        value,
        row: {
          original: { total_amount_pending },
        },
      }) {
        if (!value) return "--"
        const content =
          value === "paid" ? (
            <span className="text-green-500 font-600">● Paid</span>
          ) : value === "partial" ? (
            <span className="text-red-500 font-600">● Partial</span>
          ) : (
            <span className="text-blue-500 font-600">● Pending</span>
          )
        return (
          <Cell
            title={content}
            subtitleClassName="ml-2"
            subtitle={value === "partial" ? inr(total_amount_pending) : null}
          />
        )
      },
    },

    {
      Header: "Sap Status",
      accessor: "transaction_number",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell title={value} />
      },
    },
    {
      Header: "Created By",
      accessor: "created_by[0]",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.full_name}
              src={value?.photo?.url}
            />
          </Tooltip>
        )
      },
    },

    {
      Header: "",
      accessor: "id",
      Cell({
        value,
        row: {
          original: { transaction_number },
        },
      }) {
        const [params, setParams] = useSearchParams()

        return (
          <Popover
            minimal
            arrow={false}
            className="p-0"
            trigger="mouseenter"
            interactive
            animation="fade"
            theme="light-border"
            placement="bottom"
            content={
              <Menu className="max-h-72">
                {transaction_number && (
                  <MenuItem
                    onClick={() => {
                      params.set("id", value)
                      setParams(params)
                      setModalType(ModalType.VIEW_SAP)
                      setView(true)
                    }}
                  >
                    View SAP Invoice
                  </MenuItem>
                )}
                {!transaction_number && (
                  <MenuItem
                    onClick={() => {
                      params.set("id", value)
                      setParams(params)
                      setModalType(ModalType.ADD_SAP)
                      setView(true)
                    }}
                  >
                    Attach Sap Invoice
                  </MenuItem>
                )}
              </Menu>
            }
          >
            <button className="hover:bg-gray-300 rounded">
              <DotsThreeVertical size={20} />
            </button>
          </Popover>
        )
      },
    },
  ]

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <div></div>
        <div className="w-[500px] mx-auto flex items-center gap-3">
          <InputGroup
            className={"w-full "}
            inputProps={{
              value: search || "",
              placeholder: "Search by SAP Transaction Number",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
          <PackingMaterialFilter filter={filter} setFilter={setFilter} />
        </div>
        <div className="flex gap-3">
          <Button onClick={() => setViewMaterial(true)} leftIcon={<Plus />}>
            Material
          </Button>
        </div>
      </header>
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        paginationProps={{
          total: Math.ceil(totalOrder / siteConfig.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={data?.packaging_order || []}
        meta={<>{} </>}
      />
      <NewMaterialModal
        open={viewMaterial}
        handleClose={() => {
          params.delete("farmerId")
          setParams(params)
          setViewMaterial(false)
          setTimeout(() => {
            refetch({ requestPolicy: "network-only" })
          }, 1500)
        }}
      />
      <ViewAddSapModal
        open={view}
        type={modalType}
        refetchData={() => refetch({ requestPolicy: "network-only" })}
        handleClose={() => {
          params.delete("id")
          setParams(params)
          setView(false)
        }}
      />
    </>
  )
}

export default PackingMaterial
