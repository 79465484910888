import { Card, Group, Image, Text } from "@vesatogo/grass-core"
import { PavtiNoteProps } from "~/pages/pavti/lib/types"

export function PavtiNote({ text, icon }: PavtiNoteProps) {
  return (
    <Card className="py-2 px-4 mb-4 border-l-4 border-gray-800 !rounded">
      <Group className="flex items-center gap-2">
        {icon ? typeof icon === "string" ? <Image src={icon} /> : icon : null}
        <Text className="text-sm font-medium !text-gray-800">{text}</Text>
      </Group>
    </Card>
  )
}
