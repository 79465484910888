enum Codenames {
  KiteDefaultCurrency = "ff8ef60d-7a8c-4552-8c00-630468b6f058",
  GenerateInward = "Generate Inward",
  GenerateSales = "Generate Sale",
  PercentageUnit = "6f17f4c8-bde4-40e3-97c1-75c507564429",
  EagleTestRequest = "a23c8ca4-d161-4791-a549-ba774766ad49",
  PackagingExpense = "52fab724-1f7d-42cc-a66a-b48478d477b0",
}

export default Codenames
