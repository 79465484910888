import { Checkbox, CodenameTag } from "@vesatogo/grass-core"
import { inr, percent } from "@vesatogo/utils"
import classNames from "classnames"
import { CaretDown } from "@phosphor-icons/react"
import { Base_User, Setu_Trade_Shared_Entity, Trade } from "~/generated/graphql"

export type TraderSummaryProps = {
  isExpanded?: boolean
  onExpandToggle?: () => void
  isSelected?: boolean
  onSelect?: () => void
  trader?: Partial<Base_User>
  trades?: Partial<Trade>[]
  rate_unit?: Partial<Setu_Trade_Shared_Entity>
  quantity_unit?: Partial<Setu_Trade_Shared_Entity>
  standard_deduction?: any
  expense_amount?: any
  total_quantity?: any
  total_amount?: any
}
const TraderSummary = ({
  isExpanded,
  isSelected,
  onSelect,
  onExpandToggle,
  trader: user,
  trades,
  rate_unit,
  quantity_unit,
  standard_deduction,
  expense_amount,
  total_quantity,
  total_amount,
}: TraderSummaryProps) => {
  return (
    <div className="border rounded-lg mb-3 last-of-type:mb-0">
      <div
        className={classNames(
          "bg-green-100 p-3 flex justify-between items-center",
          isExpanded ? "rounded-t-lg" : "rounded-lg"
        )}
      >
        <div>
          <div>
            {onSelect ? (
              <Checkbox checked={isSelected} onClick={onSelect} />
            ) : null}
            <p
              className={classNames(
                "flex items-center mb-[2px]",
                onSelect && "ml-7"
              )}
            >
              <span>
                {user?.full_name || "na"}{" "}
                <span className="text-gray-500">(trader name)</span>
              </span>
            </p>
          </div>
          <CodenameTag referenceId={user?.external_reference_id as string} />
        </div>
        {onExpandToggle ? (
          <CaretDown
            style={{
              rotate: isExpanded ? "0deg" : "180deg",
            }}
            onClick={onExpandToggle}
            className="cursor-pointer hover:opacity-70"
            size={24}
          />
        ) : null}
      </div>
      {(isExpanded || !onExpandToggle) && (
        <div className="p-3">
          <table className="w-full border rounded-md">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-[10px] py-[7px]">Farmer</th>
                <th className="px-[10px] py-[7px]">Grade</th>
                <th className="px-[10px] py-[7px]">Rate</th>
                <th className="px-[10px] py-[7px]">Quantity</th>
                <th className="px-[10px] py-[7px]">Amount</th>
              </tr>
            </thead>
            <tbody>
              {trades?.map(trade => {
                const farmer = trade?.seller?.user?.[0]

                return (
                  <>
                    {trade?.trade_items?.map(item => {
                      return (
                        <tr key={item.id}>
                          <td className="px-[10px] py-[7px]">
                            <p>{farmer?.full_name || "na"} </p>
                            <CodenameTag
                              referenceId={
                                farmer?.external_reference_id || "na"
                              }
                            />
                          </td>
                          <td className="px-[10px] py-[7px]">
                            {item.variety_grade?.name}
                          </td>
                          <td className="px-[10px] py-[7px]">
                            {`${inr(item.rate)} / ${rate_unit?.name || "na"}`}
                          </td>
                          <td className="px-[10px] py-[7px]">
                            {item.quantity} {quantity_unit?.name || "na"}{" "}
                          </td>
                          <td className="px-[10px] py-[7px]">
                            {inr(item.amount)}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )
              })}
              <tr>
                <td className="px-[10px] py-[7px] text-red-500">
                  Average Standard deduction
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td className="px-[10px] py-[7px] text-red-500">
                  {percent(standard_deduction)}
                </td>
              </tr>
              <tr>
                <td className="px-[10px] py-[7px] text-red-500">Expense</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="px-[10px] py-[7px] text-red-500">
                  {inr(expense_amount)}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="px-[10px] py-[7px] font-600 text-gray-700">
                  Total (i.e Material price - Std. deduction)
                </td>
                <td></td>
                <td></td>
                <td className="px-[10px] py-[7px] font-600 text-gray-700">
                  {total_quantity?.toLocaleString("en-IN")}{" "}
                  {quantity_unit?.name}
                </td>
                <td className="px-[10px] py-[7px] font-600 text-gray-700">
                  {inr(total_amount)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  )
}

export default TraderSummary
