import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormGroup,
  Input,
  RadioGroup,
  Selector,
} from "@vesatogo/grass-core";
import { FileUpload } from "@vesatogo/grass-dropzone";
import { get } from "lodash-es";
import toast from "react-hot-toast";

type PaymentOptionProps = {
  form: any;
  totalAmount: number;
};

const PaymentOption: React.FC<PaymentOptionProps> = ({ form, totalAmount }) => {
  const [selectedOption, setSelectedOption] = useState<string>("pending");

  const handleChange = (value: string) => {
    form.setFieldValue("payment_status", value);
    setSelectedOption(value);
  };

  return (
    <div className="mt-4">
      <RadioGroup
        className="w-full flex p-2 space-y-2"
        label="Payment Option"
        required
        value={selectedOption}
        onChange={handleChange}
      >
        <Checkbox type="radio" value="paid" label="Paid" />
        <Checkbox type="radio" value="partial" label="Partial" />
        <Checkbox type="radio" value="pending" label="Pending" />
      </RadioGroup>

      <div className="mt-4">
        {selectedOption === "paid" && (
          <PaidPaymentForm form={form} totalAmount={totalAmount} type={"full"} />
        )}
        {selectedOption === "partial" && (
          <PaidPaymentForm
            form={form}
            totalAmount={totalAmount}
            type={"partial"}
          />
        )}
        {selectedOption === "pending" && null}
      </div>
    </div>
  );
};

type PaidPaymentFormProps = {
  type: "full" | "partial";
  form: any;
  totalAmount: number;
};

const PaidPaymentForm: React.FC<PaidPaymentFormProps> = ({
  type,
  form,
  totalAmount,
}) => {
  useEffect(() => {
    form.setFieldValue("payment.amount", totalAmount);
  }, [totalAmount]);

  const [media, setMedia] = useState<any[]>([]);
  const [ifscDetails, setIfscDetails] = useState<any>({});

  const handleIfscChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const ifscCode = e.target.value;
    form.setFieldValue("payment.ifsc_code", ifscCode);

    if (ifscCode.length === 11) {
      try {
        const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
        if (response.ok) {
          const data = await response.json();
          setIfscDetails(data);
          form.setFieldValue("payment.bank_name", data.BANK);
          form.setFieldValue("payment.branch_name", data.BRANCH);
        } else {
          toast.error("Invalid IFSC Code");
        }
      } catch (error) {
        toast.error("Failed to fetch IFSC details");
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-6 items-start">
      <div className="pr-1">
        <FileUpload
          onError={(error) => toast.error(error)}
          uploadCardClassName="h-32 w-32 border border-gray-300 rounded-lg"
          onSuccess={(file: any) => {
            setMedia([file[0]?.data?.media]);
            form.setFieldValue("payment.photo_id", file[0]?.data?.media?.id);
          }}
          endpoint={`https://${
            import.meta.env.VITE_REST_URL
          }/rest/trade/uploader/`}
          appName="setutrade"
          onChange={() => console.log("change")}
          showPreviewForDocument
          onDelete={() => {
            setMedia([]);
            form.setFieldValue("payment.photo_id", null);
          }}
          maxFiles={1}
          media={media}
        />
      </div>
      <div className="space-y-3 w-full md:w-auto">
        <div className="flex gap-2 w-full">
          <FormGroup required label="Amount" className="w-full">
            <Input
              type="number"
              value={get(form.values, "payment.amount", "")}
              disabled={type === "full"}
              max={get(form.values, "total_amount")}
              min={0}
              onChange={(e: any) => {
                form.setFieldValue("payment.amount", e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup required label="Payment Mode" className="w-full">
            <Selector
              options={[
                { id: "Cash", name: "Cash" },
                { id: "Upi", name: "UPI" },
                { id: "Cheque", name: "Cheque" },
                { id: "NEFT", name: "NEFT" },
                { id: "RTGS", name: "RTGS" },
              ]}
              value={form.values.payment.mode}
              onChange={(value) => {
                form.setFieldValue("payment.mode", value);
              }}
            />
          </FormGroup>
          <FormGroup required label="Transaction ID" className="w-full">
            <Input
              onChange={(e) => {
                form.setFieldValue("payment.transaction_id", e.target.value);
              }}
            />
          </FormGroup>
        </div>
        <div className="flex gap-2 w-full">
          <FormGroup label="Account Number" className="w-full">
            <Input
              onChange={(e) => {
                form.setFieldValue("payment.account_number", e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup label="Re-verify Account Number" className="w-full">
            <Input
              onChange={(e) => {
                form.setFieldValue("payment.re_account_number", e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup label="Account Holder Name" className="w-full">
            <Input
              onChange={(e) => {
                form.setFieldValue("payment.acount_holder", e.target.value);
              }}
            />
          </FormGroup>
        </div>
        <div className="flex gap-2 w-full">
          <FormGroup label="IFSC Code" className="w-full">
            <Input
              onChange={handleIfscChange}
            />
          </FormGroup>
          <FormGroup label="Bank Name" className="w-full">
            <Input
              value={ifscDetails.BANK || ""}
              readOnly
            />
          </FormGroup>
          <FormGroup label="Branch Name" className="w-full">
            <Input
              value={ifscDetails.BRANCH || ""}
              readOnly
            />
          </FormGroup>
        </div>
        <div className="w-1/3 pr-1">
          <FormGroup label="Fund Transfer Date">
            <Input
              type="date"
              onChange={(e) => {
                form.setFieldValue("payment.date", e.target.value);
              }}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default PaymentOption;
