import { Funnel } from "@phosphor-icons/react"
import {
  Button,
  FilterDrawer,
  FormGroup,
  QuerySelector,
  Selector,
} from "@vesatogo/grass-core"
import { Dispatch, SetStateAction, useState } from "react"
import {
  usePackingMaterialListQuery,
} from "~/generated/graphql"
import { FilterType } from "../../pages/packing-material"

type PackingMaterialFilterProps = {
  filter: FilterType
  setFilter: Dispatch<SetStateAction<FilterType>>
}
const PackingMaterialFilter = ({
  filter,
  setFilter,
}: PackingMaterialFilterProps) => {
  const [isOpen, setOpen] = useState(false)

  function inputProps(path: string) {
    return {
      value: filter[path],
      onChange: (e: any) => {
        setFilter(prev => ({
          ...prev,
          [path]: e?.target ? e.target.value : e,
        }))
      },
    }
  }

  function getAppliedFilters(prefix: string, filter: any) {
    const totalApplied = Object.keys(filter)?.filter?.(
      key => key.startsWith(prefix) && filter[key]
    )?.length
    return totalApplied ? `${totalApplied} Applied` : null
  }
  return (
    <>
      <Button
        onClick={() => {
          setOpen(prev => !prev)
        }}
        variant="outline"
        className="items-center"
      >
        <Funnel />
      </Button>
      <FilterDrawer
        tabs={[
          {
            id: 1,
            title: "Material",
            subtitle: getAppliedFilters("sb", filter),
            content: (
              <>
                <FormGroup label="Material" className={"mb-2"}>
                  <QuerySelector
                    serverSideQuery
                    dataHook={usePackingMaterialListQuery}
                    {...inputProps("material")}
                  />
                </FormGroup>
              </>
            ),
          },
          {
            id: 2,
            title: "Status",
            subtitle: getAppliedFilters("com", filter),
            content: (
              <div className="flex flex-col gap-3">
                {" "}
                <FormGroup label="Payment Status" className={"w-full"}>
                  <Selector
                    options={[
                      { id: "paid", name: "Paid" },
                      { id: "pending", name: "Pending" },
                      { id: "partial", name: "Partial" },
                    ]}
                    {...inputProps("payment_status")}
                  />
                </FormGroup>{" "}
              </div>
            ),
          },
        ]}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onApply={() => {
          setOpen(false)
        }}
        onClearAll={() => {
          setFilter({})
          setOpen(false)
        }}
      />
    </>
  )
}

export default PackingMaterialFilter
