import { CodenameTag, Modal, ModalProps } from "@vesatogo/grass-core"
import { inr, percent } from "@vesatogo/utils"
import PavtiNote from "~/components/PavtiNote"
import SummaryTable from "~/components/SummaryTable"
import { TRADE_KINDS } from "~/constants/static.items"
import { ReceiptState } from "~/pages/d/trade-requests/receipts/types"

type ReceiptSummaryProps = ModalProps & {
  receipt?: ReceiptState
  isDisabled?: boolean
  isLoading?: boolean
}
const CreateReceiptSummary = ({
  isOpen,
  onClose,
  receipt,
  isLoading,
  isDisabled,
  ...props
}: ReceiptSummaryProps) => {
  return (
    <Modal
      bodyClassName="w-[50%]"
      contentClassName="!bg-gray-100"
      title={TRADE_KINDS.find(kind => kind.id === receipt?.kind)?.name}
      isOpen={isOpen}
      onClose={onClose}
      {...props}
    >
      <div className="bg-white rounded">
        {receipt?.seller?.id && (
          <div className="bg-green-100 px-3 py-2 rounded-t">
            <div>
              <div className="font-500">
                {receipt?.seller?.name}{" "}
                <span className="text-gray-600 font-400">(farmer name)</span>
              </div>
              <CodenameTag
                referenceId={receipt?.seller?.external_reference_id}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-3 p-3">
          {receipt?.trades.map(trade => {
            return (
              <SummaryTable
                key={trade.buyer?.id}
                header={
                  <tr className="!bg-yellow-100">
                    <th
                      colSpan={4}
                      className="font-500 px-3 gap-1 w-full py-[8px]"
                    >
                      <span className="flex gap-3">
                        {trade.buyer?.name}{" "}
                        <CodenameTag
                          referenceId={trade.buyer?.external_reference_id}
                        />
                      </span>
                    </th>
                  </tr>
                }
                data={trade?.items}
                columns={[
                  {
                    name: "Grade",
                    accessor: "grade",
                    cell(value) {
                      return value?.name
                    },
                    footer() {
                      return "Total"
                    },
                  },
                  {
                    name: "Rate",
                    accessor: "rate",
                    cell(value) {
                      return (
                        <>
                          {inr(value)} / {receipt.unit?.name}
                        </>
                      )
                    },
                  },
                  {
                    name: "Quantity",
                    accessor: "quantity",
                    cell(value) {
                      return (
                        <>
                          {value} {receipt.unit?.name}
                        </>
                      )
                    },
                    footer() {
                      return (
                        <span className="font-500">
                          {trade.total_quantity} {receipt.unit?.name}
                        </span>
                      )
                    },
                  },
                  {
                    name: "Amount",
                    accessor: "total_amount",
                    cell(value) {
                      return inr(value || 0)
                    },
                    footer() {
                      return (
                        <span className="font-500">
                          {inr(trade.total_amount || 0)}
                        </span>
                      )
                    },
                  },
                ]}
              />
            )
          })}

          <hr className="border-dashed" />
          <SummaryTable
            data={[
              {
                id: 1,
                type: "std",
              },
              {
                id: 2,
                type: "expense",
                name: "Total Expense",
              },
              {
                id: 3,
                type: "expense",
                name: "Seller Expense",
              },
            ]}
            columns={[
              {
                name: "Total",
                cell(_, { type, name, id }) {
                  return (
                    <span className={id !== 2 ? "text-red-500" : undefined}>
                      {type === "std"
                        ? `Standard Deduction - ${percent(
                            receipt?.standard_deduction || 0
                          )}`
                        : name}
                    </span>
                  )
                },
                footer() {
                  return (
                    <span className="text-lg text-green-400 font-600">
                      Seller Payable
                    </span>
                  )
                },
              },
              {
                name: `${receipt?.total_quantity} ${receipt?.unit?.name}`,
                cell(_, { type }) {
                  if (type === "expense") return null
                  return (
                    <span className="text-red-500">
                      {receipt?.quantity_deducted} {receipt?.unit?.name}
                    </span>
                  )
                },
              },
              {
                name: `${inr(receipt?.total_amount || 0)}`,
                cell(_, { type, id }) {
                  return (
                    <span className={id !== 2 ? "text-red-500" : undefined}>
                      {inr(
                        type === "std"
                          ? receipt?.amount_deducted || 0
                          : id === 2
                          ? receipt?.expense_amount || 0
                          : receipt?.seller_expense_amount || 0
                      )}
                    </span>
                  )
                },
                footer() {
                  return (
                    <span className="text-lg text-green-400 font-600">
                      {inr(receipt?.seller_receivable_amount || 0)}
                    </span>
                  )
                },
              },
            ]}
          />
        </div>
        <div className="p-3">
          {receipt?.kind && <PavtiNote kind={receipt?.kind} />}
        </div>
      </div>
    </Modal>
  )
}

export default CreateReceiptSummary
