import { Card, Group } from "@vesatogo/grass-core"
import { ICardWithFooterProps } from "~/pages/pavti/lib/types"

export function CardWithFooter({
  children,
  footerSection,
}: ICardWithFooterProps) {
  return (
    <Card>
      <Group className="flex flex-col items-center p-4 pt-12">{children}</Group>
      <Group className="w-full border-gray-300 border-t p-3">
        {footerSection}
      </Group>
    </Card>
  )
}
