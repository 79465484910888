import { Card, Group, Modal } from "@vesatogo/grass-core"

import { CaretRight } from "@phosphor-icons/react"
import c from "classnames"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { TradeDetailsSubscription } from "~/generated/graphql"
import { getVerificationColor } from "~/pages/pavti/lib/utils"
export function TradeStatus({
  data,
}: {
  data: TradeDetailsSubscription["trade_by_pk"]
}) {
  const { t } = useTranslation()
  const sellerVerification = data?.seller_verification
  const buyerVerification = data?.buyer_verification
  const [remarksModal, setRemarksModal] = useState<string | false>(false)

  return data ? (
    <>
      {sellerVerification !== null ? (
        <Card
          className={c(
            "p-2 mt-2 flex items-center gap-2 rounded-md cursor-pointer",
            getVerificationColor(sellerVerification)
          )}
        >
          <span
            className={c(
              "p-2 h-2 rounded-full self-center",
              getVerificationColor(sellerVerification, true)
            )}
          ></span>
          <span
            className="w-full"
            onClick={e =>
              !sellerVerification && setRemarksModal(data.seller_remark || "")
            }
          >
            {t(
              `${sellerVerification ? "seller.confirmed" : "seller.rejected"}`
            )}
          </span>
          {!sellerVerification ? (
            <CaretRight size={24} className="text-red" />
          ) : null}
        </Card>
      ) : null}
      {buyerVerification !== null ? (
        <Card
          className={c(
            "p-2 mt-2 flex items-center gap-2 rounded-md",
            getVerificationColor(buyerVerification)
          )}
        >
          <span
            className={c(
              "p-2 h-2 rounded-full self-center",
              getVerificationColor(buyerVerification, true)
            )}
          ></span>
          <span
            className="w-full"
            onClick={e =>
              !buyerVerification && setRemarksModal(data.buyer_remark || "")
            }
          >
            {t(`${buyerVerification ? "buyer.confirmed" : "buyer.rejected"}`)}
          </span>
          {!buyerVerification ? (
            <CaretRight size={24} className="text-red" />
          ) : null}
        </Card>
      ) : null}
      <Modal
        isOpen={remarksModal !== false}
        onClose={() => setRemarksModal(false)}
        title={t("remark")}
      >
        <Group className="">{data.seller_remark}</Group>
      </Modal>
    </>
  ) : null
}
