export const userTypeMapper = {
  s: {
    name: "seller",
    title: "Farmer",
  },
  b: {
    name: "buyer",
    title: "Trader",
  },
}
