import {
  Check,
  CheckCircle,
  Plus,
  Trash,
  Warning,
  XCircle,
} from "@phosphor-icons/react"
import {
  Button,
  Flex,
  FormInput,
  FormQuerySelector,
  FormSelector,
  NoDataFound,
  Note,
  Selector,
  Tabs,
  Tag,
  Tooltip,
} from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import { useState } from "react"
import { useClient } from "urql"
import { FormCard } from "~/components/FormCard"
import Codenames from "~/constants/Codenames"
import { ADVANCE_KINDS, EXPENSE_KINDS } from "~/constants/static.items"
import {
  ExpenseConfigsQuery,
  ExpenseConfigsQueryVariables,
  useAdancedPaymentParametersQuery,
  useExpenseConfigVersionsQuery,
  useExpenseParametersQuery,
} from "~/generated/graphql"
import { EXPENSE_CONFIGS } from "~/graphql/trade.gql"

export type Expense = {
  expense_parameter: any
  seller: {
    id?: any
    paid_by: "seller"
    expense_parameter: any
    kind: any
    value: any
    amount: any
  } | null
  buyer: {
    id?: any
    paid_by: "buyer"
    expense_parameter: any
    kind: any
    value: any
    amount: any
  } | null
  kind: any
  amount: any
}

export type Advaced_Payment = {
  parameter: any
  bourn_by: string
  amount: number
}

export const EXPENSE: Expense = {
  expense_parameter: null,
  kind: null,
  amount: 0,
  seller: {
    paid_by: "seller",
    value: null,
    amount: null,
    kind: null,
    expense_parameter: null,
  },
  buyer: {
    paid_by: "buyer",
    value: null,
    amount: null,
    kind: null,
    expense_parameter: null,
  },
}

export const ADVANCED_PAYMENT: Advaced_Payment = {
  parameter: null,
  bourn_by: "",
  amount: 0,
}

export type TradeExpensesProps = {
  isDisabled?: boolean
  form: any
  sharedDeptId: string
  inputProps: any
  onExpenseTemplateChange?: (expenseConfig: ExpenseConfigsQuery) => void
  onDeleteAdvancePayment?: (idx: number, advancePayment: any) => void
  onDeleteExpense?: (idx: number, expense: any) => void
  isStatusGenerateSale?: boolean
  hasInvoice?: boolean
}

const TradeExpenses = ({
  form,
  sharedDeptId,
  isDisabled,
  inputProps,
  onExpenseTemplateChange,
  onDeleteExpense,
  onDeleteAdvancePayment,
  isStatusGenerateSale = false,
  hasInvoice = false,
}: TradeExpensesProps) => {
  const [expenseTemplate, setExpenseTemplate] = useState<any>()
  const [activeTab, setActiveTab] = useState("tradeExpenses")
  const client = useClient()
  const [{ data: expenseConfigList }] = useExpenseConfigVersionsQuery({
    variables: {
      department: `${sharedDeptId}`,
      kind: form.values.kind,
      mode: form.values.mode,
    },
    pause: !sharedDeptId || !form.values.mode || !form.values.kind,
    requestPolicy: "network-only",
  })

  const isVerified = form?.values?.credit_notes[0]?.is_verified

  return (
    <>
      <FormCard title="Finance">
        <Tabs
          tabs={[
            {
              id: "tradeExpenses",
              header: <h6 className="text-base font-500">Trade Expenses</h6>,
              panel: (
                <>
                  {form.values?.trades?.length > 0 &&
                    form.values?.__typename !== "trade" && (
                      <Note
                        className="mb-3"
                        intent="info"
                        title="All expenses are individually applied to every trade"
                      />
                    )}
                  {form.values?.expenses?.length ? (
                    <table className="expenses-table w-full">
                      <thead className="bg-green-100 text-sm rounded">
                        <tr>
                          <th className="p-1 w-[25%]">Expense Parameter</th>
                          <th className="p-1 w-[25%]">Expense Type</th>
                          <th className="p-1">Farmer Charge</th>
                          <th className="p-1">Trader Charge</th>
                          <th className="p-1">Facilitator Charge</th>
                          <th className="p-1 text-right">Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.values.expenses?.map((expense, idx) => {
                          const isExpenseDisabled =
                            isStatusGenerateSale && hasInvoice
                              ? true
                              : isStatusGenerateSale &&
                                !hasInvoice &&
                                expense?.expense_parameter?.codename !==
                                  Codenames.PackagingExpense &&
                                expense?.expense_parameter?.codename !==
                                  undefined
                              ? true
                              : isStatusGenerateSale && !hasInvoice
                              ? false
                              : isDisabled

                          const rightElement = expense?.kind?.short && (
                            <Tag variant="minimal" className="mr-2">
                              {expense?.kind?.id === "flat"
                                ? `x${form.values.trades?.length || 1}`
                                : expense?.kind?.short}
                            </Tag>
                          )

                          return (
                            <tr
                              key={
                                expense.expense_parameter?.id || `exp_${idx}`
                              }
                            >
                              <td>
                                <FormQuerySelector
                                  filterOption={(opt: any) => {
                                    if (isStatusGenerateSale && !hasInvoice) {
                                      return opt.data.codename ===
                                        Codenames.PackagingExpense
                                        ? true
                                        : false
                                    } else return true
                                  }}
                                  className="w-full"
                                  isOptionDisabled={(opt: any) => {
                                    return form.values.expenses?.find(
                                      exp =>
                                        Number(exp.expense_parameter?.id) ===
                                        Number(opt.id)
                                    )
                                      ? true
                                      : false
                                  }}
                                  disabled={isExpenseDisabled}
                                  dataHook={useExpenseParametersQuery}
                                  {...inputProps(
                                    `expenses.${idx}.expense_parameter`
                                  )}
                                />
                              </td>
                              <td>
                                <FormSelector
                                  className="w-full"
                                  // options={EXPENSE_KINDS.filter(kind => kind.id !== "flat")}
                                  options={EXPENSE_KINDS}
                                  disabled={isExpenseDisabled}
                                  {...inputProps(`expenses.${idx}.kind`)}
                                />
                              </td>
                              <td>
                                <FormInput
                                  type={"number"}
                                  className="text-right"
                                  rightElement={rightElement}
                                  {...inputProps(
                                    `expenses.${idx}.seller.value`
                                  )}
                                  disabled={isExpenseDisabled}
                                />
                              </td>
                              <td>
                                <FormInput
                                  type={"number"}
                                  className="text-right"
                                  rightElement={rightElement}
                                  {...inputProps(`expenses.${idx}.buyer.value`)}
                                  disabled={isExpenseDisabled}
                                />
                              </td>
                              <td>
                                <FormInput
                                  type={"number"}
                                  className="text-right"
                                  rightElement={rightElement}
                                  {...inputProps(
                                    `expenses.${idx}.facilitator.value`
                                  )}
                                  disabled={isExpenseDisabled}
                                />
                              </td>
                              <td className="text-right flex-1">
                                {inr(expense.amount)}
                              </td>
                              <td>
                                <button
                                  disabled={isExpenseDisabled}
                                  className={classNames(
                                    "w-10 flex justify-center",
                                    isExpenseDisabled
                                      ? "text-gray-600"
                                      : "text-red-500"
                                  )}
                                  onClick={() =>
                                    onDeleteExpense?.(idx, expense)
                                  }
                                >
                                  <Trash
                                    height={24}
                                    width={24}
                                    className="!mr-0"
                                  />
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr className="border-t border-gray-300 border-dashed">
                          <td colSpan={2}>Total Expense</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-right font-500">
                            {inr(form.values.expense_amount || 0)}
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  ) : (
                    <NoDataFound
                      className="min-h-[11rem]"
                      title="There are no expenses added yet"
                    >
                      <Button
                        onClick={() => form.insertListItem("expenses", EXPENSE)}
                        leftIcon={<Plus />}
                        size="sm"
                        disabled={
                          isStatusGenerateSale &&
                          hasInvoice &&
                          form.values.expenses?.find(
                            exp =>
                              exp?.expense_parameter?.codename ===
                              Codenames.PackagingExpense
                          ) !== undefined
                        }
                      >
                        Expense
                      </Button>
                    </NoDataFound>
                  )}
                </>
              ),
            },
            {
              id: "advancePayment",
              header: <h6 className="text-base font-500">Advance Payment</h6>,
              panel: (
                <>
                  {form.values?.advance_payment?.length ? (
                    <table className="expenses-table w-full">
                      <thead className="bg-green-100 text-sm rounded w-full">
                        <tr className="w-[100%]">
                          <th className="p-1 w-[40%] ">Advance Parameter</th>
                          <th className="p-1 w-[40%]">Borne by</th>
                          <th className="p-1 w-[40%]">Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.values?.advance_payment?.map(
                          (advance_payment, idx) => {
                            const isAdvanceDisabled = isDisabled
                            const is_verified = advance_payment?.is_verified

                            return (
                              <tr key={advance_payment?.id || `exp_${idx}`}>
                                <td>
                                  <FormQuerySelector
                                    dataHook={useAdancedPaymentParametersQuery}
                                    {...inputProps(
                                      `advance_payment.${idx}.parameter`
                                    )}
                                    disabled={isAdvanceDisabled}
                                  />
                                </td>
                                <td>
                                  <FormSelector
                                    options={ADVANCE_KINDS}
                                    disabled={isAdvanceDisabled}
                                    getOptionValue={opt => opt.id}
                                    getOptionLabel={opt => opt.name}
                                    {...inputProps(
                                      `advance_payment.${idx}.bourn_by`
                                    )}
                                  />
                                </td>
                                <td className="text-right">
                                  <FormInput
                                    disabled={isAdvanceDisabled}
                                    type="number"
                                    {...inputProps(
                                      `advance_payment.${idx}.amount`
                                    )}
                                  />
                                </td>
                                <td>
                                  {isAdvanceDisabled || advance_payment?.id ? (
                                    is_verified === null ||
                                    is_verified === undefined ? (
                                      <Tooltip content="Pending">
                                        <Warning
                                          size={24}
                                          weight="fill"
                                          className="!mr-0 text-blue"
                                        />
                                      </Tooltip>
                                    ) : !is_verified ? (
                                      <Tooltip content="Rejected">
                                        <XCircle
                                          size={24}
                                          weight="fill"
                                          className="!mr-0 text-red"
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip content="Verified">
                                        <CheckCircle
                                          size={24}
                                          weight="fill"
                                          className="!mr-0 text-green"
                                        />
                                      </Tooltip>
                                    )
                                  ) : (
                                    <button
                                      className={classNames(
                                        "w-10 flex ",
                                        isAdvanceDisabled || advance_payment?.id
                                          ? "text-gray-600"
                                          : "text-red-500"
                                      )}
                                      disabled={
                                        isAdvanceDisabled || advance_payment?.id
                                      }
                                      onClick={() =>
                                        onDeleteAdvancePayment?.(
                                          idx,
                                          advance_payment
                                        )
                                      }
                                    >
                                      <Trash
                                        height={24}
                                        width={24}
                                        className="!mr-0"
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                      <tfoot>
                        <tr className="border-t border-gray-300 border-dashed">
                          <td colSpan={2}>Total Advance</td>
                          <td className="text-right font-500">
                            {inr(form.values.advance_amount || 0)}
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  ) : (
                    <NoDataFound
                      className="min-h-[11rem]"
                      title="There are no Advance Payment added yet"
                    >
                      <Button
                        onClick={() =>
                          form.insertListItem(
                            "advance_payment",
                            ADVANCED_PAYMENT
                          )
                        }
                        leftIcon={<Plus />}
                        size="sm"
                        disabled={isDisabled}
                      >
                        Advance
                      </Button>
                    </NoDataFound>
                  )}
                </>
              ),
            },
            {
              id: "creditNote",
              header: (
                <h6 className="text-base font-500">Supplier Credit Note</h6>
              ),
              headerIcon: <Check size={20} />,
              panel: (
                <>
                  <table className="expenses-table w-full">
                    <thead className="bg-green-100 text-sm rounded w-full">
                      <tr className="w-[100%]">
                        <th className="p-1">Remark</th>
                        <th className="p-1 w-1/6">Amount</th>
                        <th className="p-1 w-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        <tr key={1}>
                          <td>
                            <FormInput
                              placeholder="Remark"
                              disabled={isDisabled}
                              {...inputProps(`credit_notes.0.remarks`)}
                            />
                          </td>
                          <td className="text-right">
                            <FormInput
                              type="number"
                              disabled={isDisabled}
                              {...inputProps(`credit_notes.0.amount`)}
                            />
                          </td>
                          {form?.values?.credit_notes?.[0]?.id && (
                            <td>
                              {isVerified === null ||
                              isVerified === undefined ? (
                                <Tooltip content="Pending">
                                  <Warning
                                    size={24}
                                    weight="fill"
                                    className="!mr-0 text-blue"
                                  />
                                </Tooltip>
                              ) : !isVerified ? (
                                <Tooltip content="Rejected">
                                  <XCircle
                                    size={24}
                                    weight="fill"
                                    className="!mr-0 text-red"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip content="Verified">
                                  <CheckCircle
                                    size={24}
                                    weight="fill"
                                    className="!mr-0 text-green"
                                  />
                                </Tooltip>
                              )}
                            </td>
                          )}
                        </tr>
                      }
                    </tbody>
                  </table>
                </>
              ),
            },
          ]}
          onChange={(_, idx) =>
            setActiveTab(() => {
              return idx === 1
                ? "advancePayment"
                : idx === 2
                ? "creditNote"
                : "tradeExpenses"
            })
          }
        >
          {activeTab === "tradeExpenses" ? (
            <Flex className="float-right">
              {!isDisabled && onExpenseTemplateChange && (
                <Selector
                  value={expenseTemplate}
                  options={expenseConfigList?.expense_config_versions?.map(
                    item => ({ id: item?.version_id, name: item?.name })
                  )}
                  className="font-400 min-w-[15rem]"
                  onChange={async (item: any) => {
                    try {
                      const { data: expenseConfig } = await client
                        .query<
                          ExpenseConfigsQuery,
                          ExpenseConfigsQueryVariables
                        >(EXPENSE_CONFIGS, {
                          version: item?.id,
                          department: `${sharedDeptId}`,
                          kind: form.values.kind,
                          mode: form.values.mode,
                        })
                        .toPromise()
                      if (expenseConfig?.expense_configs?.length) {
                        onExpenseTemplateChange(expenseConfig)
                      }
                    } catch {
                    } finally {
                      setExpenseTemplate(item)
                    }
                  }}
                />
              )}
              <Button
                className="w-full"
                disabled={
                  isStatusGenerateSale && hasInvoice
                    ? true
                    : isStatusGenerateSale &&
                      !hasInvoice &&
                      form.values.expenses?.find(
                        exp =>
                          exp?.expense_parameter?.codename ===
                          Codenames.PackagingExpense
                      ) !== undefined
                }
                onClick={() => form.insertListItem("expenses", { ...EXPENSE })}
                leftIcon={<Plus />}
                size="sm"
              >
                Expense
              </Button>
            </Flex>
          ) : activeTab === "advancePayment" ? (
            <Flex className="float-right">
              <Button
                onClick={() =>
                  form.insertListItem("advance_payment", ADVANCED_PAYMENT)
                }
                leftIcon={<Plus />}
                size="sm"
                disabled={isDisabled}
              >
                Advance
              </Button>
            </Flex>
          ) : activeTab === "creditNote" ? null : null}
        </Tabs>
      </FormCard>
    </>
  )
}

export default TradeExpenses
