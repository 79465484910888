export const TEMPLATE_BASE_FRAG = /* GraphQL */ `
  fragment TemplateBaseFrag on template {
    id
    name
    grade {
      id
      name
    }
    template_parameters {
      id
      configurations(
        where: { evaluation_criteria: { tag: { name: { _eq: "fail" } } } }
      ) {
        id
        comparator
        value
        evaluation_criteria {
          id
          tag {
            id
            name
          }
        }
      }
      quality_parameter {
        id
        name
      }
      standard
      weightage
      observation_count
    }
  }
`

export const ALL_SOYABEAN_TEMPLATES = /* GraphQL */ `
  query SoyaBeanTemplates($reference_id: String!, $entity_type: String) {
    template(
      where: {
        is_active: { _eq: true }
        quality_stage: {
          codename: { _eq: "b4bda282-4e1d-4753-b204-ad0f48f658e8" }
        }
        test_for: {
          reference_id: { _eq: $reference_id }
          entity_type: { _eq: $entity_type }
        }
      }
    ) {
      ...TemplateBaseFrag
    }
  }
  ${TEMPLATE_BASE_FRAG}
`
