export const TNC_CONFIG_VERSIONS = /* GraphQL */ `
  query TncConfigVersions(
    $department: ID
    $kind: String!
    $mode: String!
    $role: String
  ) {
    trade_tnc_versions(
      department: $department
      kind: $kind
      mode: $mode
      role: $role
    ) {
      id: version_id
      name
      kind
      mode
      role
    }
  }
`

export const TNC_CONFIG_DETAIL = /* GraphQL */ `
  query TncConfigs(
    $version: ID!
    $department: ID!
    $kind: String!
    $mode: String!
    $role: String
  ) {
    trade_tnc_template_detail(
      version: $version
      department: $department
      kind: $kind
      mode: $mode
      role: $role
    ) {
      seller
      buyer
    }
  }
`
