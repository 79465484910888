import { TradeKinds, TradeKindsReadable } from "~/constants/Kinds"

export const EXPENSE_KINDS = [
  { id: "flat", name: "Flat", short: "Rs" },
  { id: "amount_percent", name: "Amount Percent", short: "%" },
  { id: "cost_per_unit", name: "Cost Per Unit", short: "Per Unit" },
  {
    id: "deducted_cost_per_unit",
    name: "Deducted Cost Per Unit",
    short: "Per Unit",
  },
]

export const ADVANCE_KINDS = [
  { id: "Farmer", name: "Farmer"},
  { id: "Company", name: "Company" },
]

export const TRADE_STATUES = [
  { id: "draft", name: "Draft" },
  { id: "sent", name: "Sent" },
  { id: "verified", name: "Verified" },
  { id: "rejected", name: "Rejected" },
  { id: "in_progress", name: "In Progress" },
  { id: "complete", name: "Complete" },
]

export const TRADE_KINDS = Object.values(TradeKinds).map(value => ({
  id: value,
  name: TradeKindsReadable[value],
}))

export const TwilioMessages = {
  delivered: {
    id: "delivered",
    name: "Delivered",
    short: "Delivered",
    color: "green",
  },
  failed: {
    id: "failed",
    name: "Failed",
    short: "Failed",
    color: "red",
  },
  read: {
    id: "read",
    name: "Read",
    short: "Read",
    color: "blue",
  },
}
