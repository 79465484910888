import { FormSelector, Modal } from "@vesatogo/grass-core"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { CustomToast } from "~/components/CustomToast"

const DEFAULT_OPTIONS = [
  { id: 1, label: "Bad Debts Pending" },
  { id: 2, label: "Write off Pending" },
  { id: 3, label: "Bad Debts Done" },
  { id: 4, label: "Write off Done" },
  { id: 5, label: "FY 23-24 Season Turnover Discount" },
  { id: 6, label: "FY24-25 Season Turnover Discount" },
  { id: 7, label: "Credit Note" },
  { id: 8, label: "Debit Note" },
  { id: 9, label: "Wrong Sale Booking" },
  { id: 10, label: "Wrong Purchase Booking" },
  { id: 11, label: "Settled" },
  { id: 12, label: "Not Settled" },
  { id: 13, label: "Old Receivable" },
  { id: 14, label: "Old Payable" },
  { id: 15, label: "Advance" },
]
export const RemarkModal = ({
  useAddRemarkMutation,
  options = DEFAULT_OPTIONS,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get("remark")
  const isOpen = Boolean(id)
  const [remark, setRemark] = useState<any>()

  const [, addRemark] = useAddRemarkMutation()
  const onSubmit = async () => {
    if (!remark) {
      return CustomToast({
        intent: "danger",
        title: "Please select a reason",
      })
    }
    const { data: updateData, error } = await addRemark({
      id: id,
      object: {
        remark: remark ? remark?.label : "",
      },
    })
    if (error) {
      onClose()
      return CustomToast({
        title: "Something went wrong! " + error.message,
        intent: "danger",
      })
    }
    onClose()
    return CustomToast({
      title: "Successfully updated remark!",
    })
  }

  const onClose = () => {
    searchParams.delete("remark")
    setSearchParams(searchParams)
    return
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={props.title}
      bodyClassName="max-w-sm overflow-visible"
      primaryActionButtonProps={{
        text: "Ok",
        onClick: onSubmit,
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        onClick: onClose,
      }}
    >
      <FormSelector
        options={options}
        onChange={e => {
          setRemark(e)
        }}
        value={remark}
        getOptionLabel={opt => opt.label}
        label="Select Reason"
      />
    </Modal>
  )
}
