import { useLocalStorage } from "@mantine/hooks"
import { Card } from "@vesatogo/grass-core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Expenses,
  Facilitator,
  Particulars,
  TradeTC,
  TradeValidity,
  UserCopy,
} from "./details/index"

import { Warning } from "@phosphor-icons/react"
import {
  VerifyTradeOtp,
  usePavtiTradeDetailQuery,
  useTradeDetailsSubscription,
} from "~/generated/graphql"
import receiptIcon from "~/pages/pavti/assets/receipt.svg"
import { AgreeTerms, PavtiNote } from "~/pages/pavti/components"
import { IOTPProps } from "~/pages/pavti/lib/types"
import { getHashValue, switchUserType } from "~/pages/pavti/lib/utils"
import { StickyFooter } from "~/pages/pavti/sections/details/StickyFooter"
import { TradeStatus } from "~/pages/pavti/sections/details/TradeStatus"
import { useURQLClient } from "~/services/urql.service"

export function Details({ token, setTitle, page, setPage }: IOTPProps) {
  const { t } = useTranslation()
  const [acceptTc, setAcceptTc] = useState(true)
  const [, setLocalValue] = useLocalStorage<VerifyTradeOtp>({
    key: `${token}`,
  })
  const { resetClient } = useURQLClient()

  const hashValue = getHashValue(token)
  const [{ data }] = usePavtiTradeDetailQuery({
    variables: { id: hashValue?.tradeId },
  })
  const [{ data: subscription, error }] = useTradeDetailsSubscription({
    variables: { id: hashValue?.tradeId },
  })

  useEffect(() => {
    if (error) {
      setLocalValue({})
      setTitle && setTitle("")
      setPage && setPage(1)
      resetClient()
    }
  }, [error])

  useEffect(() => {
    if (setTitle && data) {
      setTitle(
        data?.trade_by_pk?.kind === "final"
          ? "permanent.trade"
          : "temporary.trade"
      )
    }
  }, [data?.trade_by_pk?.kind])

  return data && hashValue ? (
    <>
      <TradeStatus data={subscription?.trade_by_pk} />
      <Facilitator data={data.trade_by_pk} />
      <PavtiNote
        text={`${t("receipt.title")}: ${data.trade_by_pk?.id}`}
        icon={receiptIcon as unknown as string}
      />
      <Card className="overflow-hidden !rounded-lg">
        <UserCopy
          data={data.trade_by_pk}
          userType={switchUserType(hashValue?.userType, page === 1)}
        />
        <Particulars data={data.trade_by_pk} />
        <Expenses
          data={data.trade_by_pk}
          userType={switchUserType(hashValue?.userType, page === 1)}
        />
      </Card>
      <TradeValidity data={data.trade_by_pk} />
      <TradeTC
        data={data.trade_by_pk}
        userType={switchUserType(hashValue?.userType, page === 1)}
      />
      <Card className="overflow-hidden mt-4 !rounded-lg">
        {data?.trade_by_pk?.kind !== "final" ? (
          <PavtiNote
            text={t("subject.to.change")}
            icon={<Warning size={20} />}
          />
        ) : null}
      </Card>
      <AgreeTerms value={acceptTc} onChange={setAcceptTc} />
      <Card className="py-2 px-4 !rounded-lg fixed bottom-0 left-0 w-full !shadow-[0px_-5px_10px_rgba(0,0,0,0.12)]">
        <StickyFooter
          data={subscription?.trade_by_pk}
          id={data.trade_by_pk?.id}
          userType={hashValue.userType}
          page={page}
          setPage={setPage}
        />
      </Card>
    </>
  ) : null
}
