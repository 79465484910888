import {
  Activity,
  Article,
  Package,
  ShoppingBag,
  Storefront,
} from "@phosphor-icons/react"
import { Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { NavLink, Navigate, Outlet } from "react-router-dom"
import RequestTimeline from "~/components/RequestTimeline"
import { Permission } from "~/constants/Permissions"
import TradeMetrics from "~/pages/d/trade-metrics"
import AllTradeRequests from "~/pages/d/trade-requests"
import AdvancePayable from "~/pages/d/payable"
import NetPayable from "~/pages/d/payable/net"
import TradeRequestDetail from "~/pages/d/trade-requests/[id]"
import TradeInvoices from "~/pages/d/trade-requests/invoices"
import TradeReceipts from "~/pages/d/trade-requests/receipts"
import ReceiptDetail from "~/pages/d/trade-requests/receipts/[id]"
import { TradeTemplates } from "~/pages/d/trade-templates"
import MandiList from "~/pages/mandi-listing"
import HishobPatti from "~/pages/mandi-listing/hishob-patti"
import PackingMaterial from "~/pages/packing-material"
import Payable from "~/icons/Payable"
import BankReconciliation from "~/icons/BankReconciliation"
import PlantSettlement from "~/pages/d/bank-reconciliation"
import InvoiceSettlement from "~/pages/d/bank-reconciliation/invoice-settlement"

function SidebarMenuItem({ link, name }: { link: string; name: string }) {
  return (
    <MenuItem className="p-0 [&>div]:m-0">
      <NavLink
        className={({ isActive }) => `p-2 block ${isActive && "bg-gray-200"}`}
        to={link}
      >
        {name}
      </NavLink>
    </MenuItem>
  )
}

export const dashboardRoutes: any[] = [
  {
    path: "trade-metrics",
    title: "Trade Metrics",
    icon: <Activity weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <TradeMetrics />,
      },
    ],
  },
  {
    path: "trade-requests",
    title: "Trade Requests",
    icon: <ShoppingBag weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <AllTradeRequests />,
      },
      {
        path: ":id",
        element: <TradeRequestDetail />,
      },
      {
        path: "receipts",
        element: <TradeReceipts />,
      },
      {
        path: "invoices",
        element: <TradeInvoices />,
      },
      {
        path: "receipts/:id",
        element: <ReceiptDetail />,
      },
    ],
  },
  {
    path: "trade-templates",
    title: "Trade Templates",
    icon: (
      <Popover
        minimal
        arrow={false}
        className="p-0 border-t border-gray-200 ml-4 -mt-4"
        trigger="mouseover"
        interactive
        animation="fade"
        placement="right-start"
        content={
          <Menu className="max-h-52">
            <SidebarMenuItem link="trade-templates/expenses" name="Expenses" />
            <SidebarMenuItem
              link="trade-templates/seller"
              name="Seller Terms"
            />
            <SidebarMenuItem link="trade-templates/buyer" name="Buyer Terms" />
          </Menu>
        }
      >
        <Article weight="fill" />
      </Popover>
    ),
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="expenses" />,
      },
      {
        path: "expenses",
        title: "Expenses Templates",
        element: <TradeTemplates type="expenses" />,
      },
      {
        path: "seller",
        title: "Seller Terms Templates",
        element: <TradeTemplates type="seller" />,
      },
      {
        path: "buyer",
        title: "Buyer Terms Templates",
        element: <TradeTemplates type="buyer" />,
      },
    ],
  },
  {
    path: "mandi-listing",
    title: "Mandi Listing",
    icon: <Storefront weight="fill" />,
    permission: Permission.ViewSummaryDashboard,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <MandiList />,
      },

      {
        path: "hishob-patti",
        element: <HishobPatti />,
      },
    ],
  },
  {
    path: "packing-material",
    title: "Packing Material",
    icon: <Package weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <PackingMaterial />,
      },
    ],
  },
  {
    path: "payable",
    title: "Payable Management",
    icon: <Payable weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <AdvancePayable />,
      },
      {
        path: "net",
        element: <NetPayable />,
      },
    ],
  },
  {
    path: "bank-reconciliation",
    title: "Bank Reconciliation",
    icon: <BankReconciliation weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <PlantSettlement />,
      },

      {
        path: "invoice-settlement",
        element: <InvoiceSettlement />,
      },
    ],
  },
]
