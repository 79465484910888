import React, { useEffect } from "react"
import { Plus, Trash } from "@phosphor-icons/react"
import { Button, Input } from "@vesatogo/grass-core"
import SummaryTable from "~/components/SummaryTable"
import { QuerySelector } from "~/components/QuerySelector"
import { usePackingMaterialListQuery } from "~/generated/graphql"
import { get } from "lodash-es"

type PackingCalculationProps = {
  form: any
  onDeleteMaterial?: (index: number) => void
}

const PackingCalculation = ({
  form,
  onDeleteMaterial,
}: PackingCalculationProps) => {
  const handleDelete = (index: number) => {
    onDeleteMaterial && onDeleteMaterial(index)
  }

  const columns = [
    {
      name: "Material",
      accessor: "packaging.name",
      cell(value, row, rowIndex) {
        return (
          <QuerySelector
            required
            className="w-96"
            dataHook={usePackingMaterialListQuery}
            value={get(row, "packaging_id")}
            disabled={form.values.packing_material[rowIndex]?.id !== undefined}
            onChange={(selectedItem: any) => {
              const updatedMaterials = form.values.packing_material.map(
                (item: any, i: number) =>
                  i === rowIndex
                    ? { ...item, packaging_id: selectedItem }
                    : item
              )
              form.setFieldValue("packing_material", updatedMaterials)
            }}
          />
        )
      },
      footer() {
        return <span className="font-500">Total</span>
      },
    },
    {
      name: "Quantity",
      accessor: "quantity",
      cell(value, row, rowIndex) {
        return (
          <Input
            required
            type="number"
            step="any"
            value={row.quantity}
            disabled={form.values.packing_material[rowIndex]?.id !== undefined}            
            className="w-full"
            onChange={e => {
              const updatedMaterials = form.values.packing_material.map(
                (item: any, i: number) =>
                  i === rowIndex
                    ? {
                        ...item,
                        quantity: parseFloat(e.target.value),
                        amount: parseFloat(e.target.value) * item.rate || 0,
                      }
                    : item
              )
              form.setFieldValue("packing_material", updatedMaterials)
            }}
          />
        )
      },
      footer(data) {
        return (
          <h6 className="font-700 border rounded shadow-sm p-1 px-3">
            {data.reduce((acc, item) => acc + item.quantity, 0).toFixed(2)}
          </h6>
        )
      },
    },
    {
      name: "Rate",
      accessor: "rate",
      cell(value, row, rowIndex) {
        return (
          <Input
            required
            type="number"
            step="any"
            className="w-full"
            value={row.rate}
            min={0}
            disabled={form.values.packing_material[rowIndex]?.id !== undefined}
            onChange={e => {
              const updatedMaterials = form.values.packing_material.map(
                (item: any, i: number) =>
                  i === rowIndex
                    ? {
                        ...item,
                        rate: parseFloat(e.target.value),
                        amount: parseFloat(e.target.value) * item.quantity || 0,
                      }
                    : item
              )
              form.setFieldValue("packing_material", updatedMaterials)
            }}
          />
        )
      },
    },
    {
      name: "Amount",
      accessor: "amount",
      cell(value, row, rowIndex) {
        return (
          <Input
            required
            className="w-full"
            type="number"
            step="any"
            value={row.amount.toFixed(2)}
            min={0}
            disabled
          />
        )
      },
      footer(data) {
        const totalAmount = data
          .reduce((acc, item) => acc + item.amount, 0)
          .toFixed(2)
        useEffect(() => {
          form.setFieldValue("total_amount", totalAmount)
        }, [totalAmount])
        return (
          <h6 className="font-700 border rounded shadow-sm p-1 px-3">
            ₹ {form.values.total_amount}
          </h6>
        )
      },
    },
    {
      name: " ",
      cell(value, row, rowIndex) {
        return (
          <Button
            variant="minimal"
            intent="danger"
            onClick={() => handleDelete(rowIndex)}
            disabled={form.values.packing_material[rowIndex]?.id !== undefined}
          >
            <Trash size={21} />
          </Button>
        )
      },
    },
  ]

  return (
    <div className="mt-4 rounded-lg border">
      <div className="flex gap-3 justify-between items-center border-b px-4 p-1">
        <h6>Packing Material</h6>
        <Button
          variant="ghost"
          intent="primary"
          leftIcon={<Plus />}
          onClick={() => {
            const newData = [
              ...form.values.packing_material,
              { packaging_id: null, quantity: 0, rate: 0, amount: 0 },
            ]
            form.setFieldValue("packing_material", newData)
          }}
        >
          Add Material
        </Button>
      </div>

      <SummaryTable
        className="w-full overflow-auto"
        columns={columns}
        data={form.values.packing_material}
      />
    </div>
  )
}

export default PackingCalculation
