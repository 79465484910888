import { useHotkeys } from "@mantine/hooks"
import { Button, FormGroup, Image, Input, Modal } from "@vesatogo/grass-core"
import { FileUpload } from "@vesatogo/grass-dropzone"
import { useState } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import {
  useAddPackagingSapMutation,
  useViewSapTransactionQuery,
} from "~/generated/graphql"

export enum ModalType {
  ADD_SAP = "ADD_SAP",
  VIEW_SAP = "VIEW_SAP",
}

type ViewAddSapModalProps = {
  open: boolean
  handleClose: () => void
  type?: ModalType
  refetchData?: () => void
}

const ViewAddSapModal = ({
  open,
  handleClose,
  type = ModalType.VIEW_SAP,
  refetchData,
}: ViewAddSapModalProps) => {
  const [params, setParams] = useSearchParams()
  const [, insertSap] = useAddPackagingSapMutation()
  const [transaction_number, setTransactionNumber] = useState("")
  const [media, setMedia] = useState<any[]>([])

  const handleAddSap = () => {
    if (!transaction_number || !media[0]?.id) {
      toast.error("Please fill all the fields")
      return
    }

    insertSap({
      id: params.get("id"),
      _set: {
        transaction_number: transaction_number.trim(),
        transaction_attachment_id: media[0]?.id,
      },
    }).then(async () => {
      if (refetchData) {
        await refetchData()
      }
      setTransactionNumber("")
      setMedia([])
      handleClose()
    })
  }

  useHotkeys([["Escape", handleClose]])

  const primaryActionButtonProps =
    type !== ModalType.VIEW_SAP
      ? { text: "Yes, Confirm", onClick: handleAddSap }
      : undefined

  const secondaryActionButtonProps =
    type !== ModalType.VIEW_SAP
      ? {
          text: "Cancel",
          onClick: () => {
            setTransactionNumber("")
            setMedia([])
            handleClose()
          },
        }
      : undefined

  const [{ data, fetching }] = useViewSapTransactionQuery({
    variables: {
      where: {
        id: {
          _eq: params.get("id"),
        },
      },
    },
    requestPolicy: "network-only",
  })

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        handleClose()
        setTransactionNumber("")
        setMedia([])
      }}
      title={type === ModalType.VIEW_SAP ? "Attachment" : "Add SAP Invoice"}
      primaryActionButtonProps={primaryActionButtonProps}
      secondaryActionButtonProps={secondaryActionButtonProps}
    >
      {type === ModalType.VIEW_SAP ? (
        <div>
          <div>
            Transaction Number:{" "}
            <span className="font-600 text-black">
              {data?.packaging_order[0]?.transaction_number}
            </span>
          </div>
          {data?.packaging_order[0]?.transaction_attachment?.kind ===
          "application/pdf" ? (
            <iframe
              className="w-full p-2 rounded-lg mt-3 border h-96 mx-auto"
              src={data?.packaging_order[0]?.transaction_attachment?.url || ""}
              title="attachment"
            />
          ) : (
            <>
              <Image
                className="w-full p-2 rounded-lg mt-3 border h-96 mx-auto"
                src={
                  data?.packaging_order[0]?.transaction_attachment?.url || ""
                }
                alt="attachment"
              />
              <div className="flex justify-end mt-2">
                <Button
                  variant="outline"
                  onClick={() =>
                    window.open(
                      data?.packaging_order[0]?.transaction_attachment?.url ||
                        ""
                    )
                  }
                >
                  Download Attachment
                </Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          <div className="flex gap-3">
            <div className="h-max">
              <FileUpload
                onError={error => {
                  toast.error(error)
                  return
                }}
                onDelete={() => {
                  setMedia([])
                }}
                uploadCardClassName="h-32 w-32 border border-gray-300 rounded-lg"
                onChange={() => toast.success("File Uploaded Successfully")}
                onSuccess={(file: any) => {
                  setMedia([file[0]?.data?.media])
                }}
                endpoint={`https://${
                  import.meta.env.VITE_REST_URL
                }/rest/trade/uploader/`}
                appName="setutrade"
                media={media}
                showPreviewForDocument
                maxFiles={1}
                shouldEncrypt={false}
              />
            </div>

            <div>
              <FormGroup required label="Transaction Number">
                <Input
                  placeholder="Enter Transaction Number"
                  onChange={(e: any) => setTransactionNumber(e.target.value)}
                  value={transaction_number}
                  required
                />
              </FormGroup>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ViewAddSapModal
