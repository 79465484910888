import { UseFormReturnType } from "@mantine/form"
import { CheckCircle, Pen, Plus } from "@phosphor-icons/react"
import { Button, Flex, NoDataFound, Toast } from "@vesatogo/grass-core"
import { omit, pick } from "lodash-es"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useClient } from "urql"
import { FormCard } from "~/components/FormCard"
import QCObservationGrade from "~/components/QCObservationGrade"
import Codenames from "~/constants/Codenames"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import {
  InputMaybe,
  ResultOneQuery,
  ResultOneQueryVariables,
  SampleObservationInput,
  useAddUpdateSharedEntityMutation,
  useSoyaBeanTemplatesQuery,
  useSubmitSampleObservationsMutation,
} from "~/generated/graphql"
import { RESULT_ONE } from "~/graphql/quality/result.gql"
import { useDepartment } from "~/utils/useDepartment"

type QCObservationsProps = {
  form: UseFormReturnType<any, (values: any) => any>
  commodity?: any
  tradeForm?: any
}
const QCObservations = ({ form, commodity }: QCObservationsProps) => {
  const resultsList = form.values.results
  const commodityReferenceId = commodity?.codename
  const commodityName = commodity?.name

  const hasResults = form.values?.results?.some?.(result => result?.id)
  const [isEditing, setEditing] = useState(!hasResults)

  useEffect(() => {
    // Disable editing if there are results
    setEditing(!hasResults)
  }, [hasResults])
  const client = useClient()

  const dept = useDepartment()

  const [{ fetching }, submitSampleObservations] =
    useSubmitSampleObservationsMutation()
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()

  const [{ data }] = useSoyaBeanTemplatesQuery({
    variables: {
      reference_id: commodityReferenceId,
      entity_type: SharedEntityGroup.KiteCommodity,
    },
    pause: !commodityReferenceId,
  })

  if (!data?.template?.length) return null

  return (
    <FormCard
      title="QC Observation"
      action={
        <Button
          disabled={resultsList.length === data?.template?.length}
          onClick={() => {
            const template = data.template.find(
              template =>
                !resultsList.find(result => result.template.id !== template.id)
            )
            form.insertListItem(
              "results",
              {
                template,
                observations: template?.template_parameters.map(
                  (param, index) => {
                    return {
                      observation_number: 1,
                      index,
                      template_parameter_id: param.id,
                      quality_parameter_id: param.quality_parameter.id,
                    }
                  }
                ),
              },
              0
            )
          }}
          size="sm"
          leftIcon={<Plus />}
        >
          Grade
        </Button>
      }
    >
      {resultsList?.length ? (
        resultsList.map((result, index) => {
          return (
            <QCObservationGrade
              result={result}
              index={index}
              key={result?.id || `result_${index + 1}`}
              isEditing={isEditing}
              form={form}
              results={data?.template || []}
            />
          )
        })
      ) : (
        <NoDataFound
          title="Unable to find quality data for the selected commodity, try adding a sample"
          className="!min-h-full"
        />
      )}

      <Flex className="justify-end">
        <Flex>
          {isEditing ? (
            <>
              <Button
                loading={fetching}
                key={"cancel"}
                onClick={() => setEditing(false)}
                variant="outline"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                disabled={!resultsList?.length}
                loading={fetching}
                key={"save"}
                size="sm"
                leftIcon={<CheckCircle />}
                onClick={async () => {
                  try {
                    const results = resultsList
                    if (!results.length)
                      throw new Error("Please add at least one sample")

                    const newResults: any[] = []

                    for await (const singleResult of results) {
                      if (!singleResult.observations?.length) continue

                      if (!singleResult.template.id) {
                        throw new Error(
                          "Please select a valid grade to continue QC"
                        )
                      }
                      let observations: InputMaybe<
                        InputMaybe<SampleObservationInput>[]
                      > = []
                      for (const observation of singleResult.observations) {
                        if (observation.value && observation.value !== 0) {
                          observations.push({
                            id: observation.sample_observation_id,
                            value: Number(observation.value),
                            observation_number: observation.observation_number,
                            parameter_id: observation.quality_parameter_id,
                            photos: observation.attachments?.map(attach =>
                              pick(attach, ["id", "url", "media_kind"])
                            ),
                          })
                        }
                      }

                      const { data } = await submitSampleObservations({
                        input: {
                          id: singleResult.sample_id,
                          request_id: singleResult.sample?.sample_request_id,
                          template_id: singleResult.template.id,
                          request_category_codename: Codenames.EagleTestRequest,
                          department: {
                            codename: dept?.id,
                            name: dept?.name,
                          },
                          unit_codename: Codenames.PercentageUnit,
                          primary_location: {
                            reference_id: commodityReferenceId,
                            entity_type: SharedEntityGroup.KiteCommodity,
                            name: commodityName,
                          },
                          observations: observations,
                        },
                      })
                      const result =
                        data?.submit_observations?.sample?.results?.[0]

                      if (!result) continue

                      const { data: resultData } = await client
                        .query<ResultOneQuery, ResultOneQueryVariables>(
                          RESULT_ONE,
                          { id: result.id },
                          { requestPolicy: "network-only" }
                        )
                        .toPromise()

                      const resultDetails = resultData?.result_by_pk
                      if (!resultDetails) continue

                      const { data: facilitatorShared } =
                        await upsertSharedEntity({
                          reference_id: resultDetails?.codename,
                          group_name: SharedEntityGroup.EagleResult,
                          name: resultDetails?.name,
                        })
                      let shared_id =
                        facilitatorShared?.insert_setu_trade_shared_entity_one
                          ?.id
                      if (shared_id) {
                        toast.custom(
                          <Toast title="Sample saved successfully for the Reselected grade" />
                        )
                        newResults.push({
                          ...resultDetails,
                          observations: resultDetails?.observations.map(
                            (obs, index) => ({
                              ...omit(obs, [
                                "sample_observation",
                                "template_parameter",
                              ]),
                              sample_observation_id: obs.sample_observation?.id,
                              observation_number:
                                obs.sample_observation?.observation_number,
                              quality_parameter_id:
                                obs.template_parameter?.quality_parameter_id,
                              index,
                              attachments:
                                obs.sample_observation?.attachments?.map(
                                  attach => attach.media
                                ),
                            })
                          ),
                          shared_id: shared_id,
                        })
                      }
                    }
                    setEditing(false)
                    form.setFieldValue("results", newResults)
                  } catch (error: any) {
                    return toast.custom(
                      <Toast title={error.message} intent="danger" />
                    )
                  }
                }}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              loading={fetching}
              key={"edit"}
              onClick={() => setEditing(true)}
              //   onClick={() => form.insertListItem(`trades.${index}.items`, {})}
              variant="ghost"
              leftIcon={<Pen />}
              size="sm"
              //   disabled={isDisabled}
            >
              Edit
            </Button>
          )}
        </Flex>
      </Flex>
    </FormCard>
  )
}

export default QCObservations
