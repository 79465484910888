import { Toast } from "@vesatogo/grass-core"
import { TradeKinds, TradeKindsReadable } from "~/constants/Kinds"

const PavtiNote = ({ kind }: { kind: string }) => {
  const title = `${TradeKindsReadable[kind]}`
  return (
    <>
      {kind === TradeKinds.HishobPatti ? (
        <Toast
          title={title}
          subtitle="No information can be changed later. The invoice will be created directly now."
          className="max-w-full"
          variant="minimal"
        />
      ) : (
        <Toast
          title={title}
          subtitle="This is temporary and is subject to change later."
          className="max-w-full"
          intent="warning"
          variant="minimal"
        />
      )}
    </>
  )
}

export default PavtiNote
