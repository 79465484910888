import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { CaretDown } from "@phosphor-icons/react"
import { useSearchParams } from "react-router-dom"
import { useReportModal } from "@vesatogo/grass-reportmodal"
import { useAllDepartmentsQuery } from "~/generated/graphql"
const TradeActions = () => {
  const [params, setParams] = useSearchParams()
  const onOpen = useReportModal(store => store.onOpen)
  return (
    <Popover
      minimal
      arrow={false}
      className="p-0"
      trigger="mouseenter"
      interactive
      animation="fade"
      theme="light-border"
      placement="bottom"
      content={
        <Menu className="max-h-52">
          <MenuItem
            onClick={() => {
              onOpen(
                [
                  {
                    key: "from_date",
                    label: "Date From",
                    type: "date",
                  },
                  {
                    key: "till_date",
                    label: "Date Till",
                    type: "date",
                  },
                  {
                    key: "trade_role",
                    label: "Trade Role",
                    type: "staticSelect",
                    options: [
                      {
                        id: "seller",
                        name: "Seller",
                      },
                      {
                        id: "buyer",
                        name: "Buyer",
                      },
                    ],
                  },
                  {
                    key: "department",
                    label: "Department",
                    type: "querySelect",
                    dataHook: useAllDepartmentsQuery,
                  },
                ],
                `https://${import.meta.env.VITE_REST_URL}/reports/trade_email/`,
                {
                  multipleEmails: true,
                }
              )
            }}
          >
            Trade Report
          </MenuItem>
          <MenuItem
            onClick={() => {
              params.set("update", "all")
              setParams(params)
            }}
          >
            Update Trades
          </MenuItem>
          <MenuItem
            onClick={() => {
              params.set("action", "generate-invoice")
              setParams(params)
            }}
          >
            Generate Invoice For Trader
          </MenuItem>
        </Menu>
      }
    >
      <Button
        className="!bg-gray-300"
        intent="none"
        leftIcon={<CaretDown weight="fill" />}
      >
        Action
      </Button>
    </Popover>
  )
}

export default TradeActions
