import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import "@vesatogo/grass-core/index.css"
import { ReportModal } from "@vesatogo/grass-reportmodal"
import "@vesatogo/grass-table/index.css"
import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { Toaster } from "react-hot-toast"
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import App from "./App"
import "./index.css"
import ClientProvider from "./services/urql.service"
Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DNS,
  environment: import.meta.env.VITE_APP_ENV || "development",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <BrowserRouter>
        <ClientProvider>
          <Toaster
            toastOptions={{
              duration: 500,
              position: "top-right",
            }}
          />
          <App />
          <ReportModal />
        </ClientProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
