import { createContext, useContext, useMemo } from "react"
import { useAuthenticatedUserQuery } from "~/generated/graphql"

const AuthorizationContext = createContext({})
export type AuthorizationProviderProps<T> = {
  children?: React.ReactNode
  permissions: T[]
}
export const AuthorizationProvider = ({
  children,
  permissions,
}: AuthorizationProviderProps<any>) => {
  const [{ data }] = useAuthenticatedUserQuery()
  const availablePermissions = data?.me?.permissions
  const availablePermissionsMap = useMemo(
    () =>
      availablePermissions?.reduce((acc, curr) => {
        if (!curr) return acc
        const [module, permission] = curr.split(".")
        return {
          ...acc,
          [permission as string]: { module },
        }
      }, {}),
    [availablePermissions]
  )
  const authorizationMap: Record<string, boolean> = useMemo(() => {
    return permissions.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: availablePermissionsMap?.[curr] ? true : false,
      }
    }, {})
  }, [availablePermissionsMap])

  return (
    <AuthorizationContext.Provider value={authorizationMap}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export const useAuthorizationContext = () => useContext(AuthorizationContext)

export const useAuthorization = (permission: string) => {
  const authorizationMap = useAuthorizationContext()
  return authorizationMap[permission]
}
