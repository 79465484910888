import { omit } from "lodash"
import { create } from "zustand"
import { SelectorType } from "~/store/types"

export const useInvoiceSelectorList = create<SelectorType>((set, get) => ({
  selected: {},
  setSelected(value) {
    if (get().selected[value.id]) {
      set({ selected: omit(get().selected, value.id) })
    } else {
      set({ selected: { ...get().selected, [value.id]: value } })
    }
  },
  selectAll(values) {
    if (Object.keys(get().selected).length === values.length) {
      set({ selected: {} })
    } else {
      const selected = {}
      values.forEach(value => {
        selected[value.id] = value
      })
      set({ selected })
    }
  },
  reset() {
    set({ selected: {} })
  },
}))
