export const OrdersCustom = ({ className }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.125 3.75C3.125 2.36929 4.24429 1.25 5.625 1.25H16C16.4832 1.25 16.875 1.64175 16.875 2.125V18.2368C16.875 19.1025 15.7522 19.4425 15.272 18.7222L14.3078 17.2759L12.846 18.9465C12.4974 19.345 11.8776 19.345 11.529 18.9465L10 17.1991L8.471 18.9465C8.12239 19.345 7.5026 19.345 7.154 18.9465L5.69221 17.2759L4.72804 18.7222C4.24782 19.4425 3.125 19.1025 3.125 18.2368V3.75ZM5.625 2.5C4.93464 2.5 4.375 3.05964 4.375 3.75V16.9983L4.92384 16.175C5.24341 15.6956 5.93101 15.6506 6.31039 16.0842L7.8125 17.8009L9.3415 16.0535C9.6901 15.655 10.3099 15.655 10.6585 16.0535L12.1875 17.8009L13.6896 16.0842C14.069 15.6506 14.7566 15.6956 15.0762 16.175L15.625 16.9983V2.5H5.625Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.129 4.99634H11.0508V3.74634H14.129V4.99634Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.129 7.76123H11.0508V6.51123H14.129V7.76123Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0495 11.0754H5.40234V9.82544H14.0495V11.0754Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0495 13.8037H5.40234V12.5537H14.0495V13.8037Z"
      fill="currentColor"
    />
    <path
      d="M8.86133 4.17921C8.84793 4.16808 8.83518 4.1556 8.82335 4.14174C8.76959 4.07879 8.75513 4.01219 8.75513 3.96461C8.75513 3.91702 8.76959 3.85042 8.82335 3.78747C8.83518 3.77361 8.84793 3.76113 8.86133 3.75L9.04898 3.75C9.32384 3.75 9.32384 4.17921 9.04898 4.17921H8.86133Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M8.85135 5.25587C8.83796 5.24474 8.8252 5.23226 8.81337 5.2184C8.75962 5.15545 8.74515 5.08885 8.74515 5.04127C8.74515 4.99368 8.75962 4.92708 8.81337 4.86413C8.8252 4.85027 8.83796 4.83779 8.85135 4.82666L9.039 4.82666C9.31387 4.82666 9.31387 5.25587 9.039 5.25587H8.85135Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M7.60137 3.82604C7.60319 3.82561 7.60499 3.82519 7.60676 3.82479C7.62569 3.82988 7.64446 3.83519 7.66307 3.84068L7.65141 3.87985C7.63483 3.85916 7.61776 3.84129 7.60137 3.82604ZM7.60137 3.82604C7.59999 3.82476 7.59861 3.82349 7.59724 3.82224C7.59465 3.82156 7.59207 3.82088 7.58948 3.8202C7.57403 3.82414 7.55361 3.82985 7.54134 3.83384C7.52872 3.83846 7.5066 3.8479 7.48719 3.85686L7.5004 3.79874C7.49786 3.79818 7.49532 3.79763 7.49278 3.79708L7.47959 3.86041C7.47317 3.86345 7.46728 3.86635 7.46242 3.86888C7.43924 3.88251 7.39211 3.91587 7.36859 3.93586C7.36593 3.93844 7.36302 3.94138 7.35992 3.94462C7.36047 3.95088 7.36076 3.9569 7.36084 3.96263C7.36473 3.95828 7.36836 3.95437 7.37162 3.95104C7.3941 3.93081 7.43952 3.8966 7.46207 3.8824C7.46624 3.88006 7.47121 3.8774 7.47664 3.87459L7.40526 4.21743L7.48402 3.87083C7.50341 3.86105 7.52623 3.85042 7.53913 3.84521C7.56176 3.83659 7.58282 3.83046 7.60137 3.82604ZM7.35077 3.97417L7.33427 4.07909C7.32516 4.09792 7.31325 4.11709 7.29785 4.13603L7.31191 4.02273C7.32437 4.00619 7.3383 3.98879 7.35077 3.97417ZM7.35077 3.97417C7.35176 3.97301 7.35274 3.97187 7.35371 3.97074L7.3577 3.94695M7.35451 3.95035C7.34255 3.96324 7.32853 3.97947 7.31526 3.99575L7.33331 3.85023C7.34681 3.87772 7.35431 3.90441 7.35797 3.92837L7.35451 3.95035ZM7.35923 3.93783L7.38627 3.77664L7.35923 3.93783Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
)
