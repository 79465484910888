import React, { useEffect, useState } from "react"
import { useForm } from "@mantine/form"
import { FormGroup, Modal, QuerySelector, Spinner } from "@vesatogo/grass-core"
import { get } from "lodash-es"
import { useSearchParams } from "react-router-dom"
import PackingCalculation from "~/components/PackingMaterial/PackingCalculation"
import PaymentDetails from "~/components/PackingMaterial/PaymentDetails"
import PaymentOption from "~/components/PackingMaterial/PaymentOption"
import {
  Packaging_Order_Constraint,
  Packaging_Order_Item_Constraint,
  Packaging_Order_Item_Update_Column,
  Packaging_Order_Update_Column,
  Payment_Constraint,
  Payment_Update_Column,
  useAddUpdateSharedEntityMutation,
  useAllPlotCropsQuery,
  useInsertPackagingOrderMutation,
  usePackingMaterialByIdQuery,
  useUsersQuery,
} from "~/generated/graphql"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import dayjs from "dayjs"
import toast from "react-hot-toast"
import { useHotkeys } from "@mantine/hooks"

type NewMaterialModalProps = {
  open: boolean
  handleClose: () => void
}

const INITIAL_STATE = {
  farmer: null as any,
  farmerExternalId: null as any,
  plot: null as any,
  plotExternalId: null,
  total_amount: 0,
  total_amount_paid: 0,
  payment_status: "pending",
  packing_material: [
    { packaging_id: null, quantity: 0, rate: 0, amount: 0 },
  ] as any[],
  payment: [] as any[],
}

const NewMaterialModal = ({ open, handleClose }: NewMaterialModalProps) => {
  const form = useForm({
    initialValues: INITIAL_STATE,
    validate: {
      farmer: value => (!value ? "Farmer is required" : null),
      packing_material: {
        packaging_id: value => (!value ? "Packaging ID is required" : null),
        quantity: value => {
          if (!value) return "Quantity is required"
          if (value <= 0) return "Quantity must be a positive number"
          return null
        },
        rate: value => {
          if (!value) return "Rate is required"
          if (value <= 0) return "Rate must be a positive number"
          return null
        },
      },
    },
  })

  const pForm = useForm({
    initialValues: {
      payment_status: "pending",
      payment: {
        amount: 0,
        mode: {} as any,
        trade_attachement: "",
        transaction_id: "",
        account_number: "",
        re_account_number: "",
        acount_holder: "",
        bank_name: "",
        branch_name: "",
        ifsc_code: "",
        photo_id: "",
      },
    },
    validate: {
      payment: {
        amount: value => {
          const remainingAmount = state?.total_amount - state?.total_amount_paid
          if (!value) return "Amount is required"
          if (value <= 0) return "Amount must be greater than 0"
          if (value > remainingAmount)
            return `Amount must be less than or equal to ${remainingAmount}`
          return null
        },
        mode: value => (!value ? "Mode is required" : null),
        transaction_id: value => (!value ? "Transaction ID is required" : null),
        ifsc_code: (value, values) => {
          if (!value) return null
          if (value.length !== 11) return "IFSC Code must be 11 characters"
          if (!values.payment.bank_name)
            return "Bank name is required for the given IFSC code"
          if (!values.payment.branch_name)
            return "Branch name is required for the given IFSC code"
          return null
        },
        re_account_number: (value, values) => {
          if (value !== values.payment.account_number)
            return "Account Number and Re-verify Account Number do not match"
          return null
        },
      },
    },
  })

  const { values: state, setValues: setState, reset: resetForm } = form
  const { values: pFormValues, reset: resetPForm } = pForm

  const [isDisabled, setIsDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()
  const [, insertPackagingOrder] = useInsertPackagingOrderMutation()

  const [params, setParams] = useSearchParams()
  const farmerId = params.get("farmerId")

  const [{ data }] = usePackingMaterialByIdQuery({
    variables: {
      id: farmerId,
    },
    requestPolicy: "network-only",
  })

  const updateData = data => {
    if (data) {
      const { packaging_order_by_pk } = data

      if (packaging_order_by_pk) {
        const {
          packaging_order_items,
          user,
          plot_crop,
          total_amount,
          total_amount_paid,
          payment_status,
        } = packaging_order_by_pk
        const packing_material = packaging_order_items.map(item => ({
          id: item.id,
          packaging_id: item.packaging,
          quantity: item.quantity,
          rate: item.rate,
          amount: item.amount,
        }))

        const payments = packaging_order_by_pk.payments.map(payment => ({
          id: payment.id,
          amount: payment.amount,
          mode: payment.mode,
          transaction_id: payment.transaction_id,
          trade_attachement: payment.trade_attachement,
          account_number: payment.account_number,
          acount_holder: payment.acount_holder,
          bank_name: payment.bank_name,
          branch_name: payment.branch_name,
          ifsc_code: payment.ifsc_code,
          photo_id: payment.photo_id,
        }))

        setState({
          farmer: user?.user[0],
          plot: plot_crop?.plot_crop[0],
          total_amount: total_amount,
          total_amount_paid: total_amount_paid,
          packing_material: packing_material,
          payment: payments,
          payment_status: payment_status,
        })
      }
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    updateData(data)
  }, [data])

  const date = new Date()
  function handleSave() {
    const formValidationErrors = form.validate()
    const pFormValidationErrors = pForm.validate()

    if (formValidationErrors.hasErrors) {
      toast.error("Please fill all the details correctly", { duration: 3000 })
      return
    }

    const { farmerExternalId, plotExternalId, packing_material } = state

    let payment = state.payment
    if (
      pForm.values?.payment_status === "paid" ||
      pForm.values?.payment_status === "partial"
    ) {
      if (pFormValidationErrors.hasErrors) {
        toast.error("Please fill all the details correctly", { duration: 3000 })
        return
      }
      payment = [{ ...state.payment, ...pForm.values.payment }]
    }

    setLoading(true)

    insertPackagingOrder({
      on_conflict: {
        constraint: Packaging_Order_Constraint.PackagingOrderPkey,
        update_columns: [Packaging_Order_Update_Column.Date],
      },
      input: {
        id: data?.packaging_order_by_pk?.id,
        user_id: farmerExternalId,
        plot_crop_id: plotExternalId,
        date: dayjs(date).format("YYYY-MM-DD"),
        packaging_order_items: {
          on_conflict: {
            constraint: Packaging_Order_Item_Constraint.PackagingOrderItemPkey,
            update_columns: [
              Packaging_Order_Item_Update_Column.Quantity,
              Packaging_Order_Item_Update_Column.Rate,
              Packaging_Order_Item_Update_Column.PackagingId,
            ],
          },
          data: packing_material.map(item => ({
            id: item.id,
            packaging_id: item.packaging_id.id,
            quantity: item.quantity,
            rate: item.rate,
          })),
        },
        ...(payment.length > 0 && {
          payments: {
            on_conflict: {
              constraint: Payment_Constraint.PaymentPkey,
              update_columns: [
                Payment_Update_Column.Amount,
                Payment_Update_Column.Mode,
                Payment_Update_Column.TransactionId,
                Payment_Update_Column.AccountNumber,
                Payment_Update_Column.AcountHolder,
                Payment_Update_Column.BankName,
                Payment_Update_Column.BranchName,
                Payment_Update_Column.IfscCode,
                Payment_Update_Column.Date,
              ],
            },
            data: payment.map(item => ({
              id: item.id,
              amount: item.amount,
              mode: item.mode?.id ? item.mode.id : item.mode,
              transaction_id: item.transaction_id,
              account_number: item.account_number,
              acount_holder: item.acount_holder,
              bank_name: item.bank_name,
              branch_name: item.branch_name,
              ifsc_code: item.ifsc_code,
              date: dayjs(date).format("YYYY-MM-DD"),
              photo_id: item.photo_id ? item.photo_id : null,
            })),
          },
        }),
      },
    })
      .then(response => {
        setLoading(false)
        if (response.data) {
          toast.success("Packing order saved successfully")
          resetForm()
          resetPForm()
          handleClose()
        }
      })
      .catch(error => {
        setLoading(false)
        toast.error("Error saving packing order")
      })
  }

  const deleteMaterial = (index: number) => {
    const updatedMaterials = [...form.values.packing_material]

    updatedMaterials.splice(index, 1)

    form.setFieldValue("packing_material", updatedMaterials)
  }

  useHotkeys([["Enter", handleSave]])

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        resetForm()
        resetPForm()
        handleClose()
      }}
      title="Packing Material"
      bodyClassName="w-full md:w-2/3"
      primaryActionButtonProps={
        state.payment_status === "paid"
          ? {
              text: "Close",
              onClick: () => {
                resetForm()
                resetPForm()
                handleClose()
              },
            }
          : {
              text: "Save",
              onClick: handleSave,
            }
      }
      secondaryActionButtonProps={
        state.payment_status === "paid"
          ? {
              text: "",
              hidden: true,
              onClick: () => {},
            }
          : {
              text: "Cancel",
              onClick: () => {
                resetForm()
                resetPForm()
                handleClose()
              },
            }
      }
    >
      <div className="p-4">
        {loading && <Spinner />}
        <FormGroup>
          <div className="flex flex-col md:flex-row w-2/3 gap-3">
            <FormGroup required label="Farmer Name" className={"w-full"}>
              <QuerySelector
                disabled={isDisabled}
                serverSideQuery
                dataHook={useUsersQuery}
                isClearable={false}
                variables={{ groups: ["Farmer"] }}
                placeholder="Select a farmer"
                value={get(state, "farmer", "")}
                onChange={async (event: any) => {
                  const value = event.target ? event.target.value : event
                  form.setFieldValue("farmer", value)
                  form.setFieldValue("plot", null)
                  const { data: aData } = await upsertSharedEntity({
                    reference_id: value.codename,
                    group_name: SharedEntityGroup.KiteUser,
                    name: value.name,
                  })
                  if (aData?.insert_setu_trade_shared_entity_one) {
                    form.setFieldValue(
                      "farmerExternalId",
                      aData?.insert_setu_trade_shared_entity_one?.id
                    )
                  }
                }}
              />
            </FormGroup>

            <FormGroup label="Plot" className={"w-full"}>
              <QuerySelector
                className="mb-2"
                serverSideQuery
                disabled={isDisabled}
                dataHook={useAllPlotCropsQuery}
                placeholder="Select a plot"
                required
                isClearable={false}
                variables={{ farmer: get(state, "farmer.id") }}
                value={get(state, "plot", "")}
                onChange={async (event: any) => {
                  const value = event?.target ? event?.target?.value : event
                  form.setFieldValue("plot", value)
                  const { data: aData } = await upsertSharedEntity({
                    reference_id: value.codename,
                    group_name: SharedEntityGroup.KitePlotCrop,
                    name: value.name,
                  })
                  if (aData?.insert_setu_trade_shared_entity_one) {
                    form.setFieldValue(
                      "plotExternalId",
                      aData?.insert_setu_trade_shared_entity_one?.id
                    )
                  }
                }}
              />
            </FormGroup>
          </div>
        </FormGroup>
        <PackingCalculation form={form} onDeleteMaterial={deleteMaterial} />
        <PaymentDetails data={data?.packaging_order_by_pk} />
        {state.payment_status === "paid" ? null : (
          <PaymentOption
            form={pForm}
            totalAmount={state?.total_amount - state?.total_amount_paid}
          />
        )}
      </div>
    </Modal>
  )
}

export default NewMaterialModal
