import { useLocalStorage } from "@mantine/hooks"
import {
  List,
  MagnifyingGlass,
  Receipt,
  SlidersHorizontal,
} from "@phosphor-icons/react"
import { Button, InputGroup, SegmentedControl, Tag } from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { TableView } from "@vesatogo/grass-table"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAddUpdateSharedEntityMutation,
  useMixAnalyticsQuery,
} from "~/generated/graphql"

export const MandiList = () => {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()
  const [, setDepartment] = useLocalStorage({ key: "department" })
  const canViewSummaryDashboard = useAuthorization(
    Permission.ViewSummaryDashboard
  )

  const [fromDate, setFromDate] = useState(new Date())
  const [tillDate, setTillDate] = useState(new Date())

  const search = params.get("search")
  const updatedAt = params.get("updatedAt")

  const [{ data, fetching }] = useMixAnalyticsQuery({
    variables: {
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      till: dayjs(tillDate).format("YYYY-MM-DD"),
      filters: {
        department_codenames: [
          "3002",
          "24965311-d0ee-4709-a524-d8929461e4a2",
          "af423ddc-997f-4f62-9c2c-2022cc779238",
          "6df3274b-8c41-44a1-9a71-831429982fc1",
          "4d969690-3208-494c-84ef-aebef53f8bc0",
          "cff69d42-45ce-49a2-9c1b-2699c6b9febb",
          "15a90c90-d2ca-40bf-ac82-d223a9c7a100",
          "15a90c90-d2ca-40bf-ac82-d223a9c-3009",
        ],
      },
    },
    requestPolicy: "network-only",
  })

  const analyticsData = data?.mix_analytics || []
  const trades = analyticsData.reduce((prev, aData) => {
    const rows = aData?.trades?.map((trade, idx) => {
      return {
        ...trade,
        mandi: idx === 0 ? { ...aData, omit: false } : { ...aData, omit: true },
      }
    })
    return prev.concat(
      rows?.length
        ? rows
        : ([
            {
              mandi: aData,
              isLast: true,
            },
          ] as any)
    )
  }, [])

  const inrFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const COLUMNS = [
    {
      Header: <div>Mandi Name</div>,
      accessor: "mandi",
      Cell({ row: { original }, value }) {
        if (original?.mandi?.omit) return ""
        return (
          <div className="">
            <Link
              className="link-text border-gray-300 text-left "
              to={AppRoutes.tradeRequests}
              onClick={() => {
                upsertSharedEntity({
                  group_name: value.name,
                  name: value.name,
                  reference_id: value.reference_id,
                })
                setDepartment(value.reference_id)
              }}
            >
              {value.name}
            </Link>
          </div>
        )
      },
      className: "w-[9%]",
    },
    {
      Header: <div>Plant Code</div>,
      accessor: "mandi.id",
      Cell({ row: { original }, value }) {
        if (original?.mandi?.omit) return ""
        return <div className="">{value}</div>
      },
      className: "w-[7%]",
    },
    // {
    //   Header: <div >User Access</div>,
    //   accessor: "user_access",
    //   Cell({  value }) {

    //     return (
    //       <div className=" flex gap-2">
    //         {/* {value?.map(user => {
    //           return (

    //         //     <Tooltip content={user?.name}>
    //         //     <Avatar name={user?.name} />
    //         //   </Tooltip>
    //           )

    //         })} */}
    //         <OverflowMenu
    //           showTooltip
    //           visibleItems={4}
    //           getTitle={item => {
    //             return generateInitials(item?.name, false)
    //           }}
    //           items={value}
    //         ></OverflowMenu>
    //       </div>
    //     )
    //   },
    //   className: "!text-center",
    // },
    {
      Header: <div>Transaction Type</div>,
      accessor: "kind",
      Cell({ value }) {
        return (
          <Tag
            intent={value === "Sauda Pavti" ? "warning" : "cyan"}
            className=""
          >
            {value}
          </Tag>
        )
      },
      className: "w-[10%]",
    },
    {
      Header: <div>Number of Transactions</div>,
      accessor: "total_transactions",
      Cell({ value }) {
        return <div className="">{value ? value : "-"}</div>
      },
      className: "w-[6%]",
      aggregate: "sum",
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Qty in Kg</div>,
      accessor: "total_quantity",
      Cell({ value }) {
        return <div className="">{value ? parseInt(value) : "-"}</div>
      },
      aggregate: "sum",
      className: "w-[6%]",
      formatter: parseInt,
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Value INR</div>,
      accessor: "total_value",
      Cell({ value }) {
        return (
          <div className="">{value ? inrFormatter.format(value) : "-"}</div>
        )
      },
      className: "w-[6%]",
      aggregate: "sum",
      formatter: inrFormatter.format,
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Unverified Transaction No.</div>,
      accessor: "unverified_transactions",
      Cell({ value }) {
        return <div className="">{value ? value : "-"}</div>
      },
      aggregate: "sum",
      className: "w-[6%]",
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Qty in Kg</div>,
      accessor: "unverified_quantity",
      Cell({ value }) {
        return <div className="">{value ? parseInt(value) : "-"}</div>
      },
      aggregate: "sum",
      className: "w-[6%]",
      formatter: parseInt,
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Value INR</div>,
      accessor: "unverified_value",
      Cell({ value }) {
        return (
          <div className="">{value ? inrFormatter.format(value) : "-"}</div>
        )
      },
      className: "w-[6%]",
      aggregate: "sum",
      formatter: inrFormatter.format,
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Verified Transaction No.</div>,
      accessor: "verified_transactions",
      Cell({ value }) {
        return <div className="">{value ? value : "-"}</div>
      },
      className: "w-[6%]",
      aggregate: "sum",
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Qty in Kg</div>,
      accessor: "verified_quantity",
      Cell({ value }) {
        return <div className="">{value ? parseInt(value) : "-"}</div>
      },
      className: "w-[6%]",
      aggregate: "sum",
      formatter: parseInt,
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>Value INR</div>,
      accessor: "verified_value",
      Cell({ value }) {
        return (
          <div className="">{value ? inrFormatter.format(value) : "-"}</div>
        )
      },
      className: "w-[6%]",
      aggregate: "sum",
      formatter: inrFormatter.format,
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>No. of Hishob Patti Conversions</div>,
      accessor: "final_conversion_count",
      Cell({ value }) {
        return <div className="">{value ? value : "-"}</div>
      },
      className: "w-[10%]",
      aggregate: "sum",
      aggregateClassName: "!text-center border-r-1 border-blue-200",
    },
    {
      Header: <div>No. of pending Hishob Patti Conversions</div>,
      accessor: "final_conversion_pending_count",
      Cell({ value }) {
        return <div className="">{value ? value : "-"}</div>
      },
      className: "w-[10%]",
      aggregate: "sum",
    },
  ]

  const totalMandis = data?.mix_analytics?.length || 0
  useEffect(() => {
    if (updatedAt) {
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])

  return canViewSummaryDashboard ? (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"mix"}
          onChange={value => {
            if (value === "hishob") {
              navigate(AppRoutes.hishobPatti)
            }
          }}
          data={[
            {
              label: "Mix Patti",
              value: "mix",
              icon: <List className="mr-1" />,
            },
            canViewSummaryDashboard && {
              label: "Hishob Patti",
              value: "hishob",
              icon: <Receipt className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            hidden
            inputProps={{
              value: search || "",
              placeholder: "Search by Mandi name",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            rightElement={
              <Button
                className="!bg-gray-200 border-l-1 border-gray-300 !rounded-l-none"
                leftIcon={<SlidersHorizontal className="!m-0" />}
              ></Button>
            }
            leftElement={<MagnifyingGlass />}
          />
        </div>
        <div className="flex ">
          <FormDateInput
            size="sm"
            className={"!w-fit "}
            value={fromDate}
            onChange={date => {
              setFromDate(date as any)
            }}
            unselectable="on"
          />
          <FormDateInput
            size="sm"
            inputGroupClassname="!w-fit !mr-4"
            popoverPanelClassName="!mr-8"
            value={tillDate}
            onChange={date => {
              setTillDate(date as any)
            }}
            unselectable="on"
          />
        </div>
      </header>
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        isLoading={fetching}
        // paginationProps={{
        //   total: Math.ceil(totalTrades / siteConfig.pageSize),
        //   page: page,
        //   onChange(page) {
        //     params.set("page", page.toString())
        //     params.set("search", "")
        //     setParams(params)
        //   },
        // }}
        getCellProps={cell => {
          if (
            cell.column.id === "total_transactions" ||
            cell.column.id === "total_quantity" ||
            cell.column.id === "total_value"
          ) {
            return {
              className: "bg-blue-100 text-center border-b-1 border-gray-300",
            }
          }
          if (
            cell.column.id === "unverified_transactions" ||
            cell.column.id === "unverified_quantity" ||
            cell.column.id === "unverified_value"
          ) {
            return {
              className: "bg-yellow-100 text-center border-b-1 border-gray-300",
            }
          }
          if (
            cell.column.id === "verified_transactions" ||
            cell.column.id === "verified_quantity" ||
            cell.column.id === "verified_value"
          ) {
            return {
              className: "bg-green-100 text-center border-b-1 border-gray-300",
            }
          }

          if (!cell.row.original.mandi.omit) {
            return {
              className: "text-center border-0",
            }
          }

          return {
            className: "text-center border-b-1 border-gray-300",
          }
        }}
        aggregateColumnClassName={
          "text-center bg-blue-100 hover:bg-blue-100 p-10 sticky bottom-0 z-[8] font-600"
        }
        columns={COLUMNS}
        data={trades || []}
        meta={<>{totalMandis} Mandis</>}
        getHeaderProps={() => {
          return {
            className: "!text-center !text-xs !px-1",
          }
        }}
      />
    </>
  ) : (
    <></>
  )
}

export default MandiList
