import { Warning } from "@phosphor-icons/react"
import { Button, Group, Text } from "@vesatogo/grass-core"
import { useTranslation } from "react-i18next"

import { useGenerateTradeOtpMutation } from "~/generated/graphql"
import { CardWithFooter, PavtiNote } from "~/pages/pavti/components"
import { IOTPProps } from "~/pages/pavti/lib/types"
import { getHashValue } from "~/pages/pavti/lib/utils"

export function SendOTPCard({ token, setOtpSent }: IOTPProps) {
  const { t } = useTranslation()
  const hashValue = getHashValue(token)
  const [, GenerateTradeOTP] = useGenerateTradeOtpMutation()

  function sendOTP() {
    GenerateTradeOTP({ token }).then(({ data }) => {
      setOtpSent(data?.generate_trade_otp?.status || false)
    })
  }

  return hashValue && hashValue.phone.length === 4 ? (
    <>
      <PavtiNote
        text={t("sensitive.information")}
        icon={<Warning size={20} />}
      />
      <CardWithFooter
        footerSection={
          <Group className="flex justify-center">
            <Button variant="solid" className="!bg-gray-700" onClick={sendOTP}>
              {t("send.otp")}
            </Button>
          </Group>
        }
      >
        <Text size="sm" className="mb-2 !text-gray-800 text-center">
          {t("request.opt.text")}
        </Text>
        <Text className="mb-8 text-xl font-medium !text-gray-800 underline underline-offset-4">
          XXXXXX{hashValue.phone}
        </Text>
      </CardWithFooter>
    </>
  ) : (
    <PavtiNote text={t("invalid.link")} />
  )
}
