import { TEMPLATE_BASE_FRAG } from "~/graphql/quality/template.gql"

export const RESULT_BASE_FRAG = /* GraphQL */ `
  fragment ResultBaseFrag on result {
    id
    name
    codename
    sample_id
    sample {
      id
      sample_request_id
    }
    template {
      ...TemplateBaseFrag
    }
    evaluations(
      where: {
        template_evaluation: {
          codename: { _eq: "51bb70e4-fc39-4a61-80ea-3636b44aa533" }
        }
      }
    ) {
      value
    }
    observations {
      id
      template_parameter_id
      template_parameter {
        id
        quality_parameter_id
      }
      value
      sample_observation: observation {
        id
        observation_number
        attachments {
          id
          media {
            id
            url
            media_kind
          }
        }
      }
    }
  }
  ${TEMPLATE_BASE_FRAG}
`

export const RESULT_ONE = /* GraphQL */ `
  query ResultOne($id: bigint!) {
    result_by_pk(id: $id) {
      ...ResultBaseFrag
    }
  }
  ${RESULT_BASE_FRAG}
`
