import { UseFormReturnType } from "@mantine/form"
import {
  CaretCircleUp,
  Paperclip,
  Plus,
  Trash,
  XCircle,
} from "@phosphor-icons/react"
import {
  Button,
  Flex,
  FormInput,
  FormSelector,
  Modal,
  Toast,
} from "@vesatogo/grass-core"
import { FileUpload } from "@vesatogo/grass-dropzone"
import "@vesatogo/grass-dropzone/index.css"
import classNames from "classnames"
import { meanBy, orderBy } from "lodash-es"
import { useState } from "react"
import { toast } from "react-hot-toast"
import {
  useDeleteSampleObservationMutation,
  useDeleteSampleOneMutation,
} from "~/generated/graphql"
type QCObservationGradeProps = {
  isEditing?: boolean
  result: any
  form: UseFormReturnType<any, (values: any) => any>
  index: number
  results?: any[]
}
const QCObservationGrade = ({
  isEditing,
  index,
  result,
  form,
  results,
}: QCObservationGradeProps) => {
  const [isOpen, setOpen] = useState(true)
  const [{ fetching: fetchingDelete }, deleteSampleObservation] =
    useDeleteSampleObservationMutation()
  const [{ fetching }, deleteSampleOne] = useDeleteSampleOneMutation()

  const [selectedObservationIdx, setSelectedObservationIdx] = useState<
    number | null
  >(null)

  const selectedObservation =
    typeof selectedObservationIdx === "number"
      ? result?.observations?.[selectedObservationIdx]
      : null

  return (
    <div className="border rounded-lg mb-3">
      <div className="flex gap-2 w-full rounded-t-lg bg-yellow-100 p-2 text-left text-sm font-medium text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
        <div className="flex justify-between items-center gap-4 w-full">
          <div className="flex justify-center items-center gap-2">
            <CaretCircleUp
              onClick={() => setOpen(prev => !prev)}
              className={`${
                isOpen ? "rotate-180 transform" : ""
              } h-6 w-6 cursor-pointer hover:opacity-80`}
            />
            <span className="text-base">
              Grade <span className="text-red">*</span>
            </span>
            <FormSelector
              isDisabled={!isEditing}
              options={results}
              // isDisabled={isDisabled || isBookingTrade}
              isOptionDisabled={(option: any) => {
                return form.values.results.find(
                  result => result?.template?.id === option?.id
                ) as any
              }}
              className="min-w-[18rem]"
              {...form.getInputProps(`results.${index}.template`)}
              // onChange={template => {
              //   form.setFieldValue(`results.${index}`, {
              //     ...template,
              //     template_parameters: template?.template_parameters?.map(
              //       param => ({
              //         ...param,
              //         observations: [{}],
              //       })
              //     ),
              //   })
              // }}
            />
          </div>
          <div className="flex items-center gap-3">
            <Button
              disabled={!isEditing}
              loading={fetching}
              key={`delete_${index}`}
              intent="danger"
              onClick={async () => {
                try {
                  if (result?.sample_id) {
                    await deleteSampleOne({
                      id: result?.sample_id,
                    })
                  }
                  form.removeListItem("results", index)
                } catch (error: any) {
                  return toast.custom(
                    <Toast intent="danger" title={error?.message} />
                  )
                }
              }}
              //   onClick={() => form.insertListItem(`trades.${index}.items`, {})}
              variant="ghost"
              leftIcon={<Trash />}
              size="sm"
              //   disabled={isDisabled}
            >
              Delete Grade
            </Button>
          </div>
        </div>
      </div>
      {isOpen && (
        <>
          <div className={classNames(`flex gap-4 p-4`)}>
            {!isEditing
              ? result?.template?.template_parameters?.map(parameter => {
                  const avgValue = meanBy(
                    result.observations.filter(
                      obs => obs.template_parameter_id === parameter.id
                    ),
                    "value"
                  )

                  const isFailed = parameter.configurations?.some(config => {
                    if (config.comparator === "GT") {
                      return avgValue > config.value
                    } else if (config.comparator === "LT") {
                      return avgValue < config.value
                    }
                  })

                  return (
                    <div
                      key={parameter.quality_parameter?.id}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Flex className="items-center justify-between">
                        <span>
                          {parameter.quality_parameter?.name} (avg){" "}
                          <span
                            className={classNames(
                              isFailed ? "text-red-500" : null
                            )}
                          >
                            {avgValue.toFixed(2)}%
                          </span>
                        </span>
                      </Flex>
                      <Flex className="items-center justify-between">
                        <span className="text-gray-600 text-sm">
                          Standard :{" "}
                          <span className="text-blue-300">
                            {parameter.standard}
                          </span>
                        </span>
                      </Flex>
                      <Flex className="items-center justify-between">
                        <span className="text-gray-600 text-sm">
                          Considerable Deduction :{" "}
                          <span className="text-blue-300">
                            {parameter.weightage}%
                          </span>
                        </span>
                      </Flex>
                    </div>
                  )
                })
              : result?.template?.template_parameters?.map((parameter: any) => {
                  const observations = orderBy(
                    result.observations.filter(
                      obs => obs.template_parameter_id === parameter.id
                    ),
                    "observation_number"
                  )
                  const exceeded =
                    observations?.length >= parameter.observation_count
                  return (
                    <div
                      key={parameter.quality_parameter?.id}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Flex className="items-center justify-between">
                        <span>
                          {parameter.quality_parameter?.name}{" "}
                          <span className="text-yellow-600">
                            ({observations?.length}/
                            {parameter?.observation_count})
                          </span>
                        </span>
                        <button
                          className={classNames(
                            exceeded
                              ? "text-gray-600 pointer-events-none"
                              : "text-green-500",
                            "flex items-center gap-1"
                          )}
                          onClick={() => {
                            if (exceeded) return
                            const new_observation_number =
                              observations?.length + 1

                            form.insertListItem(
                              `results.${index}.observations`,
                              {
                                observation_number: new_observation_number,
                                index: result.observations.length,
                                template_parameter_id: parameter.id,
                                quality_parameter_id:
                                  parameter.quality_parameter.id,
                                attachments: [],
                              }
                            )
                          }}
                          // role="button"
                        >
                          <Plus />
                          Reading
                        </button>
                      </Flex>
                      <div className="flex flex-col gap-3">
                        {observations?.map(observation => {
                          return (
                            <Flex
                              className="items-center"
                              data-observation-number={
                                observation.observation_number
                              }
                              key={
                                observation.id ||
                                `obs_${observation.observation_number}`
                              }
                            >
                              <FormInput
                                placeholder="Value"
                                type="number"
                                {...form.getInputProps(
                                  `results.${index}.observations.${observation.index}.value`
                                )}
                              />
                              <Flex className="gap-2">
                                <Paperclip
                                  onClick={() =>
                                    setSelectedObservationIdx(observation.index)
                                  }
                                  size={22}
                                  className="text-green"
                                  role="button"
                                />

                                <XCircle
                                  onClick={async () => {
                                    if (observation.sample_observation_id) {
                                      await deleteSampleObservation({
                                        id: observation.sample_observation_id,
                                      })
                                    }
                                    form.removeListItem(
                                      `results.${index}.observations`,
                                      observation.index
                                    )
                                  }}
                                  role="button"
                                  size={22}
                                  weight="fill"
                                  className={classNames(
                                    "text-red-500",
                                    fetchingDelete
                                      ? "pointer-events-none"
                                      : null
                                  )}
                                />
                              </Flex>
                            </Flex>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
          </div>
        </>
      )}
      <Modal
        isOpen={selectedObservation ? true : false}
        onClose={() => setSelectedObservationIdx(null)}
        title={`Upload images for sample observation`}
        primaryActionButtonProps={{
          text: "Save",
          onClick: () => setSelectedObservationIdx(null),
        }}
        secondaryActionButtonProps={{
          text: "Cancel",
          onClick: () => setSelectedObservationIdx(null),
        }}
      >
        <FileUpload
          className="!grid-cols-4"
          onError={error => {
            console.log(error)
          }}
          media={selectedObservation?.attachments || []}
          onDelete={file => {
            const indexOfFile = selectedObservation?.attachments?.findIndex(
              attachment => attachment.url === file.url
            )
            form.removeListItem(
              `results.${index}.observations.${selectedObservation.index}.attachments`,
              indexOfFile
            )
          }}
          appName="setutrade"
          environment={import.meta.env.VITE_APP_ENV}
          endpoint={`https://${
            import.meta.env.VITE_KITE_API
          }/services/uploader/`}
          onChange={media => {
            form.setFieldValue(
              `results.${index}.observations.${selectedObservation.index}.attachments`,
              media
            )
          }}
        />
      </Modal>
    </div>
  )
}

export default QCObservationGrade
