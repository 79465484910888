import { Modal } from "@vesatogo/grass-core"
import { useSearchParams } from "react-router-dom"
import TraderSummary from "~/components/TraderSummary"
import { Base_User, useInvoicePreviewQuery } from "~/generated/graphql"
import { downloadInvoice } from "~/pages/d/trade-requests/invoices"

const PreviewInvoice = () => {
  const [params, setParams] = useSearchParams()
  const isOpen = params.get("invoice") ? true : false
  const invoiceId = parseInt(params.get("invoice") || "")

  const [{ data }] = useInvoicePreviewQuery({
    variables: {
      id: invoiceId,
    },
    pause: !invoiceId,
  })

  const invoice = data?.invoice_by_pk

  const quantityUnit = invoice?.trades?.[0]?.trade_items?.[0]?.quantity_unit
  const rateUnit = invoice?.trades?.[0]?.trade_items?.[0]?.rate_unit

  return (
    <Modal
      bodyClassName="w-[60%]"
      isOpen={isOpen}
      onClose={() => {
        params.delete("invoice")
        setParams(params)
      }}
      secondaryActionButtonProps={{
        text: "Close",
        onClick() {
          params.delete("invoice")
          setParams(params)
        },
      }}
      primaryActionButtonProps={{
        text: "Download Invoice",
        async onClick() {
          downloadInvoice(invoice?.id)()
        },
      }}
      title={`Invoice No : #${invoiceId}`}
    >
      <TraderSummary
        total_amount={invoice?.deducted_amount}
        total_quantity={invoice?.deducted_quantity}
        trader={invoice?.buyer?.user?.[0] as Base_User}
        rate_unit={rateUnit}
        quantity_unit={quantityUnit}
        standard_deduction={invoice?.standard_deduction}
        expense_amount={invoice?.expense_amount}
        trades={invoice?.trades as any}
      />
    </Modal>
  )
}

export default PreviewInvoice
