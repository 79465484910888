import { Group, Text } from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import { useTranslation } from "react-i18next"
import { PavtiTradeDetailQuery } from "~/generated/graphql"
import { DetailCard, Table } from "~/pages/pavti/components"
import { Column } from "~/pages/pavti/lib/types"

export function Particulars({
  data,
}: {
  data: PavtiTradeDetailQuery["trade_by_pk"]
}) {
  const { t } = useTranslation()
  const item = data?.trade_items[0]
  const totalQuantity = data?.trade_items_aggregate.aggregate?.sum?.quantity
  const deductedAmount = (data?.standard_deduction / 100) * data?.total_amount
  const deductedQuantity = (data?.standard_deduction / 100) * totalQuantity

  const particularColumns: Column<typeof item>[] = [
    {
      accessor: "item",
      Heading: t("item"),
      flex: 3,
      Cell() {
        return (
          <>
            <Text size="sm" className="!text-gray-800">
              {data?.commodity_variety?.name}
            </Text>
            <Text size="sm">{data?.commodity.name}</Text>
          </>
        )
      },
    },
    {
      accessor: "grade",
      Heading: <div className="text-center">{t("grade")}</div>,
      Cell(data) {
        return (
          <Text size="sm" className="!text-gray-800 text-center">
            {data?.variety_grade?.name}
          </Text>
        )
      },
    },
    {
      accessor: "quantity",
      Heading: <div className="text-right">{t("quantity")}</div>,
      Cell(data) {
        return (
          <div className="text-right">
            <Text size="sm" className="!text-gray-800">
              {Number(data?.quantity).toFixed(2)}
            </Text>
            <Text size="xs">{data?.quantity_unit.name}</Text>
          </div>
        )
      },
    },
    {
      accessor: "rate",
      Heading: <div className="text-right">{t("rate")}</div>,
      Cell(data) {
        return (
          <div className="text-right">
            <Text size="sm" className="!text-gray-800">
              {Number(data?.rate).toFixed(2)}
            </Text>
            <Text size="xs">per {data?.rate_unit.name}</Text>
          </div>
        )
      },
    },
    ...(data?.kind === "final"
      ? [
          {
            accessor: "amount",
            flex: 1.5,
            Heading: <div className="text-right">{t("amount")}</div>,
            Cell(data) {
              return (
                <div className="text-right">
                  <Text size="sm" className="!text-gray-800">
                    {inr(data?.amount)}
                  </Text>
                </div>
              )
            },
          },
        ]
      : []),
  ]
  return data?.trade_items.length ? (
    <DetailCard
      header={
        <Text className="font-normal !text-gray-800">{t("particulars")}</Text>
      }
    >
      <Table columns={particularColumns} data={data?.trade_items} />
      <Group className="flex gap-2 p-2 border-t border-gray-300">
        <Text size="sm" className="flex-[4_1_0%]">
          {t("amount.before")}
        </Text>
        <Group className="text-right flex-1">
          <Text size="sm" className="!text-gray-800">
            {totalQuantity.toFixed(2)}
          </Text>
          <Text size="xs">{data.trade_items[0]?.quantity_unit.name}</Text>
        </Group>
        <div className="flex-1" />
        {data.kind === "final" ? (
          <Text size="sm" className="flex-[1.5_1_0%] text-end">
            {inr(data.total_amount)}
          </Text>
        ) : null}
      </Group>
      <Group className="flex items-start gap-2 p-2 border-t border-gray-300">
        <Text size="sm" className="flex-[3_1_0%]">
          {t("deduction")}
        </Text>
        <Group className="flex justify-center items-center flex-1">
          <Text size="sm" className="!text-gray-800">
            {Number(data.standard_deduction).toFixed(2)}
          </Text>
          <Text size="xs">%</Text>
        </Group>
        <Group className="text-right flex-1">
          <Text size="sm" className="!text-gray-800">
            {deductedQuantity.toFixed(2)}
          </Text>
          <Text size="xs">{data.trade_items[0]?.quantity_unit.name}</Text>
        </Group>
        <div className="flex-1" />
        {data?.kind === "final" ? (
          <Text size="sm" className="flex-[1.5_1_0%] text-end">
            {inr(deductedAmount)}
          </Text>
        ) : null}
      </Group>
      <Group className="flex gap-2 p-2 border-y border-gray-300 mb-4">
        <Text size="sm" className="flex-[4_1_0%] !text-gray-800">
          {t("amount.after")}
        </Text>
        <Group className="text-right flex-1">
          <Text size="sm" className="!text-gray-800">
            {(
              totalQuantity -
              Number(
                ((data.standard_deduction / 100) * totalQuantity).toFixed(2)
              )
            ).toFixed(2)}
          </Text>
          <Text size="xs" className="!text-gray-800">
            {data.trade_items[0]?.quantity_unit.name}
          </Text>
        </Group>
        <div className="flex-1" />
        {data.kind === "final" ? (
          <Text
            size="sm"
            className="flex-[1.5_1_0%] text-end !text-gray-800 font-600"
          >
            {inr(data.total_amount - Number(deductedAmount))}
          </Text>
        ) : null}
      </Group>
    </DetailCard>
  ) : null
}
