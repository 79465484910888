import { BookingTradeSummaryQuery } from "../generated/graphql"

export function getBookingQuantity(
  bookingData?: BookingTradeSummaryQuery,
  id?: number
) {
  return (
    bookingData?.trade_grade_quantity_summary_report?.grade_summary?.find(
      bItem => bItem?.variety_grade_id == id
    )?.remaining_quantity || 0
  )
}
