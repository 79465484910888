import { Funnel } from "@phosphor-icons/react"
import {
  Button,
  Checkbox,
  FilterDrawer,
  FormGroup,
  QuerySelector,
  RadioGroup,
  Selector,
} from "@vesatogo/grass-core"
import { DateInput } from "@vesatogo/grass-dates"
import { Dispatch, SetStateAction, useState } from "react"
import { TradeKinds } from "~/constants/Kinds"
import {
  useAllCommoditiesQuery,
  useAllVarietiesQuery,
  useTradeStatusesQuery,
  useUsersQuery,
} from "~/generated/graphql"
import { FilterType } from "."

type TradeRequestsFilterProps = {
  filter: FilterType
  setFilter: Dispatch<SetStateAction<FilterType>>
}
const TradeRequestsFilter = ({
  filter,
  setFilter,
}: TradeRequestsFilterProps) => {
  const [isOpen, setOpen] = useState(false)

  function inputProps(path: string) {
    return {
      value: filter[path],
      onChange: (e: any) => {
        setFilter(prev => ({
          ...prev,
          [path]: e?.target ? e.target.value : e,
        }))
      },
    }
  }

  function getAppliedFilters(prefix: string, filter: any) {
    const totalApplied = Object.keys(filter)?.filter?.(
      key => key.startsWith(prefix) && filter[key]
    )?.length
    return totalApplied ? `${totalApplied} Applied` : null
  }
  return (
    <>
      <Button
        onClick={() => {
          setOpen(prev => !prev)
        }}
        variant="outline"
        className="items-center"
      >
        <Funnel />
      </Button>
      <FilterDrawer
        tabs={[
          {
            id: 1,
            title: "Seller/Buyer",
            subtitle: getAppliedFilters("sb", filter),
            content: (
              <>
                <FormGroup
                  label="Farmer"
                  className={"mb-2"}
                  helperText="seller"
                >
                  <QuerySelector
                    serverSideQuery
                    dataHook={useUsersQuery}
                    variables={{ groups: ["Farmer", "Trader"] }}
                    {...inputProps("sb_seller")}
                  />
                </FormGroup>
                <FormGroup label="Trader" className={"mb-2"} helperText="buyer">
                  <QuerySelector
                    serverSideQuery
                    dataHook={useUsersQuery}
                    variables={{ groups: ["Trader"] }}
                    {...inputProps("sb_buyer")}
                  />
                </FormGroup>
              </>
            ),
          },
          {
            id: 2,
            title: "Commodity",
            subtitle: getAppliedFilters("com", filter),
            content: (
              <div className="flex flex-col gap-3">
                {" "}
                <FormGroup label="Commodity" className={"w-full"}>
                  <QuerySelector
                    serverSideQuery
                    dataHook={useAllCommoditiesQuery}
                    {...inputProps("com_commodity")}
                  />
                </FormGroup>{" "}
                <FormGroup label="Variety" className={"w-full"}>
                  <QuerySelector
                    dataHook={useAllVarietiesQuery}
                    variables={{ commodity_id: filter.com_commodity?.id }}
                    {...inputProps("com_variety")}
                  />
                </FormGroup>{" "}
              </div>
            ),
          },
          {
            id: 3,
            title: "Others",
            subtitle: getAppliedFilters("oth", filter),
            content: (
              <div className="flex flex-col gap-3">
                <RadioGroup
                  className={"w-full"}
                  label="Mode *"
                  {...inputProps("oth_mode")}
                >
                  <Checkbox type={"radio"} value="direct" label="Direct" />
                  <Checkbox
                    type={"radio"}
                    value="facilitator"
                    label="Via Facilitator"
                  />
                </RadioGroup>
                <RadioGroup
                  className={"w-full"}
                  label="Trade Kind *"
                  {...inputProps("oth_kind")}
                >
                  <Checkbox
                    type={"radio"}
                    value={TradeKinds.SaudaPavti}
                    label="Sauda"
                  />
                  <Checkbox
                    type={"radio"}
                    value={TradeKinds.HishobPatti}
                    label="Hishob"
                  />
                  <Checkbox
                    type={"radio"}
                    value={TradeKinds.Booking}
                    label="Booking"
                  />
                  <Checkbox
                    type={"radio"}
                    value={TradeKinds.StoragePatti}
                    label="Storage"
                  />
                </RadioGroup>
                <RadioGroup
                  className={"w-full"}
                  {...inputProps("oth_kind")}
                >
                  <Checkbox
                    type={"radio"}
                    value={TradeKinds.InwardPatti}
                    label="Inward"
                  />
                </RadioGroup>
                {filter.oth_mode === "facilitator" && (
                  <FormGroup label="Facilitator Company" className={"w-full"}>
                    <Selector
                      options={filter?.sb_seller?.companies?.map(
                        c => c?.company
                      )}
                      {...inputProps("oth_facilitator")}
                    />
                  </FormGroup>
                )}
                <FormGroup label="Status" className={"w-full"}>
                  <QuerySelector
                    // options={TRADE_STATUES}
                    dataHook={useTradeStatusesQuery}
                    getOptionLabel={item => item.id}
                    {...inputProps("oth_status")}
                  />
                </FormGroup>
                <FormGroup label="Created At" className={"w-full"}>
                  <DateInput
                    className={"w-full"}
                    {...inputProps("oth_created_at")}
                  />
                </FormGroup>
              </div>
            ),
          },
        ]}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onApply={() => {
          setOpen(false)
        }}
        onClearAll={() => {
          setFilter({})
          setOpen(false)
        }}
      />
    </>
  )
}

export default TradeRequestsFilter
