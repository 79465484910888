import { useLocalStorage } from "@mantine/hooks"
import { useState } from "react"
import i18n from "i18next"
import { initReactI18next, useTranslation } from "react-i18next"
import langEn from "./languages/en.json"
import langHi from "./languages/hi.json"
import langMr from "./languages/mr.json"
import { config } from "./config"

import { Header } from "./components/Header"
import { Details } from "./sections/Details"
import { OTPCard } from "./sections/OTPCard"
import { SendOTPCard } from "./sections/SendOTPCard"
import { getLocalStorage } from "./lib/utils"
import { useParams } from "react-router-dom"
import { VerifyTradeOtp } from "~/generated/graphql"

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: langEn },
    hi: { translation: langHi },
    mr: { translation: langMr },
  },
  lng: getLocalStorage("lang") || config.defaultLang,
  fallbackLng: config.defaultLang,
})

const SaudaPavti = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [otpSent, setOtpSent] = useState(false)
  const [localValue] = useLocalStorage<VerifyTradeOtp>({
    key: `${id}`,
  })
  const [page, setPage] = useState(1)
  const [headTitle, setHeadTitle] = useState("")

  return id ? (
    <div className="bg-gray-100 h-screen">
      <Header
        title={headTitle ? t(headTitle) : ""}
        page={page}
        setPage={setPage}
      />
      <div className="px-2 pb-4 pt-[72px]">
        {localValue?.refresh_expires_in ? (
          <Details
            token={id}
            setOtpSent={setOtpSent}
            setTitle={setHeadTitle}
            page={page}
            setPage={setPage}
          />
        ) : otpSent ? (
          <OTPCard token={id} setOtpSent={setOtpSent} />
        ) : (
          <SendOTPCard token={id} setOtpSent={setOtpSent} />
        )}
      </div>
    </div>
  ) : null
}

export default SaudaPavti
