export async function downloadPdf(hash: string, token: string | null) {
  const url = `https://${import.meta.env.VITE_REST_URL}/${hash}`

  try {
    const response = await fetch(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) throw new Error("Something went wrong")
    const blobRes = await response.blob()
    const file = window.URL.createObjectURL(blobRes)
    window.open(file)
  } catch (err) {
    throw err
  }
}
