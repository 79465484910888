import { Image } from "@phosphor-icons/react"
import dayjs from "dayjs"
type PaymentDetailsProps = {
  data?: any
}
const PaymentDetails = ({ data }: PaymentDetailsProps) => {
  const paymentData = data
  const tableHead = [
    "Date of Payment",
    "",
    "Payment Mode",
    "Transaction ID",
    "Amount",
  ]

  const tableColumns = [
    {
      name: "Date of Payment",
      accessor: "date",
      cell(value) {
        return dayjs(value).format("DD MMM 'YY")
      },
    },
    {
      name: "Payment Option",
      accessor: "payment_status",
      cell(value) {
        return null
      },
    },
    {
      name: "Payment Mode",
      accessor: "mode",
      cell(value) {
        return value
      },
    },
    {
      name: "Transaction ID",
      accessor: "transaction_id",
      cell(value) {
        return <>#{value}</>
      },
    },
    {
      name: "Amount",
      accessor: "amount",
      cell(value, row) {
        return (
          <span className="flex items-center gap-2">
            {value}{" "}
            {
              row.photo && (
                <a href={row.photo?.url} target="_blank">
                  <Image
                    onClick={() => {}}
                    className="p-1 text-blue-500 bg-blue-100 rounded-full cursor-pointer"
                    weight="fill"
                    size={25}
                  />
                </a>
              )
            }
          </span>
        )
      },
    },
  ]

  return (
    <div className="rounded-lg mt-4">
      <div className="mt-2 rounded-lg bg-gray-100">
        <div className="font-semibold mb-2 bg-green-100 text-gray-800 p-1 rounded-t-lg px-3">
          Payment Detail
        </div>
        {paymentData && paymentData.payments.length > 0 ? (
          <>
            <div className="flex text-sm font-500 gap-2 px-3 py-1">
              <span>
                Total Amount:{" "}
                <span className="text-green-600">
                  ₹{paymentData?.total_amount}
                </span>
              </span>
              |
              <span>
                Partial Amount:{" "}
                <span className="text-green-600">
                  ₹{paymentData.total_amount_paid}
                </span>
              </span>
              <span>
                Remaining Amount:{" "}
                <span className="text-red-600">
                  ₹{paymentData.total_amount_pending}
                </span>
              </span>
            </div>

            <hr className="border-dashed border-gray-400" />

            <table className="w-full text-sm">
              <thead>
                <tr>
                  {tableHead.map((head, index) => (
                    <th
                      key={index}
                      className="py-2 px-3 border-b border-b-gray-300 font-500 text-gray-600 text-left"
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paymentData.payments.map((payment, index) => (
                  <tr key={index} className="border">
                    {tableColumns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className={`py-1 px-3 border-b border-b-gray-300 text-gray-800 ${
                          colIndex === 4 ? "text-right" : "text-left"
                        }`}
                      >
                        {column.cell(payment[column.accessor], payment)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4} className="py-2 px-3 border font-700">
                    Total
                  </td>
                  <td className="py-2 px-3 border font-700">
                    {paymentData.total_amount_paid}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default PaymentDetails
