import { Group } from "@vesatogo/grass-core"
import { IDetailCardProps } from "~/pages/pavti/lib/types"

export function DetailCard({ children, header }: IDetailCardProps) {
  return (
    <Group>
      {header !== null ? (
        <div className="p-2 bg-gray-300 flex justify-center">{header}</div>
      ) : null}
      <div>{children}</div>
    </Group>
  )
}
