import { useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import ExampleTheme from "./ExampleTheme";
import ToolbarPlugin from "./ToolbarPlugin";
import "./editor.css";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from "lexical";
import { $generateNodesFromDOM, $generateHtmlFromNodes } from "@lexical/html";

function Placeholder() {
  return <div className="editor-placeholder">Enter Terms and Condition ...</div>;
}

const editorConfig = {
  namespace: "editor",
  onError(error: Error) {
    throw error;
  },
  theme: ExampleTheme,
};

type EditorProps = {
  value: string;
  disabled?: boolean;
  onEditorChange?: (value: string) => void;
};

function PreFillPlugin({ value }: { value: string }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!value) return;
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      const parser = new DOMParser();
      const dom = parser.parseFromString(value, "text/html");

      const nodes = $generateNodesFromDOM(editor, dom);
      root.select();
      $insertNodes(nodes);
    });
  }, [editor, value]);

  return null;
}

function OnChangePlugin({
  onEditorChange,
}: {
  onEditorChange?: (value: string) => void;
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!onEditorChange) return;

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        onEditorChange(htmlString);
      });
    });
  }, [editor, onEditorChange]);

  return null;
}

export default function Editor({
  value,
  disabled = false,
  onEditorChange,
}: EditorProps) {
  return (
    <div className="editor-container w-full">
      <LexicalComposer initialConfig={editorConfig}>
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                disabled={disabled}
                className={`editor-input border rounded-b-lg p-2 text-gray-800 border-gray-300 ${
                  disabled ? "pointer-events-none bg-gray-200" : ""
                }`}
              />
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <PreFillPlugin value={value} />
          <OnChangePlugin onEditorChange={onEditorChange} />
        </div>
      </LexicalComposer>
    </div>
  )
}
