enum SharedEntityGroup {
  KiteDepartment = "kite.department",
  KiteCommodity = "kite.commodity",
  KiteVariety = "kite.commodity_variety",
  KiteGrade = "kite.variety_grade",
  KiteUser = "kite.base_user",
  KitePlotCrop = "kite.plot_crop",
  KiteCompany = "kite.company",
  KiteCurrency = "kite.currency",
  KiteSiUnit = "kite.si_unit",
  KiteSku = "kite.sku",
  EagleResult = "eagle.result",
  StarlingVehicle = "starling.vehicle",
}

export default SharedEntityGroup
